import {
  defaultTeamPrefs,
  defaultUserPrefs,
  getSubfield,
} from '@arcadehq/shared/lib/helpers'
import {
  BackgroundImage,
  CustomCursor,
  FlowNavSetting,
  FlowWrapper,
} from '@arcadehq/shared/types'
import { TeamTheme } from 'src/types'
import { getFlowNavSetting } from 'src/utils/flows'

import { AccountCore } from '../AccountCore'
import { setOrDeleteSubfield } from '../helpers'

export type FlowDefaults = ReturnType<typeof flowDefaults>

export const flowDefaults = (core: AccountCore, theme: TeamTheme | null) => ({
  get font() {
    const value = getSubfield(theme, 'prefs', 'fontFamily')
    if (value !== undefined) {
      return value
    }

    // TODO check user profile
    return core.access.isActiveMemberOfTeam
      ? defaultTeamPrefs.fontFamily
      : defaultUserPrefs.fontFamily
  },

  get backgroundImage(): BackgroundImage | undefined {
    if (theme) {
      return getSubfield(theme, 'prefs', 'bgImage')
    }
  },

  get wrapper(): FlowWrapper | undefined {
    if (theme) {
      return getSubfield(theme, 'prefs', 'wrapper')
    }
  },

  get cursor(): CustomCursor | undefined {
    if (theme) {
      return getSubfield(theme, 'prefs', 'cursor')
    }
  },

  get navBarEnabled() {
    if (theme) {
      return getFlowNavSetting(
        getSubfield(theme, 'prefs', 'showFlowNavIndicator')
      )
    }
    return FlowNavSetting.Auto
  },

  get startOverlayEnabled() {
    if (theme) {
      return !!getSubfield(theme, 'prefs', 'showStartOverlay')
    }
    return false
  },

  get backNextButtonsEnabled() {
    if (theme) {
      return !!getSubfield(theme, 'prefs', 'showBackNextButtons')
    }
    return false
  },

  get startOverlayText() {
    if (theme) {
      return getSubfield(theme, 'prefs', 'startOverlayButtonText')
    }
  },

  setFont(font: string | undefined) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'fontFamily',
        font,
        core.user.id
      )
    }
    // TODO set user profile
    return Promise.resolve(false)
  },

  setBackgroundImage(backgroundImage: BackgroundImage | undefined) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'bgImage',
        backgroundImage,
        core.user.id
      )
    }
    return Promise.resolve(false)
  },

  setWrapper(wrapper: FlowWrapper | undefined) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'wrapper',
        wrapper,
        core.user.id
      )
    }
    return Promise.resolve(false)
  },

  setCursor(cursor: CustomCursor | undefined) {
    if (theme) {
      return setOrDeleteSubfield(theme, 'prefs', 'cursor', cursor, core.user.id)
    }
    return Promise.resolve(false)
  },

  setNavBarEnabled(navBarEnabled: FlowNavSetting) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'showFlowNavIndicator',
        navBarEnabled,
        core.user.id
      )
    }
    return Promise.resolve(false)
  },

  setBackNextButtonsEnabled(backNextButtonsEnabled: boolean) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'showBackNextButtons',
        backNextButtonsEnabled,
        core.user.id
      )
    }
    return Promise.resolve(false)
  },

  setStartOverlayEnabled(startOverlayEnabled: boolean) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'showStartOverlay',
        startOverlayEnabled,
        core.user.id
      )
    }
    return Promise.resolve(false)
  },

  setStartOverlayText(startOverlayText?: string) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'startOverlayButtonText',
        startOverlayText,
        core.user.id
      )
    }
    return Promise.resolve(false)
  },
})
