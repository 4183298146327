import {
  getRecordingDurationMs,
  getTimeSinceRecordingStartMs,
  isImageStep,
} from '@arcadehq/shared/helpers'
import {
  CapturedClickEvent,
  CapturedHtmlData,
  InterAppMessage,
  InterAppMessageResponse,
  Step,
  StepType,
} from '@arcadehq/shared/types'
import { captureException } from '@sentry/nextjs'
import { User } from 'next-firebase-auth'
import { ForwardedRecordingData, TabInfo } from 'src/api/FlowUploader'
import { Account } from 'src/auth/Account'
import { HOTSPOT_SIZE } from 'src/components/Hotspots/constants'
import {
  isPreprodEnv,
  isProductionEnv,
  isStagingEnv,
  shimmerDataUrl,
} from 'src/helpers'
import { v4 as uuid } from 'uuid'

import {
  localChromeExtensionId,
  preprodChromeExtensionId,
  publishedChromeExtensionId,
  stagingChromeExtensionId,
} from '../../constants'

export const passUserToExtension = async (
  user: Partial<User> | null
): Promise<boolean> => {
  const result = await sendMessage({
    name: 'UserFromApp',
    user,
  })
  return result.success
}

export const notifyExtensionReadyForPreflight = async (): Promise<boolean> => {
  const result = await sendMessage({
    name: 'ReadyForPreflight',
  })

  if (!result.success) {
    captureException(new Error(result.errorMessage))
    return false
  }

  return true
}

export const getExtensionVersion = async (): Promise<string | null> => {
  const result = await sendMessage({
    name: 'GetVersion',
  })

  if (result.success) {
    return result.reply.version || null
  }

  return null
}

export const fetchRecordedMedia = () =>
  window.postMessage(
    {
      name: 'arcade-request-recorded-data',
    },
    location.origin
  )

//
// Internal helpers
//

type Response<Message extends InterAppMessage> =
  | { success: true; reply: InterAppMessageResponse<Message['name']> }
  | { success: false; errorMessage: string }

const sendMessage = async <M extends InterAppMessage>(
  message: M
): Promise<Response<M>> => {
  // In prod, send the message to the prod extension first, then fall back to
  // local to allow testing the local extension against prod. In dev, do the
  // reverse.
  //
  // This allow having both extensions cohabitate and do what you expect by
  // default, but if you want to test across environments, you can do so by
  // enabling only the extension you want to test against.
  const extensionIds = [publishedChromeExtensionId, localChromeExtensionId]

  if (!isProductionEnv() || isStagingEnv()) {
    extensionIds.reverse()
  }

  if (isStagingEnv()) {
    extensionIds.unshift(preprodChromeExtensionId)
    extensionIds.unshift(stagingChromeExtensionId)
  }

  if (isPreprodEnv()) {
    extensionIds.unshift(stagingChromeExtensionId)
    extensionIds.unshift(preprodChromeExtensionId)
  }

  const errors = []

  for (const id of extensionIds) {
    const result = await sendChromeExtensionMessage(id, message).catch(
      err =>
        ({
          success: false,
          errorMessage: err.message as string,
        } as const)
    )

    if (result.success) {
      return result
    } else {
      errors.push(result.errorMessage)
    }
  }

  return {
    success: false,
    errorMessage: `Failed to send ${message.name} to extension: ${errors.join(
      ' - '
    )}`,
  }
}

const sendChromeExtensionMessage = <Message extends InterAppMessage>(
  extensionId: string,
  message: Message
): Promise<Response<Message>> =>
  new Promise(resolve => {
    if (typeof window === 'undefined') {
      return resolve({
        success: false,
        errorMessage: 'window is not defined',
      })
    }

    const w = window as any
    if (
      typeof w !== 'undefined' &&
      'chrome' in w &&
      'runtime' in w.chrome &&
      'sendMessage' in w.chrome.runtime
    ) {
      w.chrome.runtime.sendMessage(extensionId, message, (reply: any) => {
        if (typeof reply === 'undefined') {
          resolve({
            success: false,
            errorMessage: w.chrome.runtime.lastError,
          })
        } else {
          resolve({
            success: true,
            reply,
          })
        }
      })
    } else {
      resolve({
        success: false,
        errorMessage: 'chrome.runtime.sendMessage not found',
      })
    }
  })

export const MIN_CLIP_MS = 500 // Mux's limit
export const MIN_SCROLL_MS = 100 // Anything less is not interesting
export const MIN_DRAG_MS = 50 // Anything less is not interesting
export const DEFAULT_PLAYBACK_RATE = 1.5

export function parseExtensionCapture(
  payload: ForwardedRecordingData,
  hotspotDefaults: Pick<
    Account['hotspotDefaults'],
    'textColor' | 'backgroundColor'
  >,
  features: { autoPanAndZoom: boolean }
): { steps: Step[]; imageHTML: Map<string, CapturedHtmlData> } {
  const {
    tabs,
    videoBlobUrl,
    capturedEvents,
    screenshots,
    pageContexts = {},
    clickContexts = {},
  } = payload

  const capturedHTML = payload.capturedHTML ? { ...payload.capturedHTML } : {}
  const videoDurationMs = getRecordingDurationMs(payload.videoTimestampWindows)
  const muted = !capturedEvents.some(e => e.type === 'media' && !!e.audio)

  const steps: Step[] = []
  const imageHTML: Map<string, CapturedHtmlData> = new Map()

  // Loops through all captured events. When a click event is found, we always
  // add an image step with a hotspot based on click location. We also check if
  // we should add a video step prior to the image step based on the amount of
  // time that passed between clicks and the presence of an "interesting"
  // event (scrolling, dragging, typing, using camera / audio).
  capturedEvents.sort((a, b) => {
    const aTime = a.type === 'click' ? a.timeMs : a.startTimeMs
    const bTime = b.type === 'click' ? b.timeMs : b.startTimeMs
    return aTime - bTime
  })
  let prevClickTimeMs = 0
  let prevUrl: string | undefined = undefined
  let hasInterestingEventSinceClick = false
  for (const e of capturedEvents) {
    switch (e.type) {
      case 'click': {
        const screenshot = screenshots[e.clickId]
        const tab = tabs[e.tabId]
        const frame = tab?.frames[e.frameId]

        if (!screenshot || !tab || !frame) continue

        const normalizedTimeMs = getTimeSinceRecordingStartMs(
          e.timeMs,
          payload.videoTimestampWindows
        )

        if (
          hasInterestingEventSinceClick && // Something worth a video step has occurred since click / start
          normalizedTimeMs - prevClickTimeMs >= MIN_CLIP_MS // Video step would be at least min duration
        ) {
          const precedingStep = steps[steps.length - 1]
          steps.push({
            id: uuid(),
            type: StepType.Video,
            url: videoBlobUrl,
            startTimeFrac: prevClickTimeMs / videoDurationMs,
            endTimeFrac: normalizedTimeMs / videoDurationMs,
            duration: videoDurationMs / 1000,
            playbackRate: DEFAULT_PLAYBACK_RATE,
            videoProcessing: true,
            muted,
            videoThumbnailUrl: isImageStep(precedingStep)
              ? precedingStep.url // Temporarily use the previous image step's url as the thumbnail if we can
              : shimmerDataUrl(800, 1200),
          })
        }

        const imageStepId = uuid()
        const pageContext = pageContexts[e.clickId]
        const clickContext = clickContexts[e.clickId]
        const html = capturedHTML[e.clickId]
        if (html) {
          imageHTML.set(imageStepId, html)
          delete capturedHTML[e.clickId]
        }
        const panAndZoomEnabled =
          prevUrl && prevUrl === pageContext?.url && features.autoPanAndZoom
        steps.push({
          id: imageStepId,
          type: StepType.Image,
          url: screenshot.dataUrl,
          originalImageUrl: screenshot.dataUrl,
          blurhash: screenshot.blurhash,
          hasHTML: !!html,
          hotspots: [
            {
              id: uuid(),
              width: HOTSPOT_SIZE / 2,
              height: HOTSPOT_SIZE / 2,
              label: '',
              style: 'pulsating',
              defaultOpen: true,
              textColor: hotspotDefaults.textColor,
              bgColor: hotspotDefaults.backgroundColor,
              ...getClickPos(e, tab, frame),
            },
          ],
          ...(panAndZoomEnabled ? { panAndZoom: { enabled: true } } : {}),
          ...(pageContext ? { pageContext } : {}),
          ...(clickContext ? { clickContext } : {}),
        })

        prevClickTimeMs = normalizedTimeMs // Start for start of next video step
        prevUrl = pageContext?.url // Update
        hasInterestingEventSinceClick = false // Reset
        break
      }

      case 'media':
      case 'typing': {
        hasInterestingEventSinceClick = true
        break
      }

      case 'scrolling': {
        const normalizedStartMs = getTimeSinceRecordingStartMs(
          e.startTimeMs,
          payload.videoTimestampWindows
        )
        const normalizedEndMs = getTimeSinceRecordingStartMs(
          e.endTimeMs,
          payload.videoTimestampWindows
        )
        if (normalizedEndMs - normalizedStartMs >= MIN_SCROLL_MS) {
          hasInterestingEventSinceClick = true
        }
        break
      }

      case 'dragging': {
        const normalizedStartMs = getTimeSinceRecordingStartMs(
          e.startTimeMs,
          payload.videoTimestampWindows
        )
        const normalizedEndMs = getTimeSinceRecordingStartMs(
          e.endTimeMs,
          payload.videoTimestampWindows
        )
        if (normalizedEndMs - normalizedStartMs >= MIN_DRAG_MS) {
          hasInterestingEventSinceClick = true
        }
        break
      }
    }
  }

  // Maybe a final video step between last click and end...
  if (
    hasInterestingEventSinceClick && // Something worth a video step has occurred since click / start
    videoDurationMs - prevClickTimeMs >= MIN_CLIP_MS // Video step would be at least min duration
  ) {
    const precedingStep = steps[steps.length - 1]
    steps.push({
      id: uuid(),
      type: StepType.Video,
      url: videoBlobUrl,
      startTimeFrac: prevClickTimeMs / videoDurationMs,
      endTimeFrac: 1,
      playbackRate: DEFAULT_PLAYBACK_RATE,
      duration: videoDurationMs / 1000,
      videoProcessing: true,
      muted,
      videoThumbnailUrl: isImageStep(precedingStep)
        ? precedingStep.url // Temporarily use the previous image step's url as the thumbnail if we can
        : shimmerDataUrl(800, 1200),
    })
  }

  // Final image step
  const finalStepId = uuid()
  const pageContext = pageContexts['final']
  const html = capturedHTML['final']
  if (html) {
    imageHTML.set(finalStepId, html)
    delete capturedHTML['final']
  }
  if (screenshots['final']) {
    steps.push({
      id: finalStepId,
      type: StepType.Image,
      url: screenshots['final'].dataUrl,
      originalImageUrl: screenshots['final'].dataUrl,
      blurhash: screenshots['final'].blurhash,
      hotspots: [],
      hasHTML: !!html,
      ...(pageContext ? { pageContext } : {}),
    })
  }

  Object.keys(capturedHTML).forEach(key => {
    const html = capturedHTML[key]
    if (!html) return
    imageHTML.set(key, html)
  })

  return { steps, imageHTML }
}

export function getClickPos(
  e: CapturedClickEvent,
  tab: TabInfo,
  frame: {
    frameScreenX: number
    frameScreenY: number
  }
): { x: number; y: number } {
  const frameTabX = frame.frameScreenX - tab.tabScreenX
  const frameTabY = frame.frameScreenY - tab.tabScreenY
  const tabX = frameTabX + e.frameX
  const tabY = frameTabY + e.frameY
  return {
    x: tabX / tab.tabWidth,
    y: tabY / tab.tabHeight,
  }
}
