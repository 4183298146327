import { convertDate } from '@arcadehq/shared/helpers'
import {
  Feature,
  StoredFeatures,
  TeamDataDoc,
  TeamPrefs,
} from '@arcadehq/shared/types'
import { Team } from 'src/types'

import { AccountCore } from '../AccountCore'

/**
 * team is a collection of getters that are specific to a team.
 * This file should only include low level getter and generic helpers.
 * It acts as a proxy between other features in Account and the database object.
 */

export const team = (core: AccountCore, team: Team | null) => ({
  update(updates: Partial<TeamDataDoc>): Promise<boolean> {
    if (!team) return Promise.resolve(false)
    return team.update(updates, core.user.id)
  },

  get id(): string | undefined {
    return team?.id
  },

  get group(): string | null {
    return team?.group ?? null
  },

  get slug(): string | undefined {
    return team?.slug
  },

  async setSlug(slug: string): Promise<void> {
    if (!this.id) {
      throw Error('Team ID is not defined')
    }
    const response = await core.fetchWithToken(
      'POST',
      `/api/team/${this.id}/update-slug`,
      { slug }
    )
    const result = await response.json()
    if (result.error) {
      throw Error(result.error)
    }
    return result.slug
  },

  get visibility(): string | undefined {
    return team?.visibility
  },

  get name(): string {
    return team?.name ?? ''
  },

  get domain(): string {
    if (!team) return ''

    // Almost all teams have `team.id` === `team.group`.
    // In the rare case that `team.id` !== `team.group`,
    // it means `team.group` is the domain they've chosen to use
    // and `team.id` is the domain they signed up with originally.
    return team.group
  },

  get inviteByUrlId(): string | null {
    return team?.inviteByUrlId ?? null
  },

  get prefs(): TeamPrefs | null {
    return team?.prefs ?? null
  },

  get colors(): string[] {
    return team?.colors || []
  },

  get logoUrl(): string | undefined {
    return team?.logo
  },

  get maySetLogoUrl(): boolean {
    return core.access.isTeamAdmin
  },

  async setLogoUrl(type: string, url: string): Promise<void> {
    if (!team) {
      return Promise.resolve()
    }

    const response = await core.fetchWithToken(
      'POST',
      `/api/team/update-logo`,
      { type, url }
    )
    const result = await response.json()
    if (result.error) {
      throw Error(result.error)
    }
  },

  get creationDate(): Date | undefined {
    return convertDate(team?.created)
  },

  getFeature<F extends Feature>(feat: F): StoredFeatures[F] | null {
    return team?.features?.[feat] ?? null
  },
  setFeature<F extends Feature>(
    feature: F,
    value: StoredFeatures[F]
  ): Promise<boolean> {
    if (!team) return Promise.resolve(false) // TODO:workspaces remove this check, team is always defined
    const newFeatures = { ...team.features, [feature]: value }
    return team.update({ features: newFeatures }, core.user.id)
  },

  /*
   * TODO:workspaces Delete everything below after migration
   */

  get customDomain(): string | undefined {
    return team?.customDomain
  },

  get currentSubscriber(): boolean {
    return !!team?.currentSubscriber
  },

  get customerId(): string | undefined {
    return team?.customerId
  },

  get isEnterprise(): boolean {
    return !!team?.isEnterprise
  },

  get trialEndDateIso(): string | undefined {
    return team?.trialEndDateIso
  },

  get isOverdue(): boolean {
    return !!team?.isOverdue && !team?.ignoreOverdue
  },

  get trialUpgradeRequested(): boolean {
    return !!team?.trialUpgradeRequested
  },
})
