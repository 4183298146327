"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsActiveSubscriber = void 0;
exports.getPlan = getPlan;
exports.getWorkspaceStatus = getWorkspaceStatus;
exports.isGrowthAndAbove = isGrowthAndAbove;
exports.subscriptionIsActive = subscriptionIsActive;
const _1 = require(".");
// TODO:workspaces deprecate getPlan
function getPlan(userProfile, team) {
    let plan;
    // The `undefined` state of `userProfile.currentSubscriber` means that the user has never been Pro.
    // For teams, we don't have that distinction, `currentSubscriber` is either `trueish` or `falseish`.
    if (userProfile.currentSubscriber) {
        plan = 'Pro';
    }
    else if (userProfile.currentSubscriber === false &&
        userProfile.customerId) {
        plan = 'Free (Pro Churned)';
    }
    else {
        plan = 'Free';
    }
    if (team && userProfile.isActiveMemberOfTeam) {
        if (team.currentSubscriber && team.customerId) {
            plan = 'Growth';
        }
        else if (team.currentSubscriber && !team.customerId) {
            plan = 'Growth Trial';
        }
        else if (!team.currentSubscriber && team.customerId) {
            plan = (plan + ' (Growth Churned)');
        }
        else if (!team.currentSubscriber && !team.customerId) {
            plan = (plan + ' (Growth Trial Ended)');
        }
    }
    if (team?.isEnterprise && userProfile.isActiveMemberOfTeam) {
        plan = 'Enterprise';
    }
    return plan;
}
function getWorkspaceStatus(workspace) {
    let trial = null;
    const activePlans = workspace.subscriptions
        .filter(subscriptionIsActive)
        .map(subscription => {
        if (subscription.plan === 'Growth' && subscription.isTrial) {
            trial = {
                startDate: (0, _1.convertDate)(subscription.startDate),
                endDate: (0, _1.convertDate)(subscription.endDate),
            };
        }
        return subscription.plan;
    });
    // Always pick the highest plan
    for (const plan of ['Enterprise', 'Growth', 'Pro']) {
        if (activePlans.includes(plan)) {
            return {
                plan,
                trial,
            };
        }
    }
    return {
        plan: 'Free',
        trial,
    };
}
function isGrowthAndAbove(status) {
    return ['Growth', 'Enterprise'].includes(status.plan);
}
function subscriptionIsActive(subscription) {
    return !subscription.endDate || (0, _1.convertDate)(subscription.endDate) > new Date();
}
// TODO:workspaces get rid of this
const selectIsActiveSubscriber = (data) => !!(data && data.currentSubscriber);
exports.selectIsActiveSubscriber = selectIsActiveSubscriber;
