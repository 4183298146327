import { USE_FIREBASE_EMULATOR } from '../constants'

export function isProductionEnv() {
  return (
    process.env.VERCEL_ENV === 'production' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
  )
}

export function isPreviewEnv() {
  return (
    process.env.VERCEL_ENV === 'preview' ||
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
  )
}

export function isStagingEnv() {
  return (
    (process.env.VERCEL_GIT_COMMIT_REF ||
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF) === 'staging'
  )
}

export function isPreprodEnv() {
  return (
    (process.env.VERCEL_GIT_COMMIT_REF ||
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF) === 'main'
  )
}

export function isLocalEnv() {
  return (
    USE_FIREBASE_EMULATOR ||
    (!isProductionEnv() && !isPreviewEnv() && !isStagingEnv())
  )
}
