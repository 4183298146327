import { isImageStep, isVideoStep } from '@arcadehq/shared/helpers'
import { getFilePathFromStorageUrl } from '@arcadehq/shared/helpers/cdn'
import {
  MaskLevel,
  MediaStep,
  Size,
  Step,
  SyntheticVoice,
  Timepoint,
  UserProfileDoc,
  VideoStep,
} from '@arcadehq/shared/types'
import { decode } from 'blurhash'
import { contrastColor } from 'contrast-color'
import chunk from 'lodash/chunk'
import memoize from 'lodash/memoize'
import React, { forwardRef } from 'react'
import { flushSync } from 'react-dom'

import {
  DEFAULT_PLAYBACK_RATE,
  FirebaseConfig,
  TIME_FRAC_MAX,
  TIME_FRAC_MIN,
  VIEWER_PLAYBACK_RATE_OPTIONS,
} from '../constants'
import {
  isLocalEnv,
  isPreprodEnv,
  isPreviewEnv,
  isProductionEnv,
  isStagingEnv,
} from './env-helpers'
import { FormSubmission, Team, User, UserProfile } from './types'

export { isLocalEnv, isPreprodEnv, isPreviewEnv, isProductionEnv, isStagingEnv }

export const isBrowser =
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  typeof document !== 'undefined'

export function widthAsPercentage(
  width: number,
  canvas: { height: number; width: number }
) {
  return width / (canvas.width ?? 1)
}

export function heightAsPercentage(
  height: number,
  canvas: { height: number; width: number }
) {
  return height / (canvas.height ?? 1)
}

export function percentageAsWidth(
  percentage: number,
  canvas: { height: number; width: number }
) {
  return (canvas.width ?? 1) * percentage
}

export function percentageAsHeight(
  percentage: number,
  canvas: { height: number; width: number }
) {
  return (canvas.height ?? 1) * percentage
}

export const toBase64 = (str: string) =>
  !isBrowser ? Buffer.from(str).toString('base64') : window.btoa(str)

export const fromBase64 = (str: string) =>
  !isBrowser ? Buffer.from(str, 'base64').toString() : window.atob(str)

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#eee" offset="20%" />
      <stop stop-color="#ccc" offset="50%" />
      <stop stop-color="#eee" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#eee" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

export const shimmerDataUrl = (h: number, w: number) =>
  `data:image/svg+xml;base64,${toBase64(shimmer(w, h))}`

export const blurCache: { [key: string]: string } = {}

export const getBlurDataUrlFromBlurhash = (
  blurhash?: string,
  width = 32,
  height = 32
) => {
  width = Math.round(width)
  height = Math.round(height)

  // server-size rendering fallback
  if (typeof document === 'undefined') {
    return shimmerDataUrl(width, height)
  }
  const cacheKey = `${blurhash}-${width}-${height}`

  if (blurCache[cacheKey]) {
    return blurCache[cacheKey]
  }

  let blurDataUrl = ''

  if (blurhash) {
    const pixels = decode(blurhash, width, height)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = width
    canvas.height = height

    try {
      const imgData = ctx!.createImageData(width, height)
      imgData.data.set(pixels)
      ctx!.putImageData(imgData, 0, 0)
      blurDataUrl = canvas.toDataURL()
    } catch (error) {
      // ignored because we use the shimmer data below instead
    }
  }

  if (!blurDataUrl) {
    blurDataUrl = shimmerDataUrl(width, height)
  }

  blurCache[cacheKey] = blurDataUrl
  return blurDataUrl
}

export const interpolate = (begin: number, end: number, curr: number) =>
  begin + (curr / 99) * (end - begin)

export const concatUnique = <T>(array: T[], newElement: T): T[] => {
  const set = new Set(array)
  set.add(newElement)
  return Array.from(set)
}

export const selectElementContents = (el: HTMLElement) => {
  const range = document.createRange()
  range.selectNodeContents(el)
  const sel = window.getSelection()
  sel?.removeAllRanges()
  sel?.addRange(range)
}

export function getPositionAndBounds(
  x: number,
  y: number,
  stageDimensions: { height: number; width: number },
  containerDimensions: { top: number; left: number }
) {
  const topBound = 10
  const leftBound = 10
  const rightBound = stageDimensions.width - 10
  const bottomBound = stageDimensions.height - 10
  const top = Math.min(
    Math.max(y - containerDimensions.top - 2, topBound),
    bottomBound
  )
  const left = Math.min(
    Math.max(x - containerDimensions.left - 2, leftBound),
    rightBound
  )
  return { x: left, y: top, topBound, leftBound, rightBound, bottomBound }
}

export function getDragPos(
  prev: { top: number; left: number },
  delta: { x: number; y: number },
  constraints: { top: number; right: number; bottom: number; left: number },
  stage: Size
) {
  const y = heightAsPercentage(
    Math.max(constraints.top, Math.min(constraints.bottom, prev.top + delta.y)),
    stage
  )
  const x = widthAsPercentage(
    Math.max(
      constraints.left,
      Math.min(constraints.right, prev.left + delta.x)
    ),
    stage
  )
  return { x, y }
}

export const mouseIsAlmostWithinElement = (
  event: React.MouseEvent,
  element: Element,
  padding: number
) => {
  const rect = element.getBoundingClientRect()
  return (
    event.clientX >= rect.x - padding &&
    event.clientX <= rect.x + rect.width + padding &&
    event.clientY >= rect.y - padding &&
    event.clientY <= rect.y + rect.height + padding
  )
}

export function optimizedImageUrl(url: string, filterOpts?: string) {
  if (!filterOpts) {
    filterOpts = 'fit=scale-down,f=auto'
    if (!isBrowser || !window?.innerWidth) {
      filterOpts = 'fit=scale-down,f=auto,width=1920,q=75'
    } else {
      const zoomRatio =
        (1 -
          Math.abs(window.innerWidth - 2000) /
            Math.max(window.innerWidth, 2000)) *
        100
      filterOpts = `${filterOpts},width=${window.innerWidth},q=${interpolate(
        60,
        75,
        zoomRatio
      )}`
      if (window.devicePixelRatio) {
        filterOpts = `${filterOpts},dpr=${window.devicePixelRatio}`
      }
    }
  }

  // This uses Cloudfront's image optimization service
  // https://developers.cloudflare.com/image-resizing/url-format
  return url.replace(
    'cdn.arcade.software/',
    `cdn.arcade.software/cdn-cgi/image/${filterOpts}/`
  )
}

export const integerOrDefault = (
  input: string | null,
  defaultTo = 0
): number => {
  if (input === null) return defaultTo
  return parseInt(input)
}

export const quotientOrNull = (
  numerator: string | number | null,
  denominator: string | number | null
): number | null => {
  if (numerator === '0' || numerator === 0) {
    return 0
  }
  if (denominator === '0' || denominator === 0 || denominator === null) {
    return null
  }
  if (numerator === null) {
    return 0
  }
  return +numerator / +denominator
}

export function isPointingToProduction() {
  return FirebaseConfig.projectId === 'arcade-cf7d5'
}

export function getBaseUrl(forDemoHost = false) {
  if (isStagingEnv()) {
    return 'https://staging.arcade.software'
  }

  if (isPreprodEnv()) {
    return 'https://next.arcade.software'
  }

  if (isProductionEnv() && !forDemoHost) {
    return 'https://app.arcade.software'
  }

  if (isProductionEnv() && forDemoHost) {
    return 'https://demo.arcade.software'
  }

  if (isPreviewEnv()) {
    return `https://${
      process.env.VERCEL_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL
    }`
  }

  return 'http://localhost:3000'
}

export function getAuthBaseUrl(path: string) {
  return `${getBaseUrl()}/auth?redirect=${encodeURIComponent(path)}`
}

export function getShareBaseUrl(customDomain?: string) {
  if (customDomain) {
    return `https://${customDomain}`
  }

  return getBaseUrl()
}

export function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export function hexColorRegex() {
  return /#([a-f0-9]{3}|[a-f0-9]{6}|[a-f0-9]{8})\b/gi
}

export const safeInvertColor = memoize((color: string): string => {
  try {
    return contrastColor({
      bgColor: color,
      fgDarkColor: '#111827',
      defaultColor: '#111827',
      fgLightColor: '#FFFFFF',
    }).toLowerCase()
  } catch (e) {
    return '#ffffff'
  }
})

export function isCurrentCustomer(
  customer: User | UserProfile | Team | undefined
) {
  return customer?.customerId && customer?.currentSubscriber
}

export class HistoryStack<T> {
  protected stack: T[]
  protected currIdx: number

  constructor() {
    this.stack = []
    this.currIdx = -1
  }

  add(item: T) {
    if (item === this.stack[this.stack.length - 1]) return
    this.stack = [...this.stack.slice(0, this.currIdx + 1), item]
    this.currIdx = this.stack.length - 1
  }

  getAll() {
    return this.stack
  }

  getCurrent() {
    return this.stack[this.currIdx]
  }

  hasPrevious() {
    return this.currIdx > 0
  }

  hasNext() {
    return this.currIdx < this.stack.length - 1
  }

  getPrevious() {
    this.currIdx = this.currIdx >= 0 ? this.currIdx - 1 : -1
    return this.currIdx === -1 ? null : this.stack[this.currIdx]
  }

  getNext() {
    this.currIdx =
      this.currIdx + 1 < this.stack.length
        ? this.currIdx + 1
        : this.stack.length
    return this.currIdx === this.stack.length ? null : this.stack[this.currIdx]
  }
}

export function downloadFile(url: string, filename: string) {
  if (!isBrowser) return

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.target = '_blank'

  // For the download attribute to work, the file must be on the same origin
  a.download = filename

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const getInitials = (name: string | null) => {
  if (!name) {
    return 'A'
  }

  const names = name.toUpperCase().split(' ')
  if (names.length === 1) {
    return names[0]?.charAt(0)
  }
  return names[0]?.charAt(0) + (names[names.length - 1] || '').charAt(0)
}

type AvatarUrlProps =
  | {
      userProfile: UserProfileDoc
      size?: number
      useGravatar?: boolean
    }
  | {
      id?: string
      name: string
      email: string
      photoURL: string
      size?: number
      useGravatar?: boolean
    }

export const resolveAvatarUrl = async (props: AvatarUrlProps) => {
  const { id, name, email, photoURL } =
    'userProfile' in props ? props.userProfile : props
  if (!id || !name || !email) return `https://avatar.vercel.sh/${Math.random()}`
  const { image } = await import('gravatar-gen')
  return props.useGravatar
    ? email
      ? await image(email, {
          size: 100,
          defaultImage: `https://avatar.vercel.sh/${id}`,
          protocol: 'https',
          rating: 'pg',
        })
      : photoURL ||
        `https://avatar.vercel.sh/${id}.svg?text=${getInitials(
          name ?? email?.split('@')[0] ?? null
        )}`
    : photoURL ||
        `https://avatar.vercel.sh/${id}.svg?text=${getInitials(
          name ?? email?.split('@')[0] ?? null
        )}`
}

export function useResolvedAvatarUrl(props: AvatarUrlProps) {
  const [avatarUrl, setAvatarUrl] = React.useState<string | undefined>(
    undefined
  )
  React.useEffect(
    () => void resolveAvatarUrl(props).then(setAvatarUrl),
    [props]
  )
  return avatarUrl
}

export const getCdnUrl = (url: string) => {
  if (
    (url.startsWith('https://firebasestorage') ||
      url.startsWith('https://storage.googleapis.com')) &&
    isPointingToProduction()
  ) {
    return `https://cdn.arcade.software/${getFilePathFromStorageUrl(url)}`
  }

  return url.match('emulator.appspot.com')
    ? `http://127.0.0.1:9199/emulator.appspot.com/${getFilePathFromStorageUrl(
        url
      )}`
    : url
}

export function getProxiedImageUrl(url: string) {
  if (url.startsWith('https://s3.us-east-1.amazonaws.com/')) {
    const path = new URL(url).pathname
    return `/s3-proxy/us-east-1${path}`
  }

  const filePath = getFilePathFromStorageUrl(url)
  if (!filePath) return undefined
  return `/cdn-proxy/${filePath}`
}

export const asBooleanDict = (ids: string[]): { [id: string]: true } =>
  ids.reduce((dict, id) => ({ ...dict, [id]: true }), {})

export const arrayOfTrueKeys = (dict: { [id: string]: boolean }) =>
  Object.entries(dict)
    .filter(([, value]) => !!value)
    .map(([key]) => key)

export const alphabeticalComparator = (a: string, b: string) => {
  if (!a && !b) return 0
  if (!a) return 1
  if (!b) return -1
  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
}

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const roundToPlaces = (num: number, precision: number) => {
  const factor = Math.pow(10, precision)
  return Math.round(num * factor) / factor
}

export function formatUrlInput(input: string) {
  let isValidUrl = false
  try {
    const url = new URL(input)
    isValidUrl = url.origin !== 'null' && url.protocol.startsWith('http')
  } catch (error) {
    isValidUrl = false
  }
  if (isValidUrl) {
    // If it's a valid URL, remove duplicate protocols and return with https:// prefix
    return input.replace(/^(https?:\/\/)+/, 'https://')
  } else if (input.startsWith('mailto:')) {
    // If it's an email address, return as is
    return input
  } else if (input.includes('@')) {
    // If it contains '@', consider it as a potential email address
    return 'mailto:' + input
  } else {
    // If it's neither a URL nor an email address, return the input
    return input
  }
}

export const isValidHttpsOrMailtoUrl = (str: string) => {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }
  return ['https:', 'mailto:'].includes(url.protocol)
}

export function isMacOS(): boolean {
  if (typeof navigator !== 'undefined') {
    const platform = navigator.userAgentData?.platform ?? navigator.platform
    return !!platform?.toLowerCase().startsWith('mac')
  }
  return false
}

export const formatCurrency = (num: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: num % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(num)
}

export function getPlaybackUrl(playbackId: string) {
  return `https://stream.mux.com/${playbackId}.m3u8`
}

export function findClosestTimestampIndex(
  targetTimestamp: number,
  timestampArray: number[]
) {
  if (timestampArray.length === 0) {
    return -1 // Handle the case when the array is empty
  }

  let closestIndex = 0
  let closestDifference = Math.abs(targetTimestamp - (timestampArray[0] || 0))

  for (let i = 1; i < timestampArray.length; i++) {
    const currentDifference = Math.abs(
      targetTimestamp - (timestampArray[i] || 0)
    )
    if (currentDifference <= closestDifference) {
      closestIndex = i
      closestDifference = currentDifference
    }
  }

  return closestIndex
}

export function getCaptionChunks(
  script: SyntheticVoice['script'] | undefined,
  chunkSize: number
) {
  const words = script
    ?.replace(/<break\s*[^>]*\/?>/gi, ' ') // removes <break /> tags
    .replace(/<phoneme\s*[^>]*>(.*?)<\/phoneme>/gi, '$1') // removes <phoneme /> tags
    .replace(/\n+/g, ' ') // matches one or more consecutive newline characters (\n) using the + quantifier and the g flag for global matching.
    .replace(/\s{2,}/g, ' ') // matches two or more consecutive whitespace characters (including spaces) using the {2,} quantifier and the g flag for global matching.
    .split(' ')
    .filter(word => word !== '')
  return chunk(words, chunkSize).map(chunk => chunk.join(' '))
}

export function getCaptionAtTime(
  {
    chunks,
    chunkSize,
    timepoints,
  }: { chunks: string[]; chunkSize: number; timepoints?: Timepoint[] },
  { time, duration }: { time: number; duration: number }
) {
  // If we have timepoints, use them to determine which caption chunk to show
  if (timepoints?.length) {
    const chunkedTimepoints = timepoints
      .sort((a, b) => a.timeSeconds - b.timeSeconds)
      .map((p, idx) => (idx % chunkSize === 0 ? p.timeSeconds : null))
      .filter((p): p is number => p !== null)
    const captionIndex = findClosestTimestampIndex(
      time - 1, // need to offset this by 1s since the timepoints seem to be a little bit off
      chunkedTimepoints
    )
    if (captionIndex > -1) return chunks[captionIndex] || ''
  } else {
    // No timepoints available... let's interpolate the caption playback rate
    const durationPerChunk = duration / (chunks.length ?? 1)
    const currentChunkIdx = Math.floor(time / durationPerChunk)
    if (currentChunkIdx < chunks.length) return chunks[currentChunkIdx] || ''
    return chunks[chunks.length - 1] || ''
  }

  return ''
}

export const getUrl = (
  baseUrl: string,
  queryParams?: Record<string, string | string[] | undefined>
) => {
  if (!queryParams) return baseUrl
  const searchParams = new URLSearchParams()
  for (const [key, value] of Object.entries(queryParams)) {
    if (Array.isArray(value)) {
      value.forEach(v => searchParams.append(key, v))
    } else if (typeof value !== 'undefined') {
      searchParams.set(key, value)
    }
  }
  return `${baseUrl}?${searchParams.toString()}`
}

export const addQueryParamToURL = (
  url: string,
  paramName: string,
  paramValue: string
) => {
  const [base, paramString] = url.split('?')
  const searchParams = new URLSearchParams(paramString)

  searchParams.set(paramName, paramValue)

  return `${base}?${searchParams.toString()}`
}

export const relativeToAbsoluteUrl = (url: string) =>
  url.startsWith('http') ? url : `${getBaseUrl()}${url}`

// TLDR: It's forwardRef that works well with generics because it removes
// things like displayName so TS is smarter here
// https://fettblog.eu/typescript-react-generic-forward-refs/
type FixedForwardRef = <T, P = Record<string, never>>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode
  // it should work with React.ReactNode as return, but maybe because of
  // ReactJS version it does not
) => (props: P & React.RefAttributes<T>) => React.ReactElement | null

export const fixedForwardRef = forwardRef as FixedForwardRef

export function eventPositionPercentage(
  element: HTMLDivElement,
  event: MouseEvent | React.MouseEvent | TouchEvent | React.TouchEvent,
  offset?: number
): number | null {
  const clientX = 'clientX' in event ? event.clientX : event.touches[0]?.clientX
  if (clientX == undefined) return null
  const { x, width } = element.getBoundingClientRect()
  return Math.min(Math.max((clientX - x + (offset ?? 0)) / width, 0), 1)
}

export function shouldUseVideoClip(
  step: VideoStep,
  inEditMode: boolean
): step is VideoStep & { streamUrl: string } {
  return (
    !inEditMode &&
    step.url !== step.streamUrl &&
    !!step.streamUrl &&
    !step.videoProcessing
  )
}

export function getVideoSrc(step: VideoStep, inEditMode: boolean): string {
  if (shouldUseVideoClip(step, inEditMode)) return step.streamUrl
  return step.url // Will be empty string if step.videoProcessing
}

export function getVideoStartFrac(step: VideoStep, inEditMode: boolean) {
  if (shouldUseVideoClip(step, inEditMode)) return TIME_FRAC_MIN
  return step.startTimeFrac ?? TIME_FRAC_MIN
}

export function getVideoEndFrac(step: VideoStep, inEditMode: boolean) {
  if (shouldUseVideoClip(step, inEditMode)) return TIME_FRAC_MAX
  return step.endTimeFrac ?? TIME_FRAC_MAX
}

export async function copyImageFromUrlToClipboard(url: string) {
  const corsSafeUrl = url.startsWith('https://cdn.arcade.software')
    ? url.replace('https://cdn.arcade.software/', '/cdn-proxy/')
    : url
  const responsePromise = fetch(corsSafeUrl)
  try {
    if ('write' in navigator.clipboard) {
      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': new Promise(resolve => {
            const blob = responsePromise.then(response => response.blob())
            resolve(blob)
          }),
        }),
      ])
      return true
    }
  } catch (err) {
    return false
  }

  return false
}

export function getRandomInt(nonInclusiveMax: number) {
  return Math.floor(Math.random() * nonInclusiveMax)
}

export function safePlaybackRate(rate: number) {
  return typeof window !== 'undefined' && isMobile() && isSafari()
    ? Math.min(rate, 2) // iOS safari seems to cap hls playback rates at 2; if higher, the media stalls and becomes unplayable
    : rate
}

function isViewerPlaybackRateAllowed(rate: number, steps: Step[]) {
  if (rate === DEFAULT_PLAYBACK_RATE) return true
  if (rate > safePlaybackRate(rate)) return false
  for (const step of steps) {
    if (isVideoStep(step) && !step.muted) {
      const effectiveRate = rate * (step.playbackRate ?? 1)
      // Many browsers won't play audio outside of a 0.5 - 4.0 range
      if (effectiveRate > safePlaybackRate(4)) return false
      if (effectiveRate < 0.5) return false
    }
  }
  return true
}

export function getViewerPlaybackRateOptions(steps: Step[]) {
  return VIEWER_PLAYBACK_RATE_OPTIONS.filter(o =>
    isViewerPlaybackRateAllowed(o, steps)
  )
}

export function getViewerPlaybackRateLimits(steps: Step[]): [number, number] {
  const options = getViewerPlaybackRateOptions(steps)
  return [
    options[0] ?? DEFAULT_PLAYBACK_RATE,
    options[options.length - 1] ?? DEFAULT_PLAYBACK_RATE,
  ]
}

/** Image Editor Helper Utils */
export function hasMask(step: Step | null | undefined): boolean {
  if (!isImageStep(step)) return false
  return (
    !!step.imageEditorState?.decoration &&
    step.imageEditorState?.decoration.length > 0
  )
}

export function hasAnnotations(step: Step | null | undefined): boolean {
  if (!isImageStep(step)) return false
  return (
    !!step.imageEditorState?.annotation &&
    step.imageEditorState?.annotation.length > 0
  )
}

// Pintura returns shape dimensions as percentage strings ex: '12.3456789%'
export const roundedShapePercentage = (percentageString: string) => {
  const percentage = parseFloat(percentageString.replace('%', ''))
  const roundedPercentage = Math.round(percentage * 100) / 100
  return roundedPercentage
}

export const getShapesForMasking = (
  outerRect: { width: number; height: number } | undefined,
  innerRect: {
    x: string | number | undefined
    y: string | number | undefined
    width: string | number | undefined
    height: string | number | undefined
  }
): {
  x: number
  y: number
  width: number
  height: number
  isSelectedRegion: boolean
}[] => {
  if (!outerRect || !outerRect.width || !outerRect.height) {
    return []
  }

  if (
    !innerRect ||
    !innerRect.width ||
    !innerRect.height ||
    !innerRect.x ||
    !innerRect.y
  ) {
    return []
  }

  const selectedRegion = {
    x:
      typeof innerRect.x === 'string'
        ? Math.round(
            roundedShapePercentage(innerRect.x) * (outerRect.width / 100)
          )
        : Math.round(Number(innerRect.x)),
    y:
      typeof innerRect.y === 'string'
        ? Math.round(
            roundedShapePercentage(innerRect.y) * (outerRect.height / 100)
          )
        : Math.round(Number(innerRect.y)),
    width:
      typeof innerRect.width === 'string'
        ? Math.round(
            roundedShapePercentage(innerRect.width) * (outerRect.width / 100)
          )
        : Math.round(Number(innerRect.width)),
    height:
      typeof innerRect.height === 'string'
        ? Math.round(
            roundedShapePercentage(innerRect.height) * (outerRect.height / 100)
          )
        : Math.round(Number(innerRect.height)),
    isSelectedRegion: true,
  }

  const topRectangle = {
    x: selectedRegion.x,
    y: 0,
    width: selectedRegion.width,
    height: selectedRegion.y,
    isSelectedRegion: false,
  }

  const bottomRectangle = {
    x: selectedRegion.x,
    y: selectedRegion.y + selectedRegion.height,
    width: selectedRegion.width,
    height: outerRect.height - (selectedRegion.y + selectedRegion.height),
    isSelectedRegion: false,
  }

  const leftRectangle = {
    x: 0,
    y: 0,
    width: selectedRegion.x,
    height: outerRect.height,
    isSelectedRegion: false,
  }

  const rightRectangle = {
    x: selectedRegion.x + selectedRegion.width,
    y: 0,
    width: outerRect.width - (selectedRegion.x + selectedRegion.width),
    height: outerRect.height,
    isSelectedRegion: false,
  }
  return [
    topRectangle,
    bottomRectangle,
    leftRectangle,
    rightRectangle,
    selectedRegion,
  ]
}

export const getMaskOpacity = (maskLevel: MaskLevel): number => {
  if (maskLevel === MaskLevel.Large) {
    return 0.9
  } else if (maskLevel === MaskLevel.Medium) {
    return 0.75
  }
  return 0.5
}

export function getFormEmail(form: FormSubmission) {
  return `${
    Object.values(form.answers).find(a => a.variant === 'email' && !!a.value)
      ?.value ?? ''
  }`
}

export function formatSeconds(seconds: number): string {
  const [minutes, secondsLeft] = [
    Math.floor((seconds % 3600) / 60),
    seconds % 60,
  ]
  return `${minutes.toString().padStart(2, '0')}:${secondsLeft
    .toString()
    .padStart(2, '0')}`
}

type EventType = React.MouseEvent | React.KeyboardEvent

export function openWithViewTransition(
  evt: EventType | null,
  onClick: () => void,
  viewTransitionClassname?: string, // use this to apply the view transition classname on click
  viewTransitionElementSelector?: string // use this if you want to apply the viewTransitionClassname to an inner element instead of the target element
) {
  if (!document.startViewTransition) {
    onClick()
  } else {
    evt?.preventDefault()

    // TODO: This is not actually working as expected with NextJS Router. To test this you need to add
    //  @view-transition { navigation: auto; }
    // to global.css)
    try {
      if (viewTransitionClassname) {
        if (viewTransitionElementSelector) {
          const targetElements = document.querySelectorAll(
            viewTransitionElementSelector
          )
          // The view transition won't work if there are multiple elements to apply the view transition to
          if (targetElements.length === 1) {
            targetElements[0]?.classList.add(viewTransitionClassname)
          }
        } else if (evt?.target instanceof HTMLElement) {
          evt.target.classList.add(viewTransitionClassname)
        }
      }
    } catch (e) {
      // no-op: this is a "nice to have" feature and should not break the app if it fails
    }

    document.startViewTransition(() => {
      flushSync(() => {
        onClick()
      })
    })
  }
}

function padTime(time: number) {
  return `${time >= 10 ? '' : '0'}${time}`
}

export function formatTime(time: number) {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${padTime(minutes)}:${padTime(seconds)}`
}

export function elementIsInput(el: HTMLElement) {
  return ['INPUT', 'TEXTAREA'].includes(el.nodeName) || el.isContentEditable
}

export function isValidFileToUpload(file: File) {
  return (
    file.size > 0 &&
    (file.type.includes('image') ||
      file.type.includes('video') ||
      file.type.includes('pdf'))
  )
}

export function coverAndFitClasses(mediaStep: MediaStep) {
  return {
    '!object-cover':
      (mediaStep.coverAndFit?.type && mediaStep.coverAndFit.type === 'cover') ||
      !mediaStep.coverAndFit ||
      !mediaStep.coverAndFit.type,
    '!object-contain':
      mediaStep.coverAndFit?.type && mediaStep.coverAndFit.type === 'fit',
    '!object-top':
      mediaStep.coverAndFit?.align && mediaStep.coverAndFit.align === 'top',
    '!object-right':
      mediaStep.coverAndFit?.align && mediaStep.coverAndFit.align === 'right',
    '!object-bottom':
      mediaStep.coverAndFit?.align && mediaStep.coverAndFit.align === 'bottom',
    '!object-left':
      mediaStep.coverAndFit?.align && mediaStep.coverAndFit.align === 'left',
    '!object-center':
      mediaStep.coverAndFit?.align && mediaStep.coverAndFit.align === 'center',
  }
}

export function isValidCompanyDomain(domain: string): boolean {
  const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/

  if (!domainRegex.test(domain)) {
    return false
  }

  try {
    // Attempt to create a URL object
    new URL(`https://${domain}`)
    return true
  } catch {
    return false
  }
}
