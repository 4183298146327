import {
  LanguageCode,
  Position,
  Size,
  ViewerLayout,
  Voiceover,
} from '@arcadehq/shared/types'
import { atom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { ChromeReminderModalProps } from 'src/components/ChromeReminderModal'
import { ConfirmationModalProps } from 'src/components/ConfirmationModal'
import { FlowLikeEntity } from 'src/components/Flows/types'
import EventTracker from 'src/tracking/EventTracker'

export const atoms = {
  activeVideoTarget: atom<string | null>(null),
  confirmation: atom<ConfirmationModalProps | null>(null),
  chromeReminder: atom<ChromeReminderModalProps | null>(null),
  flowsSelected: atom<FlowLikeEntity[] | null>(null),
  flowAudienceRevealCount: atomWithReset<string>('0'),
  flowFormSubmissionsCount: atomWithReset<string>('0'),
  flowsDrag: atom<FlowLikeEntity[]>([]),
  isFullscreen: atom<boolean>(false),
  isPreviewMobile: atomWithReset<boolean>(false),
  previewLanguage: atomWithReset<LanguageCode>('default'),
  isPanAndZoomAnimationActive: atom<boolean>(false),
  lastActiveHotspotOrCalloutPosition: atom<Position | null>(null),
  isStartOverlayActive: atom<boolean>(false),
  mouseIsOnTopOfHotspot: atom<boolean>(false),
  multipleStepSelection: atom<string[]>([]),
  selectedVoiceover: atomWithReset<Voiceover | null>(null),
  showFlowNavIndicator: atom<boolean>(false),
  stageDimensions: atomWithReset<Size & { layout: ViewerLayout }>({
    height: 0,
    width: 0,
    layout: ViewerLayout.Default,
  }),
  firstStepContentIsReady: atom<boolean>(false),
  zoomBox: atom<{
    top: number
    left: number
    height: number
    width: number
    zoomLevel: number
  }>({
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    zoomLevel: 1.5,
  }),
  zoomPreviewOn: atom<boolean>(false),
  showTextFormattingOptions: atom<boolean>(false),
  viewerFormResponses: atom<Record<string, Record<string, any>>>({}),
  viewerDemoMode: atomWithReset<boolean>(false),
  hideHotspots: atom<boolean>(false),
  editorViewType: atom<'linear' | 'map'>('linear'),
  pageTracker: atom<EventTracker | null>(null),
}
