const projectId =
  process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ??
  process.env.GCLOUD_PROJECT ??
  'arcade-cf7d5'

const previewDomain =
  process.env.VERCEL_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL

const contentSecurityPolicy = () => {
  const defaultSrc = ['default-src', "'self'"].join(' ')
  const connectSrc = [
    'connect-src',
    "'self'",
    'blob:',
    'data:',
    'https://*.algolia.net',
    'https://*.algolianet.com',
    'https://*.amplitude.com',
    'https://*.analytics.google.com',
    'https://*.google-analytics.com',
    'https://*.googleapis.com',
    'https://*.googletagmanager.com',
    'https://*.hotjar.com',
    'https://*.hotjar.io',
    'https://*.liveblocks.io',
    'https://*.ingest.sentry.io',
    'https://*.intercom.io',
    'https://*.intercom.io',
    'https://*.intercomcdn.com',
    'https://*.litix.io',
    'https://*.mux.com',
    'https://analytics.google.com',
    'https://api-iam.intercom.io',
    'https://api-js.mixpanel.com',
    'https://api.segment.io',
    'https://*.posthog.com',
    'https://cdn.arcade.software',
    'https://cdn.linkedin.oribi.io',
    'https://cdn.segment.com',
    'https://firebasestorage.googleapis.com',
    'https://indigo-toad.aws-us-east-1.cubecloudapp.dev',
    'https://px.ads.linkedin.com',
    'https://risk.clearbit.com',
    'https://salmon-warbler.aws-us-east-1.cubecloudapp.dev',
    'https://t.arcade.show',
    'wss://*.hotjar.com',
    'wss://*.intercom.io',
    'wss://*.liveblocks.io',
    'https://*.hsforms.com',
    'https://hubspot-forms-static-embed.s3.amazonaws.com',
    'https://api.typeform.com',
    'https://cdn.jsdelivr.net', // TODO ask Liveblocks to host the emoji scripts on their own domain

    // Direct connection to Firebase functions
    'https://us-central1-arcade-cf7d5.cloudfunctions.net',

    // Allow the Vercel preview deployment to connect to the production API
    ...(!['main', 'prod'].includes(process.env.VERCEL_GIT_COMMIT_REF)
      ? [
          `https://${process.env.VERCEL_URL}`,
          'https://*.preview.arcade.software',
        ]
      : []),
  ].join(' ')

  const scriptSrc = [
    'script-src',
    "'self'",
    "'unsafe-eval'",
    "'unsafe-inline'",
    'blob:',
    'https://*.amplitude.com',
    'https://*.firebaseio.com',
    'https://*.googletagmanager.com',
    'https://*.hotjar.com',
    'https://*.intercomcdn.com',
    'https://apis.google.com',
    'https://cdn.arcade.software',
    'https://cdn.firebase.com',
    'https://cdn.mxpnl.com',
    'https://cdn.segment.com',
    'https://googletagmanager.com',
    'https://indigo-toad.aws-us-east-1.cubecloudapp.dev',
    'https://js.hs-scripts.com',
    'https://js.stripe.com',
    'https://risk.clearbit.com',
    'https://salmon-warbler.aws-us-east-1.cubecloudapp.dev',
    'https://snap.licdn.com',
    'https://tagmanager.google.com',
    'https://vercel.live',
    'https://widget.intercom.io',
    'https://www.google.com',
    'https://www.gstatic.com',
    'https://*.hsforms.net',
    'https://assets.calendly.com',
    'https://embed.typeform.com',
    'https://*.marketo.com',
    'https://*.mktoweb.com',
  ].join(' ')

  const imgSrc = [
    'img-src',
    "'self'",
    'data:',
    'blob:',
    'https://*.google-analytics.com',
    'https://*.googletagmanager.com',
    'https://*.googleusercontent.com',
    'https://*.hotjar.com',
    'https://*.intercomassets.com',
    'https://*.intercomcdn.com',
    'https://*.mux.com',
    'https://assets.vercel.com',
    'https://cdn.arcade.software',
    'https://firebasestorage.googleapis.com',
    'https://googletagmanager.com',
    'https://logo.clearbit.com',
    'https://messenger-apps.intercom.io',
    'https://px.ads.linkedin.com',
    'https://ssl.gstatic.com',
    'https://www.gstatic.com',
    'https://*.hsforms.com',
    'https://i.ytimg.com',
    'https://*.hubspotusercontent.net',
    'https://*.hubspotusercontent-eu1.net',
  ].join(' ')

  const mediaSrc = [
    'media-src',
    "'self'",
    'blob:',
    'https://*.intercomcdn.com',
    'https://*.mux.com',
    'https://cdn.arcade.software',
    'https://storage.googleapis.com/eleven-public-prod/',
  ].join(' ')

  const styleSrc = [
    'style-src',
    "'self'",
    "'unsafe-inline'",
    'https://*.googletagmanager.com',
    'https://*.hotjar.com',
    'https://fonts.googleapis.com',
    'https://googletagmanager.com',
    'https://tagmanager.google.com',
    'https://embed.typeform.com',
    'https://*.marketo.com',
    'https://*.mktoweb.com',
  ].join(' ')

  const fontSrc = [
    'font-src',
    "'self'",
    'data:',
    'https://*.hotjar.com',
    'https://cdn.arcade.software',
    'https://fonts.googleapis.com',
    'https://fonts.gstatic.com',
    'https://fonts.intercomcdn.com',
  ].join(' ')

  const frameSrc = [
    'frame-src',
    "'self'",
    'https://*.firebaseapp.com/',
    'https://*.hotjar.com',
    'https://app.arcade.software',
    'https://demo.arcade.software',
    'https://auth.arcade.software',
    'https://intercom-sheets.com',
    'https://vercel.live',
    'https://www.google.com',
    'https://cdn.arcade.software',
    'https://firebasestorage.googleapis.com',
    'https://calendly.com',
    'https://www.youtube.com',
    'https://app.hubspot.com',
    'https://*.hsforms.com',
    'https://form.typeform.com',
    'https://*.marketo.com',
    'https://*.mktoweb.com',
    ...(previewDomain ? [`https://${previewDomain}`] : []),
  ].join(' ')

  return (
    projectId !== 'emulator' && !projectId.startsWith('dev-')
      ? [
          defaultSrc,
          connectSrc,
          scriptSrc,
          imgSrc,
          mediaSrc,
          styleSrc,
          fontSrc,
          frameSrc,
        ]
      : []
  ).join('; ')
}

module.exports = {
  projectId,
  contentSecurityPolicy,
}
