import { setUser as setSentryUser } from '@sentry/nextjs'
import { createContext, PropsWithChildren, useEffect } from 'react'
import { Account } from 'src/auth/Account'
import defaultAccount from 'src/auth/defaultAccount'
import DevWorkspaceInfos from 'src/components/DevWorkspaceInfos'
import { isLocalEnv } from 'src/env-helpers'
import { Team, User, Workspace } from 'src/types'
import { passUserToExtension } from 'src/utils/extension'

// see src/auth/withAuth.tsx for details
// the actual user is loaded in `withAuth`
export const defaultUser: User = {
  id: null,
  isLoggedIn: false,
  name: null,
  email: null,
  emailVerified: false,
  emailDomain: null,
  photoURL: null,
  getIdToken: async () => null,
  clientInitialized: false,
  firebaseUser: null,
  signOut: async () => {},
  group: null,
  intercomUserId: null,
  intercomUserHash: null,
  prefs: null,
  colors: null,
  features: null,
  currentSubscriber: false,
  customerId: null,
  subscriptionDate: null,
  activeTeamId: null,
  isActiveMemberOfTeam: false,
  isExternalMemberOfTeam: false,
  isTeamAdmin: false,
  onboardingUseCases: {
    Marketing: false,
    Product: false,
    Sales: false,
    CustomerSuccess: false,
    Other: null,
  },
  onboardingCompanySize: null,
  getStartedArticlesHidden: null,
  dismissedGrowthTrialBanner: false,
  isOverdue: false,
  overdueDate: null,
  ignoreOverdue: false,
  announcements: null,
  featuresDiscovered: null,
  tenantId: null,
  isCommentsUser: false,

  // Workspace related:
  workspaces: [],
  teams: [],
  lastUsedWorkspaceId: null,
  lastUsedTeamId: null,

  serialize: () => '',
}

export const AuthContext = createContext<{
  user: User
  team: Team | null
  workspace: Workspace | null
  account: Account
  algoliaApiKey?: string
}>({
  user: defaultUser,
  team: null,
  workspace: null,
  account: defaultAccount,
})

export function AuthProvider({
  user,
  team,
  workspace,
  account = defaultAccount,
  algoliaApiKey,
  children,
}: PropsWithChildren<{
  user?: User
  team?: Team | null
  workspace?: Workspace | null
  account?: Account
  algoliaApiKey?: string
}>) {
  useEffect(() => {
    if (account.user.id) {
      setSentryUser({ id: account.user.id })
      void passUserToExtension({
        id: account.user.id,
        email: account.user.email,
        displayName: account.user.name,
      })
      // hotjar
      ;(window as any).hj?.('identify', account.user.id, {})
    }
  }, [account.user.email, account.user.id, account.user.name])

  const value = {
    user: user ?? defaultUser,
    team: team ?? null,
    workspace: workspace ?? null,
    account,
    ...(algoliaApiKey ? { algoliaApiKey } : {}),
  }

  const devWorkspaceInfos =
    isLocalEnv() && workspace ? <DevWorkspaceInfos /> : null

  return (
    <AuthContext.Provider value={value}>
      {children}
      {devWorkspaceInfos}
    </AuthContext.Provider>
  )
}
