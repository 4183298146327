import { DEFAULT_BG_MUSIC_VOLUME } from '@arcadehq/shared/constants'
import {
  getTranslatedFlowDescription,
  getTranslatedFlowNameExternal,
  getTranslatedStepData,
  isImageStep,
  isMediaStep,
  isNonEmptyStep,
  isOverlayStep,
  isOverlayStepWithEmbeds,
  isOverlayStepWithoutPaths,
  isOverlayStepWithPaths,
  isVideoStep,
  stepHasCameraRecording,
  stepHasSyntheticVoiceover,
} from '@arcadehq/shared/helpers'
import {
  CustomCursor,
  FlowDataDoc,
  FlowNavSetting,
  FlowWrapper,
  LanguageCode,
  NonEmptyStep,
  PublishStatus,
  Step,
  StepType,
} from '@arcadehq/shared/types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { Account } from 'src/auth/Account'
import { FlowDefaults } from 'src/auth/features/flow-defaults'
import { getRandomInt } from 'src/helpers'
import { Flow, FlowData, FlowPublicData } from 'src/types'

import bgImages from '../components/BackgroundImageSelect/list.json'
import {
  getFromSerialized,
  getSerializable,
  SerializableData,
} from './serializable'
import { getStepPreviewUrl } from './steps'

export const selectPublicFlowData = (flow: Flow): FlowPublicData => ({
  ...pick(flow, [
    'id',
    'created',
    'modified',
    'name',
    'externalName',
    'description',
    'aspectRatio',
    'cta',
    'steps',
    'createdBy',
    'editors',
    'status',
    'submittedForSpotlight',
    'flowWrapper',
    'customCursor',
    'showBackNextButtons',
    'font',
    'bgImage',
    'group',
    'showArcadeButton',
    'openingAnimation',
    'belongsToTeam',
    'showFlowNavIndicator',
    'optimizeFlowForMobileView',
    'showStartOverlay',
    'startOverlayButtonText',
    'preventIndexing',
    'structureHash',
    'structureBits',
    'isTemplate',
    'autoplay',
    'backgroundMusicUrl',
    'backgroundMusicVolume',
    'customVariables',
    'thumbnailUrl',
    'translations',
    'showCaptions',
    'demoModeByDefault',
    'sharePageButton',
    'sharePageLogo',
    'watermark',
    'prefs',
    'useMagicCursor',
  ]),
  // Don't leak the actual timestamp but "anonymize" it to 1970-01-01
  firstViewed: flow.firstViewed ? new Date(0) : null,
  steps: flow.steps.map((step): Step => {
    const base = {
      id: step.id,
      hiddenFromNavigation: step.hiddenFromNavigation,
    }
    if (!isNonEmptyStep(step)) {
      return { ...base, type: step.type }
    }
    const nonEmptyBase = {
      ...base,
      syntheticVoice: step.syntheticVoice,
      cameraRecording: step.cameraRecording,
      cta: step.cta,
    }
    if (isOverlayStep(step)) {
      const overlayBase = {
        ...nonEmptyBase,
        type: step.type,
        title: step.title,
        subtitle: step.subtitle,
        blur: step.blur,
        theme: step.theme,
        variant: step.variant,
        textAlign: step.textAlign,
        customTheme: step.customTheme,
        formFields: step.formFields,
        autoplay: step.autoplay,
      }

      if (isOverlayStepWithEmbeds(step)) {
        return {
          ...overlayBase,
          embedCode: step.embedCode,
        }
      }
      if (isOverlayStepWithPaths(step)) {
        return {
          ...overlayBase,
          paths: step.paths,
          type: step.type,
        }
      }
      if (isOverlayStepWithoutPaths(step)) {
        return {
          ...overlayBase,
          buttonText: step.buttonText,
          buttonType: step.buttonType,
          buttonColor: step.buttonColor,
          buttonTextColor: step.buttonTextColor,
          actionUrl: step.actionUrl,
        }
      }
    }
    const mediaBase = {
      ...nonEmptyBase,
      url: step.url,
      size: step.size,
      panAndZoom: step.panAndZoom,
      coverAndFit: step.coverAndFit,
      clickContext: step.clickContext,
      pageContext: step.pageContext,
    }
    if (isImageStep(step)) {
      return {
        ...mediaBase,
        type: step.type,
        originalImageUrl: step.url, // No need to expose any other url in public flow data
        hotspots: step.hotspots,
        blurhash: step.blurhash,
        autoplay: step.autoplay,
        assetId: step.assetId,
      }
    }
    return {
      ...mediaBase,
      type: step.type,
      targetId: step.targetId,
      streamUrl: step.streamUrl,
      videoThumbnailUrl: step.videoThumbnailUrl,
      playbackRate: step.playbackRate,
      assetId: step.assetId,
      startTimeFrac: step.startTimeFrac,
      endTimeFrac: step.endTimeFrac,
      duration: step.duration,
      muted: step.muted,
      videoProcessing: step.videoProcessing,
      hotspots: step.hotspots,
      videoPanAndZooms: step.videoPanAndZooms,
    }
  }),
})

export const getSerializablePublicFlow = (
  flow: Flow
): SerializableData<FlowPublicData> =>
  getSerializable(selectPublicFlowData(flow))

export function getFlowFromSerializablePublicFlow(
  publicFlow: SerializableData<FlowPublicData>,
  userPreferredLanguage?: LanguageCode
): Flow {
  const deserializedFlow = getFromSerialized<FlowPublicData>(publicFlow)
  const translatedStringsInFlow = userPreferredLanguage
    ? deserializedFlow.translations?.[userPreferredLanguage]?.strings
    : undefined
  const externalName = getTranslatedFlowNameExternal(
    deserializedFlow.externalName ?? deserializedFlow.name,
    translatedStringsInFlow
  )
  const description = getTranslatedFlowDescription(
    deserializedFlow.description,
    translatedStringsInFlow
  )
  const steps = getTranslatedStepData(
    deserializedFlow.steps,
    translatedStringsInFlow
  )

  return {
    ...deserializedFlow,
    // Don't expose internal name if an external name is set
    name: '',
    externalName,
    description,
    steps,
    schemaVersion: '',
    experiments: [],
    experimentsConfig: {},
    gifUrl: '',
    videoUrl: '',
    tagIds: [],
    folderId: null,
    publishedDate: new Date(),
    lastModifiedBy: '',
    uploadId: '',
    unlocked: null,
    themeId: null,
    themeIdMismatchConfirmed: null,
    useMagicCursor: false,
    hasComments: false,
    createdWith: '',
    // Don't leak the actual timestamp but "anonymize" it to 1970-01-01
    firstViewed: deserializedFlow.firstViewed ? new Date(0) : null,
    update: async (entity: Partial<FlowData>, userId: string | null) => {
      if (!userId) return false
      // ensure firebase is not bundled in the viewer
      const { updateFlow } = await import('src/store/flows')
      return updateFlow(deserializedFlow.id, entity, userId)
    },
  }
}

// TODO: Ideally we should share the `Flow` type between apps
// and not have to do this.
export function getFlowDataDocFromFlow(flow: Flow): FlowDataDoc {
  return {
    ...omit(flow, ['id', 'created', 'modified', 'createdBy', 'lastModifiedBy']),
  }
}

export function getEmptyFlow(data: Partial<Flow> = {}): Flow {
  return {
    id: '',
    name: '',
    externalName: null,
    description: '',
    schemaVersion: '',
    uploadId: '',
    aspectRatio: 1,
    steps: [],
    cta: {},
    createdWith: '',
    editors: [],
    status: PublishStatus.draft,
    submittedForSpotlight: false,
    flowWrapper: FlowWrapper.browserLight,
    customCursor: CustomCursor.default,
    font: '',
    bgImage: null,
    group: '',
    experiments: [],
    experimentsConfig: {},
    showArcadeButton: false,
    openingAnimation: null,
    belongsToTeam: false,
    showFlowNavIndicator: FlowNavSetting.Auto,
    optimizeFlowForMobileView: false,
    showStartOverlay: false,
    startOverlayButtonText: '',
    preventIndexing: false,
    structureHash: '',
    structureBits: undefined,
    autoplay: false,
    demoModeByDefault: false,
    showBackNextButtons: false,
    publishedDate: undefined,
    createdBy: '',
    isTemplate: false,
    lastModifiedBy: '',
    created: new Date(),
    modified: new Date(),
    gifUrl: '',
    videoUrl: '',
    tagIds: [],
    folderId: null,
    themeId: null,
    themeIdMismatchConfirmed: null,
    backgroundMusicUrl: '',
    backgroundMusicVolume: DEFAULT_BG_MUSIC_VOLUME,
    firstViewed: null,
    unlocked: null,
    customVariables: null,
    thumbnailUrl: null,
    translations: null,
    showCaptions: true,
    watermark: null,
    sharePageLogo: null,
    sharePageButton: null,
    prefs: null,
    useMagicCursor: false,
    hasComments: false,
    update: async () => false,
    delete: async () => {},
    ...data,
  }
}

export function stepHasVoiceover(step: Step | null): step is NonEmptyStep {
  return stepHasSyntheticVoiceover(step) || stepHasCameraRecording(step)
}

export function stepHasMedia(step: Step | null) {
  return isVideoStep(step) || stepHasVoiceover(step)
}

export function flowHasAudio(flow: Flow) {
  return (
    !!flow.backgroundMusicUrl ||
    flow.steps.some(step => step.type === StepType.Video && !step.muted) ||
    flowHasVoiceover(flow)
  )
}

export function flowHasVoiceover(flow: Flow) {
  return flow.steps.some(step => stepHasVoiceover(step))
}

export function getFlowNavSetting(
  showFlowNavIndicator: Flow['showFlowNavIndicator'] | undefined
): FlowNavSetting {
  if (showFlowNavIndicator === true) return FlowNavSetting.Auto
  if (showFlowNavIndicator === false) return FlowNavSetting.Off
  if (showFlowNavIndicator == undefined) return FlowNavSetting.Auto
  return showFlowNavIndicator
}

export function getFlowNavSettingText(setting: FlowNavSetting) {
  switch (setting) {
    case FlowNavSetting.Off:
      return 'Never shown regardless of user interaction.'
    case FlowNavSetting.On:
      return 'Always shown regardless of user interaction.'
    case FlowNavSetting.Auto:
    default:
      return 'Shown when users hover over the bottom of the Arcade.'
  }
}

export function getFlowThumbnailUrl(flow: FlowData) {
  return flow.thumbnailUrl ?? getStepPreviewUrl(flow.steps.find(isMediaStep))
}

export function getBaseFlowData(
  flow: Partial<FlowDataDoc> = {},
  account: Account,
  flowDefaults: FlowDefaults
): FlowDataDoc & { steps: Step[] } {
  // ARC-3332 Auto background image experiment
  const bgImage = account.access.isActiveMemberOfTeam
    ? flowDefaults.backgroundImage ?? null
    : bgImages[getRandomInt(bgImages.length)] ?? null

  return {
    name: 'Arcade Flow (' + new Date().toDateString() + ')',
    externalName: null,
    description: '',
    schemaVersion: '1.0',
    aspectRatio: 2 / 3,
    steps: [],
    cta: {},
    editors: [],
    status: PublishStatus.draft,
    belongsToTeam: false,
    optimizeFlowForMobileView: true,
    folderId: null,
    themeId: null,
    showCaptions: true,
    group: account.team.id ?? account.user.emailGroup ?? undefined,
    ...(account.workspace ? { teamId: account.team.id } : {}),
    showFlowNavIndicator: flowDefaults.navBarEnabled,
    flowWrapper: flowDefaults.wrapper ?? FlowWrapper.browserLight,
    customCursor: flowDefaults.cursor ?? CustomCursor.default,
    font: flowDefaults.font ?? 'Inter',
    showStartOverlay: flowDefaults.startOverlayEnabled,
    showBackNextButtons: flowDefaults.backNextButtonsEnabled,
    startOverlayButtonText: flowDefaults.startOverlayText ?? '',
    bgImage,
    ...flow,
  }
}
