import {
  isImageStep,
  isMediaStep,
  isNonEmptyStep,
  isOverlayStep,
  isVideoStep,
} from '@arcadehq/shared/helpers'
import { Hotspot, Step, Voiceover } from '@arcadehq/shared/types'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { SetStateAction, useCallback } from 'react'

import { activeStepAtom } from './activeStep'

export type InspectorPanelState = { isOpen: boolean } & (
  | { type: 'hotspot'; hotspot: Hotspot }
  | { type: 'voiceover'; voiceover: Voiceover }
  | { type: 'pan_and_zoom' }
  | { type: 'cover_and_fit' }
  | { type: 'design' }
  | { type: 'overlay' }
  | { type: 'comments' }
)

export const ELEMENT_DESIGN_PANELS: InspectorPanelState['type'][] = [
  'hotspot',
  'pan_and_zoom',
  'voiceover',
]

export const inspectorPanelAtom = atom<InspectorPanelState>({
  type: 'design',
  isOpen: true,
})

export const inspectorAccordionAtom = atomWithStorage<
  | {
      captions?: boolean
      audio?: boolean
      other?: boolean
      metadata?: boolean
    }
  | undefined
>('inspectorAccordion', undefined)

export const inspectorPanelStepChange = atom(null, (get, set) => {
  const prevInspectorPanel = get(inspectorPanelAtom)
  const currActiveStep = get(activeStepAtom)
  // Maintain the same inspector panel if the currentActiveStep has the same type of feature.
  // This is to limit the inspector panel from being reset when the user switches between steps.
  if (
    prevInspectorPanel.type === 'hotspot' &&
    isMediaStep(currActiveStep) &&
    currActiveStep.hotspots &&
    currActiveStep.hotspots.length > 0
  ) {
    set(inspectorPanelAtom, {
      type: 'hotspot',
      isOpen: prevInspectorPanel.isOpen,
      hotspot: currActiveStep.hotspots[0]!,
    })
    return
  }
  if (
    prevInspectorPanel.type === 'voiceover' &&
    isNonEmptyStep(currActiveStep) &&
    ((currActiveStep.syntheticVoice?.enabled &&
      !!currActiveStep.syntheticVoice.url) ||
      (currActiveStep.cameraRecording?.enabled &&
        !!currActiveStep.cameraRecording.url))
  ) {
    set(inspectorPanelAtom, {
      type: 'voiceover',
      isOpen: prevInspectorPanel.isOpen,
      voiceover: {
        syntheticVoice: currActiveStep.syntheticVoice,
        cameraRecording: currActiveStep.cameraRecording,
      },
    })
    return
  }
  if (
    prevInspectorPanel.type === 'pan_and_zoom' &&
    ((isImageStep(currActiveStep) && currActiveStep.panAndZoom?.enabled) ||
      (isVideoStep(currActiveStep) &&
        currActiveStep.videoPanAndZooms?.some(pz => pz.enabled)))
  ) {
    set(inspectorPanelAtom, {
      type: 'pan_and_zoom',
      isOpen: prevInspectorPanel.isOpen,
    })

    return
  }
  if (prevInspectorPanel.type === 'overlay' && isOverlayStep(currActiveStep)) {
    set(inspectorPanelAtom, {
      type: 'overlay',
      isOpen: prevInspectorPanel.isOpen,
    })
    return
  }
  if (
    prevInspectorPanel.type === 'cover_and_fit' ||
    prevInspectorPanel.type === 'comments'
  )
    return
  set(inspectorPanelAtom, {
    type: 'design',
    isOpen: prevInspectorPanel.isOpen,
  })
})

export const resetInspectorPanel = atom(null, (get, set) => {
  const prevInspectorPanel = get(inspectorPanelAtom)
  set(inspectorPanelAtom, {
    type: 'design',
    isOpen: prevInspectorPanel.isOpen,
  })
})

export const useSetInspectorPanel = () => useSetAtom(inspectorPanelAtom)
export const useResetInspectorPanel = () => useSetAtom(resetInspectorPanel)

export function useInspectorPanelUpdaters() {
  const setInspectorPanel = useSetInspectorPanel()

  const updateInspectorHotspot = useCallback(
    (setter: SetStateAction<Hotspot>) => {
      setInspectorPanel(prev => {
        if (prev.type !== 'hotspot') return prev
        const hotspot =
          typeof setter === 'function' ? setter(prev.hotspot) : setter
        if (hotspot.id !== prev.hotspot.id) return prev
        return { ...prev, hotspot }
      })
    },
    [setInspectorPanel]
  )

  const updateInspectorVoiceover = useCallback(
    (setter: SetStateAction<Voiceover>) => {
      setInspectorPanel(prev => {
        if (prev.type !== 'voiceover') return prev
        const voiceover =
          typeof setter === 'function' ? setter(prev.voiceover) : setter
        return { ...prev, voiceover }
      })
    },
    [setInspectorPanel]
  )

  return { updateInspectorHotspot, updateInspectorVoiceover }
}

export function useInspectorPanel() {
  const inspectorPanel = useAtomValue(inspectorPanelAtom)
  return inspectorPanel
}

export const selectIsFeatureToCopySelected = (
  inspectorPanel: InspectorPanelState,
  stepsToCopyCount: number,
  activeStep: Step | null | undefined
) =>
  ['hotspot', 'voiceover', 'pan_and_zoom'].includes(inspectorPanel.type) &&
  inspectorPanel.isOpen &&
  stepsToCopyCount === 1 &&
  !(inspectorPanel.type === 'pan_and_zoom' && isVideoStep(activeStep))
