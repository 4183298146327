"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultTeamPrefs = exports.defaultUserPrefs = void 0;
const types_1 = require("../types");
exports.defaultUserPrefs = {
    fontFamily: 'Inter',
    bgColor: '#2142E7', // colors['default-hotspot']
    textColor: '#FFFFFF', // colors.white
};
exports.defaultTeamPrefs = {
    fontFamily: 'Inter',
    bgColor: '#2142E7', // colors.blue[600]
    fgColor: '#FFFFFF', // colors.white
    bgImage: undefined,
    wrapper: types_1.FlowWrapper.browserLight,
    cursor: types_1.CustomCursor.default,
    showStartOverlay: false,
    startOverlayButtonText: undefined,
    showFlowNavIndicator: types_1.FlowNavSetting.Auto,
    showBackNextButtons: false,
};
