import {
  getFirstNonEmptyStep,
  getNextNonEmptyAutoStep,
  isVideoStep,
  selectFlowAutoplay,
  stepSupportsAutoplay,
} from '@arcadehq/shared/helpers'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { stepHasMedia } from 'src/utils/flows'

import { flowAtom } from '../flow'
import { FlowMode, flowModeAtom } from '../flowMode'
import { activeStepAtom, activeStepIdAtom } from './activeStep'

export enum AutoplayStatus {
  On = 'ON',
  Paused = 'PAUSED',
  Off = 'OFF',
}

export type viewerPlaybackContextAction =
  | { type: 'SET_TEXT_ENDED'; ended: boolean }
  | { type: 'SET_MEDIA_ENDED'; ended: boolean }
  | { type: 'SET_NAVIGATE_TO_ON_ENDED'; id: string | null }
  | { type: 'SET_VISIBLE'; visible: boolean }
  | { type: 'TURN_OFF_AUTOPLAY' }

const textEndedAtom = atom(false)
const mediaEndedAtom = atom(false)
const flowAutoplayAtom = atom(false)
const autoplayStatusAtom = atom<AutoplayStatus>(AutoplayStatus.Off)
const autoplayStatusSetterAtom = atom(null, (get, set, visible: boolean) => {
  if (get(autoplayStatusAtom) === AutoplayStatus.Off) return
  set(autoplayStatusAtom, visible ? AutoplayStatus.On : AutoplayStatus.Paused)
})
const navigateToOnEndedAtom = atom<string | null>(null)

export const onStepChangeAtom = atom(
  null,
  (get, set, newStepId: string | null) => {
    if (get(flowModeAtom) !== FlowMode.View) return

    set(textEndedAtom, false)
    set(mediaEndedAtom, false)
    set(navigateToOnEndedAtom, null)

    const flow = get(flowAtom)
    const autoplayStatus = get(autoplayStatusAtom)
    const flowAutoplay = get(flowAutoplayAtom)

    const nextStep = flow.steps.find(s => s.id === newStepId)

    if (stepSupportsAutoplay(nextStep) && nextStep.autoplay?.enabled) {
      if (autoplayStatus !== AutoplayStatus.Paused) {
        set(autoplayStatusAtom, AutoplayStatus.On)
      }
    } else if (!flowAutoplay) {
      set(autoplayStatusAtom, AutoplayStatus.Off)
    }
  }
)

const viewerPlaybackDispatchAtom = atom(
  null,
  (get, set, action: viewerPlaybackContextAction) => {
    if (get(flowModeAtom) !== FlowMode.View) return
    switch (action.type) {
      case 'SET_TEXT_ENDED': {
        set(textEndedAtom, action.ended)
        break
      }
      case 'SET_MEDIA_ENDED': {
        set(mediaEndedAtom, action.ended)
        break
      }
      case 'SET_VISIBLE': {
        set(autoplayStatusSetterAtom, action.visible)
        break
      }
      case 'SET_NAVIGATE_TO_ON_ENDED': {
        set(navigateToOnEndedAtom, action.id)
        break
      }
      case 'TURN_OFF_AUTOPLAY': {
        set(autoplayStatusAtom, AutoplayStatus.Off)
        break
      }
    }

    const flow = get(flowAtom)
    const activeStep = get(activeStepAtom)
    if (!activeStep) return

    const hasMedia = stepHasMedia(activeStep)

    const textEnded = get(textEndedAtom)
    const mediaEnded = get(mediaEndedAtom)
    const navigateToOnEnded = get(navigateToOnEndedAtom)
    const autoplayStatus = get(autoplayStatusAtom)

    const shouldProgressPendingNavigation =
      !!navigateToOnEnded && (!hasMedia || mediaEnded)
    const shouldProgressVideoStep = isVideoStep(activeStep) && mediaEnded
    const shouldProgressAutoplay =
      autoplayStatus === AutoplayStatus.On &&
      textEnded &&
      (!hasMedia || mediaEnded)
    const shouldProgress =
      shouldProgressPendingNavigation ||
      shouldProgressVideoStep ||
      shouldProgressAutoplay

    if (!shouldProgress) return

    const nextStepId =
      navigateToOnEnded ??
      getNextNonEmptyAutoStep(flow.steps, activeStep, {
        allowRestart: false,
      })?.id

    if (!nextStepId) return

    // `onStepChangeAtom` is triggered by `activeStepIdAtom` setter
    set(activeStepIdAtom, nextStepId)
  }
)

export const resetViewerPlaybackAtom = atom(null, (get, set) => {
  const flow = get(flowAtom)
  const mode = get(flowModeAtom)

  if (mode === FlowMode.View) {
    const firstStep = getFirstNonEmptyStep(flow.steps)
    const autoplayIsEnabled = selectFlowAutoplay(flow).enabled

    set(flowAutoplayAtom, autoplayIsEnabled)
    set(
      autoplayStatusAtom,
      autoplayIsEnabled ||
        (stepSupportsAutoplay(firstStep) && firstStep?.autoplay?.enabled)
        ? AutoplayStatus.On
        : AutoplayStatus.Off
    )
    set(textEndedAtom, false)
    set(mediaEndedAtom, false)
    set(navigateToOnEndedAtom, null)
  } else {
    set(flowAutoplayAtom, false)
    set(autoplayStatusAtom, AutoplayStatus.Off)
  }
})

// these properties are always used together
export const useViewerPlaybackValues = () => ({
  textEnded: useAtomValue(textEndedAtom),
  mediaEnded: useAtomValue(mediaEndedAtom),
  navigateToOnEnded: useAtomValue(navigateToOnEndedAtom),
  autoplayStatus: useAtomValue(autoplayStatusAtom),
})

export const useViewerPlaybackDispatch = () =>
  useSetAtom(viewerPlaybackDispatchAtom)
