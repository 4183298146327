import {
  MemberType,
  WorkspaceMemberRole,
  WorkspacePlan,
} from '@arcadehq/shared/types'

import { AccountCore } from '../AccountCore'

/**
 * Access rules allow to define who can read and write to a page.
 * The rules are defined by the workspace and team roles.
 * This is an OR condition, meaning that if the user has the role in the workspace or the team, they can access the page.
 * Access rules are defined in the page component and passed to `withAuth`
 *
 *  The read capabilities should be handled by the page itself.
 *  `isReadonly:boolean` is automatically passed to the page component props.
 *
 * Examples:
 *
 * ```
 * export const workspaceSettingsAccessRules: AccessRules = {
 *  write: {
 *   workspace: ['Owner', 'Admin'],
 *  }
 * }
 *
 * withAuth({ accessRules: workspaceSettingsAccessRules })(...)
 * ```
 *
 * This means only the Owner and Admin of the workspace can access the page.
 *
 * ```
 * export const workspaceMembersAccessRules: AccessRules = {
 *  read: {
 *    workspace: ['Member', 'Billing User'],
 *    team: ['Admin', 'Member'],
 *  },
 *  write: {
 *   workspace: ['Owner', 'Admin'],
 *  }
 * }
 *
 * withAuth({ accessRules: workspaceMembersAccessRules })(...)
 * ```
 *
 * This means only the Owner and Admin of the workspace can edit the page, but everyone else can read it.
 *
 * ```
 * export const workspaceMembersAccessRules: AccessRules = {
 *  },
 *  write: {
 *   workspace: ['Owner', 'Admin'],
 *   team: ['Admin'],
 *   plan: ['Enterprise'],
 *  }
 * }
 *
 * withAuth({ accessRules: workspaceMembersAccessRules })(...)
 * ```
 *
 * This means only the Owner and Admin of the workspace and Admin of the team and the workspace has an
 * enterprise subscription can edit the page, but only if the workspace plan is Enterprise.
 */
export type AccessRules = {
  read?: AccessRule
  write: AccessRule
}

export type AccessRule = {
  plan?: WorkspacePlan[]
  workspace?: WorkspaceMemberRole[]
  team?: MemberType[]
}

export const access = (core: AccountCore) => ({
  get isTeamAdmin(): boolean {
    if (core.workspace) {
      return !!core.team.id && this.isAdminOfTeam(core.team.id)
    }
    // TODO:workspaces remove below
    return !!core.user.isTeamAdmin
  },

  get isActiveMemberOfTeam(): boolean {
    if (core.workspace) {
      // TODO:workspaces check for status/role as well because we have invitations here
      return core.user.teams.some(({ teamId }) => teamId === core.team.id)
    }

    // TODO:workspaces remove below
    return !!core.user.isActiveMemberOfTeam
  },

  isPartOfTeam(teamId: string): boolean {
    if (core.workspace) {
      return this.getTeamMemberType(teamId) !== undefined
    }
    // TODO:workspaces remove below
    throw new Error('Not implemented for old team structure')
  },

  getTeamMemberType(teamId: string): MemberType | undefined {
    if (core.workspace) {
      return (
        core.user.teams.find(t => t.teamId === teamId && t.status === 'Active')
          ?.type || undefined
      )
    }
    // TODO:workspaces remove below
    throw new Error('Not implemented for old team structure')
  },

  isAdminOfTeam(teamId: string): boolean {
    if (core.workspace) {
      return this.getTeamMemberType(teamId) === 'Admin'
    }
    // TODO:workspaces remove below
    throw new Error('Not implemented for old team structure')
  },

  getWorkspaceMemberRole(workspaceId: string): WorkspaceMemberRole | undefined {
    if (core.workspace) {
      return (
        core.user.workspaces.find(
          w => w.workspaceId === workspaceId && w.status === 'Active'
        )?.role || undefined
      )
    }
    // TODO:workspaces remove below
    throw new Error('Not implemented for old team structure')
  },

  get isWorkspaceOwner(): boolean {
    if (core.workspace) {
      return this.getWorkspaceMemberRole(core.workspace.id) === 'Owner'
    }
    // TODO:workspaces remove below
    return false
  },

  get isWorkspaceAdmin(): boolean {
    if (core.workspace) {
      return this.getWorkspaceMemberRole(core.workspace.id) === 'Admin'
    }
    // TODO:workspaces remove below
    return false
  },

  getAccessType(
    accessRules: AccessRules,
    teamId: string = core.team.id!
  ): 'write' | 'read' | 'none' {
    if (!core.workspace) {
      // TODO:workspaces remove this check
      return 'write'
    }

    const teamMemberType = this.getTeamMemberType(teamId)
    const workspaceMemberRole = this.getWorkspaceMemberRole(core.workspace.id)

    if (!workspaceMemberRole) {
      return 'none'
    }

    if (
      ((accessRules.write.workspace &&
        accessRules.write.workspace.includes(workspaceMemberRole)) ||
        (accessRules.write.team &&
          teamMemberType &&
          accessRules.write.team.includes(teamMemberType))) &&
      (!accessRules.write.plan ||
        (core.workspacePlan &&
          accessRules.write.plan?.includes(core.workspacePlan)))
    ) {
      return 'write'
    }

    if (
      ((accessRules.read &&
        accessRules.read.workspace &&
        accessRules.read.workspace.includes(workspaceMemberRole)) ||
        (accessRules.read &&
          accessRules.read.team &&
          teamMemberType &&
          accessRules.read.team.includes(teamMemberType))) &&
      (!accessRules.read.plan ||
        (core.workspacePlan &&
          accessRules.read.plan?.includes(core.workspacePlan)))
    ) {
      return 'read'
    }

    return 'none'
  },

  canAccess(accessRules: AccessRules, teamId: string = core.team.id!) {
    return this.getAccessType(accessRules, teamId) !== 'none'
  },
})
