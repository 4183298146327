import Link from 'next/link'
import { useState } from 'react'
import toast from 'react-hot-toast'
import useAccount from 'src/auth/useAccount'

export default function DevWorkspaceInfos() {
  const account = useAccount()
  const [active, setActive] = useState(false)

  return (
    <div className='absolute bottom-0 right-0'>
      {active ? (
        <div className='bg-black border border-gray-200 p-4 rounded-lg shadow-lg'>
          <div className='flex justify-between'>
            <div>
              <p className='text-sm font-semibold text-white'>Account</p>
            </div>
            <button
              onClick={() => setActive(false)}
              className='text-xs text-gray-200'
            >
              ✖
            </button>
          </div>
          <div className='mt-2'>
            <p
              className='text-xs text-white cursor-context-menu'
              onClick={() => copyToClipboard(account.user.id)}
            >
              <span className='font-semibold'>User ID:</span> {account.user.id}
            </p>
            <p
              className='text-xs text-white cursor-context-menu'
              onClick={() => copyToClipboard(account.team.id)}
            >
              <span className='font-semibold'>Team ID:</span> {account.team.id}
            </p>
            <p
              className='text-xs text-white cursor-context-menu'
              onClick={() => copyToClipboard(account.workspace?.id)}
            >
              <span className='font-semibold'>Workspace ID:</span>{' '}
              {account.workspace?.id}
            </p>
            <Link
              href={`/admin/workspaces/${account.workspace?.id}`}
              target='_blank'
              passHref
            >
              <span className='text-xs text-white underline'>More infos</span>
            </Link>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setActive(true)}
          className='text-lg text-white rounded-full bg-black opacity-15 translate-x-3 translate-y-3 hover:opacity-100 hover:translate-x-0 hover:translate-y-0 w-8 h-8'
        >
          ℹ
        </button>
      )}
    </div>
  )
}

async function copyToClipboard(value: any) {
  let text = value
  if (typeof value === 'object') {
    text = JSON.stringify(value, null, 2)
  } else if (value instanceof Date) {
    text = value.toLocaleString()
  } else if (typeof value === 'boolean') {
    text = value ? 'true' : 'false'
  }

  if (!text) return toast.error('Nothing to copy')

  await navigator.clipboard.writeText(text)
  toast.success(`Copied ${text.slice(0, 20)} to clipboard`)
}
