import { convertDate, extractGroupName } from '@arcadehq/shared/helpers'
import {
  Feature,
  FeaturesDiscovered,
  StoredFeatures,
  UserPrefs,
  UserProfileDataDoc,
  UserTeamMembership,
  UserWorkspaceMembership,
} from '@arcadehq/shared/types'
import type { User } from 'next-firebase-auth'
import { UserProfile } from 'src/types'

import { AccountCore } from '../AccountCore'

/**
 * user is a collection of getters that are specific to a user.
 * This file should only include low level getter and generic helpers.
 * It acts as a proxy between other features in Account and the database object.
 */

export const user = (
  _core: AccountCore,
  authUser: User,
  userProfile: UserProfile
) => ({
  update(
    updates: Partial<UserProfileDataDoc>,
    modifiedBy?: string
  ): Promise<boolean> {
    return userProfile.update(updates, modifiedBy || this.id)
  },

  get id(): string {
    return authUser.id ?? userProfile.id ?? ''
  },

  get email(): string | null {
    return authUser.email
  },

  get emailGroup(): string | null {
    return extractGroupName({ id: this.id, email: this.email })
  },

  get profileGroup(): string | null {
    return userProfile.group ?? null
  },

  get emailVerified(): boolean {
    return authUser.emailVerified
  },

  get name(): string | null {
    return authUser.displayName
  },

  get firstName(): string | null {
    return authUser.displayName?.split(' ')[0] || null
  },

  get photoUrl(): string | null {
    return userProfile.photoURL ?? null
  },

  get colors(): string[] {
    return userProfile.colors || []
  },

  get prefs(): UserPrefs | null {
    return userProfile.prefs || null
  },

  updatePrefs(prefs: Partial<UserPrefs>): Promise<boolean> {
    return userProfile.update(
      {
        prefs: {
          ...this.prefs,
          ...prefs,
        },
      },
      this.id
    )
  },

  get featuresDiscovered(): FeaturesDiscovered {
    return userProfile.featuresDiscovered || {}
  },

  get firebaseUser(): User['firebaseUser'] {
    return authUser.firebaseUser
  },

  getIdToken(forceRefresh: boolean = false): Promise<string | null> {
    return authUser.getIdToken(forceRefresh)
  },

  get isClientInitialized(): boolean {
    return authUser.clientInitialized
  },

  get creationDate(): Date {
    return convertDate(userProfile.created)
  },

  // Used to determine if the user just signed up (for analytics)
  get wasCreatedWithinLastMinute(): boolean {
    if (userProfile) {
      return Date.now() - (this.creationDate?.getTime() ?? 0) <= 60000
    }
    return true
  },

  get features(): StoredFeatures {
    return userProfile.features || {}
  },

  getFeature<F extends Feature>(feat: F): StoredFeatures[F] | null {
    return this.features[feat] ?? null
  },
  setFeature<F extends Feature>(
    feature: F,
    value: StoredFeatures[F]
  ): Promise<boolean> {
    const newFeatures = { ...this.features, [feature]: value }
    return userProfile.update({ features: newFeatures }, this.id)
  },

  logOut(): Promise<void> {
    return authUser.signOut()
  },

  get teams(): UserTeamMembership[] {
    return userProfile.teams || []
  },

  isMemberOfTeam(teamId: string): boolean {
    return (
      this.teams.some(team => team.teamId === teamId) ||
      this.isActiveMemberOfTeam // TODO:workspaces remove this after migration
    )
  },

  isAdminOfTeam(teamId: string): boolean {
    return (
      this.teams.some(
        team => team.teamId === teamId && team.type === 'Admin'
      ) || this.isTeamAdmin
    ) // TODO:workspaces remove this after migration
  },

  get workspaces(): UserWorkspaceMembership[] {
    return userProfile.workspaces || []
  },

  isMemberOfWorkspace(workspaceId: string): boolean {
    return this.workspaces.some(
      workspace => workspace.workspaceId === workspaceId
    )
  },

  isAdminOfWorkspace(workspaceId: string): boolean {
    return this.workspaces.some(
      workspace =>
        workspace.workspaceId === workspaceId && workspace.role === 'Admin'
    )
  },

  /*
   * TODO:workspaces Delete everything below after migration
   */

  get currentSubscriber(): boolean {
    return !!userProfile.currentSubscriber
  },

  get customerId(): string | undefined {
    return userProfile.customerId
  },

  get isOverdue(): boolean {
    return !!userProfile.isOverdue && !userProfile.ignoreOverdue
  },

  get activeTeamId(): string | null {
    return userProfile.activeTeamId || null
  },

  get isActiveMemberOfTeam(): boolean {
    return !!userProfile.isActiveMemberOfTeam
  },

  get isTeamAdmin(): boolean {
    return !!userProfile.isTeamAdmin
  },

  get isExternalMemberOfTeam(): boolean {
    return !!userProfile.isExternalMemberOfTeam
  },

  // Used to prevent connecting to Liveblocks unnecessarily
  // A user becomes a "comment user" when they:
  // 1. Access the comments panel or
  // 2. Have been mentioned in a comment before
  get isCommentsUser(): boolean {
    return !!userProfile.isCommentsUser
  },

  get isArcadeEmployee(): boolean {
    return (
      !!authUser.email?.endsWith('@arcade.software') && authUser.emailVerified
    )
  },
})
