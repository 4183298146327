import { getFirstNonEmptyStep } from '@arcadehq/shared/helpers'
import { Step } from '@arcadehq/shared/types'
import { atom, useAtomValue, useSetAtom } from 'jotai'

import { flowAtom } from '../flow'
import { FlowMode, flowModeAtom } from '../flowMode'
import { inspectorPanelStepChange } from './inspectorPanel'
import { onStepChangeAtom } from './viewerPlayback'

export const activeStepIdAtom = atom<string | null, [string | null], void>(
  null,
  (get, set, stepId: string | null) => {
    const currentActiveStepId = get(activeStepIdAtom)
    set(activeStepIdAtom, stepId)

    if (stepId !== currentActiveStepId) {
      const mode = get(flowModeAtom)

      if (mode === FlowMode.View) {
        set(onStepChangeAtom, stepId)
      } else if (mode === FlowMode.Edit) {
        set(inspectorPanelStepChange)
      }
    }
  }
)

export const activeStepAtom = atom(get => {
  const flow = get(flowAtom)
  const activeStepId = get(activeStepIdAtom)
  return flow.steps.find(step => step.id === activeStepId) ?? null
})

const activeStepIndexAtom = atom(get => {
  const flow = get(flowAtom)
  const activeStepId = get(activeStepIdAtom)
  return flow.steps.findIndex(step => step.id === activeStepId)
})

export const resetActiveStepIdAtom = atom(null, (get, set) => {
  const flow = get(flowAtom)
  const mode = get(flowModeAtom)
  set(activeStepIdAtom, getInitialActiveStepId(flow.steps, mode))
})

const goToPreviousStepAtom = atom(null, (get, set, steps?: Step[]) => {
  const currentSteps = steps ?? get(flowAtom).steps // This is only necessary if we want to select a step that will be created in the near future
  const activeStepId = get(activeStepIdAtom)
  const currentIdx = currentSteps.findIndex(step => step.id === activeStepId)
  const previousStep = currentSteps[currentIdx - 1]
  if (previousStep) {
    set(activeStepIdAtom, previousStep.id)
  }
})

const goToNextStepAtom = atom(null, (get, set, steps?: Step[]) => {
  const currentSteps = steps ?? get(flowAtom).steps // This is only necessary if we want to select a step that will be created in the near future
  const activeStepId = get(activeStepIdAtom)
  const currentIdx = currentSteps.findIndex(step => step.id === activeStepId)
  const previousStep = currentSteps[currentIdx + 1]
  if (previousStep) {
    set(activeStepIdAtom, previousStep.id)
  }
})

export const useActiveStepId = () => useAtomValue(activeStepIdAtom)
export const useActiveStep = () => useAtomValue(activeStepAtom)
export const useActiveStepIndex = () => useAtomValue(activeStepIndexAtom)

export const useSetActiveStepId = () => useSetAtom(activeStepIdAtom)
export const useGoToPreviousStep = () => useSetAtom(goToPreviousStepAtom)
export const useGoToNextStep = () => useSetAtom(goToNextStepAtom)

function getInitialActiveStepId(steps: Step[], mode: FlowMode) {
  if (mode == FlowMode.Edit) return steps[0]?.id ?? null
  return getFirstNonEmptyStep(steps)?.id ?? null
}
