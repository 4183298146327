import { DefaultShareType, Feature } from '@arcadehq/shared/types'

import { LIVEBLOCKS_DISABLED } from '../../../constants'
import { AccountCore } from '../AccountCore'

export type Privacy = ReturnType<typeof privacy>

export const privacy = (core: AccountCore) => ({
  get preventIndexing(): boolean {
    if (core.workspace) {
      return !!core.team.getFeature(Feature.PreventIndexing)
    }
    // TODO:workspaces remove below
    return !!core.getFeature(Feature.PreventIndexing)
  },

  get disableIpTracking(): boolean {
    if (core.workspace) {
      return !!core.team.getFeature(Feature.DisableIPTracking)
    }
    // TODO:workspaces remove below
    return !!core.getFeature(Feature.DisableIPTracking)
  },

  get disableEventTracking(): boolean {
    if (core.workspace) {
      return !!core.team.getFeature(Feature.DoNotTrack)
    }
    // TODO:workspaces remove below
    return !!core.getFeature(Feature.DoNotTrack)
  },

  get defaultShareType(): DefaultShareType {
    if (core.workspace) {
      return (
        core.team.getFeature(Feature.DefaultShareType) ??
        DefaultShareType.public
      )
    }
    // TODO:workspaces remove below
    return core.getFeature(Feature.DefaultShareType) ?? DefaultShareType.public
  },

  get disableInternalTracking(): boolean {
    if (core.workspace) {
      return !!core.team.getFeature(Feature.DisableInternal3rdPartyTrackers)
    }
    // TODO:workspaces remove below
    return !!core.getFeature(Feature.DisableInternal3rdPartyTrackers)
  },

  get editAccessToArcadesIsControlled(): boolean {
    return !!core.team.getFeature(Feature.EditAccessToArcadesIsControlled)
  },

  setPreventIndexing(preventIndexing: boolean): Promise<boolean> {
    if (core.workspace) {
      return core.team.setFeature(Feature.PreventIndexing, preventIndexing)
    }
    // TODO:workspaces remove below
    if (core.team.id) {
      return core.team.setFeature(Feature.PreventIndexing, preventIndexing)
    } else {
      return core.user.setFeature(Feature.PreventIndexing, preventIndexing)
    }
  },

  setDisableIpTracking(disableIpTracking: boolean): Promise<boolean> {
    if (core.workspace) {
      return core.team.setFeature(Feature.DisableIPTracking, disableIpTracking)
    }
    // TODO:workspaces remove below
    if (core.team.id) {
      return core.team.setFeature(Feature.DisableIPTracking, disableIpTracking)
    } else {
      return core.user.setFeature(Feature.DisableIPTracking, disableIpTracking)
    }
  },

  setDisableEventTracking(disableEventTracking: boolean): Promise<boolean> {
    if (core.workspace) {
      return core.team.setFeature(Feature.DoNotTrack, disableEventTracking)
    }
    // TODO:workspaces remove below
    if (core.team.id) {
      return core.team.setFeature(Feature.DoNotTrack, disableEventTracking)
    } else {
      return core.user.setFeature(Feature.DoNotTrack, disableEventTracking)
    }
  },

  setDefaultShareType(defaultShareType: DefaultShareType): Promise<boolean> {
    if (core.workspace) {
      return core.team.setFeature(Feature.DefaultShareType, defaultShareType)
    }
    // TODO:workspaces remove below
    if (core.team.id) {
      return core.team.setFeature(Feature.DefaultShareType, defaultShareType)
    } else {
      return core.user.setFeature(Feature.DefaultShareType, defaultShareType)
    }
  },

  setEditAccessToArcadesIsControlled(
    editAccessToArcadesIsControlled: boolean
  ): Promise<boolean> {
    return core.team.setFeature(
      Feature.EditAccessToArcadesIsControlled,
      editAccessToArcadesIsControlled
    )
  },

  setAccessToCommenting(accessToCommenting: boolean): Promise<boolean> {
    return core.team.setFeature(Feature.Commenting, accessToCommenting)
  },

  get mayUseCommenting(): boolean {
    return (
      !LIVEBLOCKS_DISABLED &&
      core.access.isActiveMemberOfTeam &&
      core.subscription.isGrowthAndAbove &&
      (core.team.getFeature(Feature.Commenting) ??
        // By returning true in the next line, the Commenting feature will be enabled
        // for all Growth+ subscribers.
        true)
    )
  },

  get maySeeCommentingButton(): boolean {
    return !core.subscription.isGrowthAndAbove || this.mayUseCommenting
  },

  get mayChangeFont(): boolean {
    return !this.disableIpTracking && !this.disableEventTracking
  },

  get mayEditAccessControl(): boolean {
    return (
      core.access.isTeamAdmin &&
      (core.subscription.isGrowth || core.subscription.isEnterprise)
    )
  },

  get mayEditPrivacySettings(): boolean {
    return (
      core.access.isTeamAdmin &&
      (core.subscription.isGrowth || core.subscription.isEnterprise)
    )
  },
})
