"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldApplyGrowthWithMinimum = exports.getStripeIds = void 0;
const constants_1 = require("../constants");
const getStripeIds = (secretKey, includePromoPrice = false, applyGrowthLimits) => {
    let result = secretKey.startsWith('sk_live')
        ? constants_1.STRIPE_IDS.live
        : constants_1.STRIPE_IDS.test;
    if (includePromoPrice) {
        result = secretKey.startsWith('sk_live')
            ? {
                ...result,
                pro: {
                    id: constants_1.STRIPE_IDS.live.pro.id,
                    monthly: 'price_1Mgcm0CDgCbpE4wjNUbHwQgg',
                    yearly: 'price_1MgcmQCDgCbpE4wjOaA09tni',
                    billingPortalConfigId: 'bpc_1MgiBRCDgCbpE4wjy2JfTjQZ',
                },
            }
            : {
                ...result,
                pro: {
                    id: constants_1.STRIPE_IDS.test.pro.id,
                    monthly: 'price_1MgcqqCDgCbpE4wjAGOYLAzz',
                    yearly: 'price_1Mgcr2CDgCbpE4wjRIlTI2ov',
                    billingPortalConfigId: 'bpc_1MgfSJCDgCbpE4wjnXaGx53k',
                },
            };
    }
    const growth = secretKey.startsWith('sk_live')
        ? constants_1.STRIPE_IDS.live[applyGrowthLimits ? 'growthMin5' : 'growth']
        : constants_1.STRIPE_IDS.test[applyGrowthLimits ? 'growthMin5' : 'growth'];
    return { ...result, growth };
};
exports.getStripeIds = getStripeIds;
const shouldApplyGrowthWithMinimum = (subscribedAt // Stripe gives us the timestamp in seconds
) => subscribedAt > constants_1.MIN_5_GROWTH_MEMBERS_SINCE &&
    // Disabled experiment. Context here https://getarcade.slack.com/archives/C06AWPHCA66/p1703029127505149
    subscribedAt < constants_1.MIN_5_GROWTH_MEMBERS_UNTIL;
exports.shouldApplyGrowthWithMinimum = shouldApplyGrowthWithMinimum;
