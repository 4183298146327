"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertDate = convertDate;
function convertDate(firebaseDate) {
    if (firebaseDate instanceof Date) {
        return firebaseDate;
    }
    if (typeof firebaseDate === 'string') {
        return new Date(firebaseDate);
    }
    // Handles server version of firebase.firestore.Timestamp
    if (typeof firebaseDate === 'object' &&
        '_seconds' in firebaseDate &&
        typeof firebaseDate._seconds === 'number') {
        return new Date(firebaseDate._seconds * 1000);
    }
    // Handles client version of firebase.firestore.Timestamp
    if (typeof firebaseDate === 'object' && 'toDate' in firebaseDate) {
        return firebaseDate.toDate();
    }
    return undefined;
}
