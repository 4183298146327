/* eslint-disable @typescript-eslint/no-var-requires */
const plugin = require('tailwindcss/plugin')
const colors = require('@arcadehq/configs/tailwind/colors')

module.exports = {
  mode: 'jit',
  content: [
    './src/**/*.{js,ts,jsx,tsx}',
    './pages/**/*.{js,ts,jsx,tsx}',
    '../../packages/shared/src/**/*.{ts,tsx}',
  ],
  darkMode: 'class',
  theme: {
    animatedSettings: {
      classes: ['animated', 'heartBeat', 'wobble', 'fast'],
    },
    extend: {
      colors,
      boxShadow: {
        btn: '0px 3px 3px rgba(17, 24, 39, 0.04), 0px 2px 2px -2px rgba(17, 24, 39, 0.06)',
        reverse:
          '0px -3px 6px rgba(17, 24, 39, 0.02), 0px -2px 8px rgba(17, 24, 39, 0.02)',
        card: 'inset 0px 0px 0px 1px rgba(17, 24, 39, 0.1)',
        'card-hover':
          '0px 0px 0px 1px #2142E7, 0px 0px 0px 4px rgba(33, 66, 231, 0.12), inset 0px 0px 0px 1px #2142E7',
        'card-hover-inset': 'inset 0px 0px 0px 1px #2142E7',
        'checkbox-hover': 'inset 0px 0px 0px 4px rgba(33, 66, 231, 0.12)',
        menu: '0px 0px 0px 1px rgba(17, 24, 39, 0.12), 0px 2px 2px -2px rgba(17, 24, 39, 0.03), 0px 4px 4px rgba(17, 24, 39, 0.03), 0px 8px 8px rgba(17, 24, 39, 0.03), 0px 16px 16px rgba(17, 24, 39, 0.03)',
        menuPlus:
          '0px 0px 0px 1px rgba(17, 24, 39, 0.10), 0px 2px 2px -2px rgba(17, 24, 39, 0.10), 0px 4px 4px rgba(17, 24, 39, 0.10), 0px 8px 8px rgba(17, 24, 39, 0.10), 0px 16px 16px rgba(17, 24, 39, 0.10), 0px 32px 32px rgba(17, 24, 39, 0.10)',
        input:
          '0px 32px 32px 0px rgba(17, 24, 39, 0.04), 0px 16px 16px 0px rgba(17, 24, 39, 0.04), 0px 8px 8px 0px rgba(17, 24, 39, 0.04), 0px 4px 4px 0px rgba(17, 24, 39, 0.04), 0px 2px 2px -2px rgba(17, 24, 39, 0.04), 0px 0px 0px 1px rgba(17, 24, 39, 0.10)',
        inputHover:
          '0px 32px 32px 0px rgba(17, 24, 39, 0.08), 0px 16px 16px 0px rgba(17, 24, 39, 0.08), 0px 8px 8px 0px rgba(17, 24, 39, 0.08), 0px 4px 4px 0px rgba(17, 24, 39, 0.08), 0px 2px 2px -2px rgba(17, 24, 39, 0.08), 0px 0px 0px 1px rgba(17, 24, 39, 0.20)',
        menuNoRing:
          '0px 2px 2px -2px rgba(17, 24, 39, 0.03), 0px 4px 4px rgba(17, 24, 39, 0.03), 0px 8px 8px rgba(17, 24, 39, 0.03), 0px 16px 16px rgba(17, 24, 39, 0.03)',
        hotBox:
          '0px 2px 2px -2px rgba(17, 24, 39, 0.06), 0px 4px 4px rgba(17, 24, 39, 0.06), 0px 8px 8px rgba(17, 24, 39, 0.06), 0px 16px 16px rgba(17, 24, 39, 0.06), 0px 32px 32px rgba(17, 24, 39, 0.06), inset 0px 0px 0px 1px rgba(17, 24, 39, 0.1)',
        palette:
          '0px 0px 0px 1px rgba(17, 24, 39, 0.12), 0px 2px 2px -2px rgba(17, 24, 39, 0.03), 0px 4px 4px rgba(17, 24, 39, 0.03), 0px 8px 8px rgba(17, 24, 39, 0.03), 0px 16px 80px rgba(17, 24, 39, 0.48)',
        camera:
          '0px 4px 64px 8px rgba(17, 24, 39, 0.06), 0px 32px 32px 0px rgba(17, 24, 39, 0.06), 0px 16px 16px 0px rgba(17, 24, 39, 0.06), 0px 8px 8px 0px rgba(17, 24, 39, 0.06), 0px 4px 4px 0px rgba(17, 24, 39, 0.06), 0px 2px 2px -2px rgba(17, 24, 39, 0.06), 0px 0px 0px 1px rgba(17, 24, 39, 0.2)',
        arcade:
          '0px 2px 2px -2px rgba(17, 24, 39, 0.06), 0px 4px 4px rgba(17, 24, 39, 0.06), 0px 8px 8px rgba(17, 24, 39, 0.06), 0px 16px 80px rgba(17, 24, 39, 0.48)',
        share:
          '0px 150px 374px 0px rgba(17, 24, 39,0.02), 0px 210px 124px 0px rgba(17, 24, 39,0.06), 0px 93px 93px 0px rgba(17, 24, 39,0.07), 0px 23px 51px 0px rgba(17, 24, 39,0.08)',
        shareMobile:
          '0px 100px 50px 0px rgba(17, 24, 39,0.04), 0px 30px 30px 0px rgba(17, 24, 39,0.07), 0px 11px 25px 0px rgba(17, 24, 39,0.08)',
        radio: '0px 0px 0px 4px white inset, 0px 0px 0px 12px #2142E7 inset',
        optionSelected:
          '0px 0px 0px 1px #2142E7, 0px 0px 0px 4px rgba(33, 66, 231, 0.12)',
        optionSelectedRing: '0px 0px 0px 2px rgba(33, 66, 231, 0.12)',
        optionHover: '0px 0px 0px 2px rgba(17, 24, 39, 0.04)',
        optionShadow:
          '0px 2px 2px -2px rgba(17, 24, 39, 0.05), 0px 4px 4px rgba(17, 24, 39, 0.05), 0px 8px 8px rgba(17, 24, 39, 0.05)',
        brandColorSelected:
          'inset 0px 0px 0px 2px rgba(17, 24, 39, 0.08), 0px 0px 0px 4px rgba(33, 66, 231, 0.12)',
        brandColorHover: 'inset 0px 0px 0px 2px rgba(17, 24, 39, 0.12)',
        arcadeBorderNoWrapperLight:
          'inset 0px 0px 0px 1px rgba(17, 24, 39, 0.14)',
        arcadeBorderNoWrapperDark:
          'inset 0px 0px 0px 1px rgba(17, 24, 39, 0.16)',
        arcadeBorderWrapperLight:
          'inset 0px 0px 0px 1px rgba(17, 24, 39, 0.12)',
        arcadeBorderWrapperDark: 'inset 0px 0px 0px 1px rgba(31, 41, 55, 1)',
        borderTop: '0px -1px 0px rgba(17, 24, 39, 0.08)',
      },
      dropShadow: {
        hotspot: [
          '0px 2px 2px rgba(17, 24, 39, 0.06)',
          '0px 4px 4px rgba(17, 24, 39, 0.06)',
          '0px 8px 8px rgba(17, 24, 39, 0.06)',
          '0px 16px 16px rgba(17, 24, 39, 0.06)',
          '0px 32px 32px rgba(17, 24, 39, 0.06)',
        ],
        arcade: [
          '0px 4px 4px rgba(17, 24, 39, 0.06)',
          '0px 8px 8px rgba(17, 24, 39, 0.06)',
          '0px 16px 80px rgba(17, 24, 39, 0.48)',
        ],
        hard: [
          '0px 0px 1px rgba(17, 24, 39, 0.2)',
          '0px 1px 1px rgba(17, 24, 39, 0.2)',
          '0px 2px 2px rgba(17, 24, 39, 0.2)',
          '0px 4px 4px rgba(17, 24, 39, 0.2)',
          '0px 8px 8px rgba(17, 24, 39, 0.2)',
        ],
      },
      flexBasis: {
        '1/3-gap-4': 'calc(33.3% - (2/3 * 1rem))',
      },
      aspectRatio: {
        '3/2': '3 / 2',
      },
      fontFamily: {
        sans: 'Inter, system-ui, sans-serif',
        browser:
          '"Helvetica Neue", Arial, Roboto, ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      },
      width: {
        90: '25rem',
      },
      transitionProperty: {
        width: 'width',
      },
      transitionTimingFunction: {
        jelly: 'cubic-bezier(0.6, 0.6, 0, 1)',
        bouncy: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
      },
      animation: {
        'slide-up': 'slide-up .5s ease-out 1 normal both',
        'slide-up-2s-delay': 'slide-up .5s ease-out 2s 1 normal both',
      },
      keyframes: {
        'slide-up': {
          from: {
            opacity: 0,
            transform: 'translateY(100%)',
          },
          to: {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      },
      backgroundColor: {
        'custom-hover':
          'color-mix(in srgb, var(--custom-text-color) 6%, transparent)',
      },
      screens: {
        webflowlg: '992px',
        webflow2xl: '1400px',
        sm: '600px',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('tailwindcss-animatecss'),
    plugin(function ({ addVariant, e, postcss, addUtilities }) {
      addVariant('firefox', ({ container, separator }) => {
        const isFirefoxRule = postcss.atRule({
          name: '-moz-document',
          params: 'url-prefix()',
        })
        isFirefoxRule.append(container.nodes)
        container.append(isFirefoxRule)
        isFirefoxRule.walkRules(rule => {
          rule.selector = `.${e(
            `firefox${separator}${rule.selector.slice(1)}`
          )}`
        })
      })
      addUtilities({
        '.no-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
        '.no-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
      })
    }),
  ],
}
