import { captureException } from '@sentry/nextjs'
import { Extras } from '@sentry/types'

import { isProductionEnv } from '../../env-helpers'

// Export in a separate file to be able to be imported without requiring lodash from exceptions/index.ts

export function captureError(e: any, extra?: Extras) {
  if (!isProductionEnv()) {
    console.error(e, extra)
    return
  }

  captureException(e instanceof Error ? e : new Error(e), { extra })
}
