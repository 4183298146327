import { Team } from 'src/types'

import { AccountCore } from '../AccountCore'

export type Insights = ReturnType<typeof insights>

export const insights = (
  _core: AccountCore,
  teamDoc: Team | null | undefined
) => ({
  get timezone(): string {
    return teamDoc?.timezone ?? 'America/Los_Angeles'
  },

  get clearbitEnabled(): boolean {
    return teamDoc?.clearbitEnabled ?? false
  },
})
