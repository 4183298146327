"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoSourceSchema = void 0;
const zod_1 = require("zod");
exports.VideoSourceSchema = zod_1.z.enum([
    'extension-record-demo',
    'extension-record-video',
    'desktop',
    'editor-upload',
    'camera-recording',
    'figma',
]);
