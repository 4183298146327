import { DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS } from '@arcadehq/shared/constants'
import { subscriptionIsActive } from '@arcadehq/shared/helpers'
import { Team, UserProfile } from 'src/types'

import { AccountCore } from '../AccountCore'

export type TrialProgress =
  | {
      trialActive: true
      daysTotal: number
      daysUsed: number
      daysLeft: number
    }
  | {
      trialActive: false
    }

export const subscription = (
  core: AccountCore,
  userProfile: UserProfile,
  team: Team | null | undefined
) => ({
  get isFree(): boolean {
    if (core.workspace) {
      return core.workspacePlan === 'Free'
    }
    // TODO:workspaces remove below
    return core.plan.startsWith('Free')
  },

  get isPro(): boolean {
    if (core.workspace) {
      return core.workspacePlan === 'Pro'
    }
    // TODO:workspaces remove below
    return core.plan.startsWith('Pro')
  },

  // Use only to know if there's an active pro subscription behind the Growth Trial
  get hasProSubscription(): boolean {
    if (core.workspace) {
      return (
        core.workspacePlan === 'Growth' &&
        core.workspace.subscriptions.some(
          sub => sub.plan === 'Pro' && subscriptionIsActive(sub)
        )
      )
    }

    return (
      !!userProfile.customerId &&
      !!userProfile.currentSubscriber &&
      (!team ||
        (!team?.customerId && !!team?.currentSubscriber) || // trial currently active...
        (!!team?.customerId && !team?.currentSubscriber)) // or was a growth subscriber
    )
  },

  // This includes growth trial
  get isGrowth(): boolean {
    if (core.workspace) {
      return core.workspacePlan === 'Growth'
    }
    // TODO:workspaces remove below
    return core.plan.startsWith('Growth')
  },

  get isGrowthTrial(): boolean {
    if (core.workspace) {
      return core.workspacePlan === 'Growth' && !!core.workspaceTrial
    }
    // TODO:workspaces remove below
    return core.plan === 'Growth Trial'
  },

  // Includes both Growth and Enterprise trial
  get isTrial(): boolean {
    if (core.workspace) {
      return !!core.workspaceTrial
    }

    // we don't handle enterprise trial before workspace
    return this.isGrowthTrial
  },

  get isEnterprise(): boolean {
    if (core.workspace) {
      return core.workspacePlan === 'Enterprise'
    }
    // TODO:workspaces remove below
    return core.plan.startsWith('Enterprise')
  },

  // Includes growth/enterprise trial
  get isProAndAbove(): boolean {
    return this.isPro || this.isGrowthAndAbove
  },

  // Includes growth/enterprise trial
  get isGrowthAndAbove(): boolean {
    return this.isGrowth || this.isEnterprise
  },

  get isPaying(): boolean {
    return this.isProAndAbove && !this.isTrial
  },

  get isNoLongerPayingSubscriber(): boolean {
    if (core.workspace) {
      return (
        this.isFree && core.workspace.subscriptions.some(sub => !sub.isTrial)
      )
    }
    // TODO:workspaces remove below
    if (!team?.id) {
      return !core.user.currentSubscriber && !!core.user.customerId
    }
    return !team.currentSubscriber && !!team.customerId
  },

  get hasntUpgradedAfterTrial(): boolean {
    if (core.workspace) {
      if (!this.isFree && !this.isPro) return false
      const pastTrial = core.workspace.subscriptions.find(
        sub => sub.isTrial && !subscriptionIsActive(sub)
      )
      if (!pastTrial || !pastTrial.endDate) return false
      const hadPayingSubscriptionsAfterTrial =
        core.workspace.subscriptions.some(
          sub =>
            !sub.isTrial &&
            (sub.startDate >= pastTrial.endDate! ||
              (sub.endDate && sub.endDate > pastTrial.endDate!))
        )

      return !hadPayingSubscriptionsAfterTrial
    }
    // TODO:workspaces remove below
    return !!team?.id && !team.currentSubscriber && !team.customerId
  },

  get trialProgress(): TrialProgress {
    const dayMs = 1000 * 60 * 60 * 24
    const nowMs = Date.now()

    if (core.workspace) {
      if (core.workspaceTrial) {
        let trialLengthDays = DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS
        const trialStartDateMs = core.workspaceTrial.startDate.getTime()
        const trialEndDateMs = core.workspaceTrial.endDate.getTime()
        trialLengthDays = (trialEndDateMs - trialStartDateMs) / dayMs

        const daysSinceStarted = (nowMs - trialStartDateMs) / dayMs

        const trialDaysRemaining = Math.max(
          0,
          Math.ceil(trialLengthDays - daysSinceStarted)
        )

        return {
          trialActive: true,
          daysTotal: trialLengthDays,
          daysUsed: daysSinceStarted,
          daysLeft: trialDaysRemaining,
        }
      }
      return {
        trialActive: false,
      }
    }

    // TODO:workspaces remove below
    if (!team?.id || !this.isTrial || !team?.created) {
      return {
        trialActive: false,
      }
    }

    const teamCreatedMs = team.created.getTime()
    const daysSinceCreated = (nowMs - teamCreatedMs) / dayMs

    let trialLengthDays = DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS
    if (team.trialEndDateIso) {
      const trialEndDateMs = new Date(team.trialEndDateIso).getTime()
      trialLengthDays = (trialEndDateMs - teamCreatedMs) / dayMs
    }

    const trialDaysRemaining = Math.max(
      0,
      Math.ceil(trialLengthDays - daysSinceCreated)
    )

    // They're paying for pro, and the trial is expired, so they're not in a trial.
    if (trialDaysRemaining === 0 && this.isPro) {
      return {
        trialActive: false,
      }
    }

    return {
      trialActive: true,
      daysTotal: trialLengthDays,
      daysUsed: daysSinceCreated,
      daysLeft: trialDaysRemaining,
    }
  },

  get freePlanLimitsApply() {
    if (this.isPro) {
      return this.isOverdue
    }

    // trialActive=true even when days left are 0
    if (this.isTrial) {
      // TODO:workspaces Find a way to check if the team is still pro
      const proPlanSubscriberInGoodStanding =
        core.user.currentSubscriber && core.user.customerId && !this.isOverdue
      return (
        (!this.trialProgress.trialActive || this.trialProgress.daysLeft <= 0) &&
        !proPlanSubscriberInGoodStanding
      )
    }

    if (this.isGrowth) {
      return this.isOverdue || this.hasntUpgradedAfterTrial
    }

    if (this.isEnterprise) {
      return false // Enterprise has no limits and overdue status should be manually handled
    }

    return true
  },

  // Note: This currently disregards whether or not the  workspace is a
  // current subscriber. It also ignores an overdue flag if the user is
  // trialing growth.
  get isOverdue(): boolean {
    if (core.workspace) {
      return core.workspace.isOverdue && !this.isTrial
    }
    // TODO:workspaces remove below
    return !!(core.user.isOverdue || core.team.isOverdue) && !this.isTrial
  },

  get shouldSeeTrialOverBanner(): boolean {
    return (
      !this.didDismissTrialBanner &&
      (this.hasntUpgradedAfterTrial ||
        // handle displaying the banner on the last day of the trial
        (this.trialProgress.trialActive && this.trialProgress.daysLeft <= 0))
    )
  },

  get didDismissTrialBanner(): boolean {
    return userProfile.dismissedGrowthTrialBanner ?? false
  },

  dismissTrialBanner(): Promise<boolean> {
    return userProfile.update(
      { dismissedGrowthTrialBanner: true },
      core.user.id
    )
  },
})
