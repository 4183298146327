import {
  SyntheticVoiceInput,
  SyntheticVoiceRequest,
  Timepoint,
} from '@arcadehq/shared/types'
import { Account } from 'src/auth/Account'

export async function generateSyntheticVoice(
  account: Account,
  syntheticVoiceRequest: SyntheticVoiceRequest
): Promise<{ url: string; timepoints: Timepoint[] } | { error: string }> {
  const response = await account.fetchWithToken(
    'POST',
    '/api/synthetic-voice',
    syntheticVoiceRequest
  )
  return response.json()
}

export async function generateSyntheticVoiceElevenLabs(
  account: Account,
  syntheticVoiceRequest: {
    script: string
    voice: { key: string; languageCode: string | null }
  }
): Promise<{ url: string; timepoints: Timepoint[] } | { error: string }> {
  const response = await account.fetchWithToken(
    'POST',
    '/api/synthetic-voice/elevenlabs',
    syntheticVoiceRequest
  )
  return response.json()
}

// Synthetic Voices
export const arVoices: SyntheticVoiceInput[] = [
  {
    name: 'Aaliyah',
    voice: {
      key: 'ar-XA-Wavenet-A',
      languageCode: 'ar-XA',
    },
    avatarUrl: '/images/voices/ar-01.png',
    isDefault: true,
  },
  {
    name: 'Bilal',
    voice: {
      key: 'ar-XA-Wavenet-B',
      languageCode: 'ar-XA',
    },
    avatarUrl: '/images/voices/ar-02.png',
  },
  {
    name: 'Nadia',
    voice: {
      key: 'ar-XA-Wavenet-D',
      languageCode: 'ar-XA',
    },
    avatarUrl: '/images/voices/ar-03.png',
  },
  {
    name: 'Amir',
    voice: {
      key: 'ar-XA-Wavenet-C',
      languageCode: 'ar-XA',
    },
    avatarUrl: '/images/voices/ar-04.png',
  },
]

export const cmnVoices: SyntheticVoiceInput[] = [
  {
    name: 'Chen',
    voice: {
      key: 'cmn-CN-Wavenet-A',
      languageCode: 'cmn-CN',
    },
    avatarUrl: '/images/voices/cn-01.png',
    isDefault: true,
  },
  {
    name: 'Jin',
    voice: {
      key: 'cmn-CN-Wavenet-B',
      languageCode: 'cmn-CN',
    },
    avatarUrl: '/images/voices/cn-02.png',
  },
  {
    name: 'Ling',
    voice: {
      key: 'cmn-CN-Wavenet-C',
      languageCode: 'cmn-CN',
    },
    avatarUrl: '/images/voices/cn-03.png',
  },
  {
    name: 'Jiang',
    voice: {
      key: 'cmn-CN-Wavenet-D',
      languageCode: 'cmn-CN',
    },
    avatarUrl: '/images/voices/cn-04.png',
  },
]

export const deVoices: SyntheticVoiceInput[] = [
  {
    name: 'Emma',
    voice: {
      key: 'de-DE-Neural2-C',
      languageCode: 'de-DE',
    },
    avatarUrl: '/images/voices/de-01.png',
    isDefault: true,
  },
  {
    name: 'Hans',
    voice: {
      key: 'de-DE-Neural2-B',
      languageCode: 'de-DE',
    },
    avatarUrl: '/images/voices/de-02.png',
  },
  {
    name: 'Ada',
    voice: {
      key: 'de-DE-Neural2-F',
      languageCode: 'de-DE',
    },
    avatarUrl: '/images/voices/de-03.png',
  },
  {
    name: 'Emil',
    voice: {
      key: 'de-DE-Neural2-D',
      languageCode: 'de-DE',
    },
    avatarUrl: '/images/voices/de-04.png',
  },
]

export const enVoices: SyntheticVoiceInput[] = [
  {
    name: 'Hazel',
    voice: { key: 'en-US-Studio-O', languageCode: 'en-US' },
    avatarUrl: '/images/voices/en-01.png',
    isDefault: true,
  },
  {
    name: 'Charles',
    voice: {
      key: 'en-US-Studio-M',
      languageCode: 'en-US',
    },
    avatarUrl: '/images/voices/en-02.png',
  },
  {
    name: 'Alicia',
    voice: {
      key: 'en-GB-Neural2-A',
      languageCode: 'en-GB',
    },
    avatarUrl: '/images/voices/en-03.png',
  },
  {
    name: 'Gabriel',
    voice: {
      key: 'en-GB-News-L',
      languageCode: 'en-GB',
    },
    avatarUrl: '/images/voices/en-04.png',
  },
]

export const elevenLabsVoices: SyntheticVoiceInput[] = [
  {
    name: 'Dorothy',
    voice: {
      key: 'ThT5KcBeYPX3keUQqHPh',
      languageCode: null,
      variant: 'elevenlabs',
    },
    avatarUrl: '/images/voices/ar-01.png',
    isDefault: true,
  },
  {
    name: 'Matilda',
    voice: {
      key: 'XrExE9yKIg1WjnnlVkGX',
      languageCode: null,
      variant: 'elevenlabs',
    },
    avatarUrl: '/images/voices/ar-03.png',
  },
  {
    name: 'Brian',
    voice: {
      key: 'nPczCjzI2devNBz1zQrb',
      languageCode: null,
      variant: 'elevenlabs',
    },
    avatarUrl: '/images/voices/ar-02.png',
  },
  {
    name: 'Daniel',
    voice: {
      key: 'onwK4e9ZLuTAKqWW03F9',
      languageCode: null,
      variant: 'elevenlabs',
    },
    avatarUrl: '/images/voices/ar-04.png',
  },
]

export const esVoices: SyntheticVoiceInput[] = [
  {
    name: 'Isabella',
    voice: {
      key: 'es-US-Neural2-A',
      languageCode: 'es-US',
    },
    avatarUrl: '/images/voices/es-01.png',
    isDefault: true,
  },
  {
    name: 'Mateo',
    voice: {
      key: 'es-US-Neural2-B',
      languageCode: 'es-US',
    },
    avatarUrl: '/images/voices/es-02.png',
  },
  {
    name: 'Sofia',
    voice: {
      key: 'es-ES-Neural2-A',
      languageCode: 'es-ES',
    },
    avatarUrl: '/images/voices/es-03.png',
  },
  {
    name: 'Antonio',
    voice: {
      key: 'es-ES-Neural2-B',
      languageCode: 'es-ES',
    },
    avatarUrl: '/images/voices/es-04.png',
  },
]

export const frVoices: SyntheticVoiceInput[] = [
  {
    name: 'Camille',
    voice: {
      key: 'fr-FR-Neural2-A',
      languageCode: 'fr-FR',
    },
    avatarUrl: '/images/voices/fr-01.png',
    isDefault: true,
  },
  {
    name: 'Louis',
    voice: {
      key: 'fr-FR-Neural2-B',
      languageCode: 'fr-FR',
    },
    avatarUrl: '/images/voices/fr-02.png',
  },
  {
    name: 'Juliette',
    voice: {
      key: 'fr-CA-Neural2-A',
      languageCode: 'fr-CA',
    },
    avatarUrl: '/images/voices/fr-03.png',
  },
  {
    name: 'Alexandre',
    voice: {
      key: 'fr-CA-Neural2-B',
      languageCode: 'fr-CA',
    },
    avatarUrl: '/images/voices/fr-04.png',
  },
]

export const hiVoices: SyntheticVoiceInput[] = [
  {
    name: 'Parvati',
    voice: {
      key: 'hi-IN-Neural2-A',
      languageCode: 'hi-IN',
    },
    avatarUrl: '/images/voices/in-01.png',
    isDefault: true,
  },
  {
    name: 'Darsh',
    voice: {
      key: 'hi-IN-Neural2-B',
      languageCode: 'hi-IN',
    },
    avatarUrl: '/images/voices/in-02.png',
  },
  {
    name: 'Aparna',
    voice: {
      key: 'hi-IN-Neural2-D',
      languageCode: 'hi-IN',
    },
    avatarUrl: '/images/voices/in-03.png',
  },
  {
    name: 'Dev',
    voice: {
      key: 'hi-IN-Neural2-C',
      languageCode: 'hi-IN',
    },
    avatarUrl: '/images/voices/in-04.png',
  },
]

export const itVoices: SyntheticVoiceInput[] = [
  {
    name: 'Francesca',
    voice: {
      key: 'it-IT-Neural2-A',
      languageCode: 'it-IT',
    },
    avatarUrl: '/images/voices/it-01.png',
    isDefault: true,
  },
  {
    name: 'Luca',
    voice: {
      key: 'it-IT-Neural2-C',
      languageCode: 'it-IT',
    },
    avatarUrl: '/images/voices/it-02.png',
  },
  {
    name: 'Bianca',
    voice: {
      key: 'it-IT-Wavenet-A',
      languageCode: 'it-IT',
    },
    avatarUrl: '/images/voices/it-03.png',
  },
  {
    name: 'Francesco',
    voice: {
      key: 'it-IT-Wavenet-B',
      languageCode: 'it-IT',
    },
    avatarUrl: '/images/voices/it-04.png',
  },
]

export const jaVoices: SyntheticVoiceInput[] = [
  {
    name: 'Aya',
    voice: {
      key: 'ja-JP-Neural2-B',
      languageCode: 'ja-JP',
    },
    avatarUrl: '/images/voices/jp-01.png',
    isDefault: true,
  },
  {
    name: 'Kenji',
    voice: {
      key: 'ja-JP-Neural2-C',
      languageCode: 'ja-JP',
    },
    avatarUrl: '/images/voices/jp-02.png',
  },
  {
    name: 'Emi',
    voice: {
      key: 'ja-JP-Wavenet-A',
      languageCode: 'ja-JP',
    },
    avatarUrl: '/images/voices/jp-03.png',
  },
  {
    name: 'Akio',
    voice: {
      key: 'ja-JP-Neural2-D',
      languageCode: 'ja-JP',
    },
    avatarUrl: '/images/voices/jp-04.png',
  },
]

export const koVoices: SyntheticVoiceInput[] = [
  {
    name: 'Sook',
    voice: {
      key: 'ko-KR-Neural2-A',
      languageCode: 'ko-KR',
    },
    avatarUrl: '/images/voices/kr-01.png',
    isDefault: true,
  },
  {
    name: 'Joon',
    voice: {
      key: 'ko-KR-Neural2-B',
      languageCode: 'ko-KR',
    },
    avatarUrl: '/images/voices/kr-02.png',
  },
  {
    name: 'Eun',
    voice: {
      key: 'ko-KR-Neural2-C',
      languageCode: 'ko-KR',
    },
    avatarUrl: '/images/voices/kr-03.png',
  },
  {
    name: 'Jung',
    voice: {
      key: 'ko-KR-Wavenet-A',
      languageCode: 'ko-KR',
    },
    avatarUrl: '/images/voices/kr-04.png',
  },
]

export const nlVoices: SyntheticVoiceInput[] = [
  {
    name: 'Maria',
    voice: {
      key: 'nl-NL-Wavenet-D',
      languageCode: 'nl-NL',
    },
    avatarUrl: '/images/voices/de-03.png',
    isDefault: true,
  },
  {
    name: 'Johannes',
    voice: {
      key: 'nl-NL-Wavenet-B',
      languageCode: 'nl-NL',
    },
    avatarUrl: '/images/voices/de-04.png',
  },
  {
    name: 'Mila',
    voice: {
      key: 'nl-BE-Wavenet-A',
      languageCode: 'nl-NL',
    },
    avatarUrl: '/images/voices/de-01.png',
  },
  {
    name: 'Liam',
    voice: {
      key: 'nl-BE-Wavenet-B',
      languageCode: 'nl-NL',
    },
    avatarUrl: '/images/voices/de-02.png',
  },
]

export const ptVoices: SyntheticVoiceInput[] = [
  {
    name: 'Fernanda',
    voice: {
      key: 'pt-PT-Wavenet-A',
      languageCode: 'pt-PT',
    },
    avatarUrl: '/images/voices/pt-01.png',
    isDefault: true,
  },
  {
    name: 'Lucas',
    voice: {
      key: 'pt-PT-Wavenet-B',
      languageCode: 'pt-PT',
    },
    avatarUrl: '/images/voices/pt-02.png',
  },
  {
    name: 'Mariana',
    voice: {
      key: 'pt-BR-Neural2-A',
      languageCode: 'pt-BR',
    },
    avatarUrl: '/images/voices/pt-03.png',
  },
  {
    name: 'Paulo',
    voice: {
      key: 'pt-BR-Neural2-B',
      languageCode: 'pt-BR',
    },
    avatarUrl: '/images/voices/pt-04.png',
  },
]

export const trVoices: SyntheticVoiceInput[] = [
  {
    name: 'Elif',
    voice: {
      key: 'tr-TR-Wavenet-A',
      languageCode: 'tr-TR',
    },
    avatarUrl: '/images/voices/ar-01.png',
    isDefault: true,
  },
  {
    name: 'Ahmet',
    voice: {
      key: 'tr-TR-Wavenet-B',
      languageCode: 'tr-TR',
    },
    avatarUrl: '/images/voices/ar-02.png',
  },
  {
    name: 'Ceren',
    voice: {
      key: 'tr-TR-Wavenet-C',
      languageCode: 'tr-TR',
    },
    avatarUrl: '/images/voices/ar-03.png',
  },
  {
    name: 'Yusuf',
    voice: {
      key: 'tr-TR-Wavenet-E',
      languageCode: 'tr-TR',
    },
    avatarUrl: '/images/voices/ar-04.png',
  },
]

// Swedish Voices
export const svVoices: SyntheticVoiceInput[] = [
  {
    name: 'Annika',
    voice: {
      key: 'sv-SE-Wavenet-A',
      languageCode: 'sv-SE',
    },
    avatarUrl: '/images/voices/de-01.png',
    isDefault: true,
  },
  {
    name: 'Noah',
    voice: {
      key: 'sv-SE-Wavenet-C',
      languageCode: 'sv-SE',
    },
    avatarUrl: '/images/voices/de-02.png',
  },
  {
    name: 'Cecilia',
    voice: {
      key: 'sv-SE-Wavenet-D',
      languageCode: 'sv-SE',
    },
    avatarUrl: '/images/voices/de-03.png',
  },
  {
    name: 'Halvar',
    voice: {
      key: 'sv-SE-Wavenet-E',
      languageCode: 'sv-SE',
    },
    avatarUrl: '/images/voices/de-04.png',
  },
]

// Norwegian Voices
export const nbVoices: SyntheticVoiceInput[] = [
  {
    name: 'Nora',
    voice: {
      key: 'nb-NO-Wavenet-A',
      languageCode: 'nb-NO',
    },
    avatarUrl: '/images/voices/de-01.png',
    isDefault: true,
  },
  {
    name: 'Lukas',
    voice: {
      key: 'nb-NO-Wavenet-B',
      languageCode: 'nb-NO',
    },
    avatarUrl: '/images/voices/de-02.png',
  },
  {
    name: 'Eva',
    voice: {
      key: 'nb-NO-Wavenet-C',
      languageCode: 'nb-NO',
    },
    avatarUrl: '/images/voices/de-03.png',
  },
  {
    name: 'Isac',
    voice: {
      key: 'nb-NO-Wavenet-D',
      languageCode: 'nb-NO',
    },
    avatarUrl: '/images/voices/de-04.png',
  },
]

// Indonesian voices
export const idVoices: SyntheticVoiceInput[] = [
  {
    name: 'Annisa',
    voice: {
      key: 'id-ID-Wavenet-A',
      languageCode: 'id-ID',
    },
    avatarUrl: '/images/voices/kr-01.png',
    isDefault: true,
  },
  {
    name: 'Junaidi',
    voice: {
      key: 'id-ID-Wavenet-B',
      languageCode: 'id-ID',
    },
    avatarUrl: '/images/voices/kr-02.png',
  },
  {
    name: 'Citra',
    voice: {
      key: 'id-ID-Wavenet-D',
      languageCode: 'id-ID',
    },
    avatarUrl: '/images/voices/kr-03.png',
  },
  {
    name: 'Herman',
    voice: {
      key: 'id-ID-Wavenet-C',
      languageCode: 'id-ID',
    },
    avatarUrl: '/images/voices/kr-04.png',
  },
]

export const internationalVoices = {
  ar: arVoices,
  cmn: cmnVoices,
  de: deVoices,
  en: enVoices,
  es: esVoices,
  fr: frVoices,
  hi: hiVoices,
  it: itVoices,
  ja: jaVoices,
  ko: koVoices,
  nl: nlVoices,
  pt: ptVoices,
  tr: trVoices,
  sv: svVoices,
  no: nbVoices,
  id: idVoices,
}

export const isElevenLabsSupportedLanguage = (language: string) =>
  language !== 'no'
