"use strict";
// For our current exports, we cap the length of the longest edge, so we need
// retain the aspect ratio and scale the width and height accordingly if the
Object.defineProperty(exports, "__esModule", { value: true });
exports.scaleWithConstraint = scaleWithConstraint;
exports.scaleForVideo = scaleForVideo;
const constants_1 = require("../constants");
// longest edge exceeds the size constraint.
function scaleWithConstraint({ width, height, }, { widthConstraint, heightConstraint, }) {
    if (width <= widthConstraint && height <= heightConstraint) {
        return { width: Math.round(width), height: Math.round(height) };
    }
    const byWidth = {
        width: Math.round(widthConstraint),
        height: Math.round((height * widthConstraint) / width),
    };
    const byHeight = {
        width: Math.round((width * heightConstraint) / height),
        height: Math.round(heightConstraint),
    };
    if (byHeight.height < byWidth.height)
        return byHeight;
    return byWidth;
}
// - H264 requires even dimensions
// - Wrapper takes up % of export height but should not affect aspect ratio
function scaleForVideo({ width, height }, sizeConstraint, noWrapper) {
    let { width: scaledWidth, height: scaledHeight } = scaleWithConstraint({ width, height }, {
        widthConstraint: sizeConstraint,
        heightConstraint: noWrapper
            ? sizeConstraint
            : sizeConstraint * (1 - constants_1.BROWSER_BAR_EXPORT_HEIGHT_FRAC),
    });
    if (!noWrapper) {
        scaledHeight += Math.round(scaledHeight * constants_1.BROWSER_BAR_EXPORT_HEIGHT_FRAC);
    }
    if (scaledWidth % 2 !== 0) {
        scaledWidth += 1;
    }
    if (scaledHeight % 2 !== 0) {
        scaledHeight += 1;
    }
    return { width: scaledWidth, height: scaledHeight };
}
