import {
  getSubfield,
  selectWatermarkHoverText,
  selectWatermarkImageUrl,
  selectWatermarkLinkUrl,
  selectWatermarkOpenInTheSameTab,
} from '@arcadehq/shared/lib/helpers'
import { Feature } from '@arcadehq/shared/types'
import { Flow, TeamTheme } from 'src/types'

import { AccountCore } from '../AccountCore'
import { setOrDeleteSubfield } from '../helpers'

export type Watermark = ReturnType<typeof watermark>

export const watermark = (
  core: AccountCore,
  theme: TeamTheme | null, // null if Pro
  flow?: Flow
) => ({
  get mayToggle(): boolean {
    return !core.subscription.isFree
  },

  get mayConfigure(): boolean {
    return core.subscription.isGrowth || core.subscription.isEnterprise
  },

  showInEditor(flow?: Flow) {
    if (!flow) return true

    // TODO materialize entitlements on the base class
    const isShowArcadeButtonNullOrUndefined =
      flow.showArcadeButton === null || flow.showArcadeButton === undefined

    if (core.subscription.isFree) {
      return true
    }
    if (core.subscription.isPro) {
      return isShowArcadeButtonNullOrUndefined
        ? !core.user.getFeature(Feature.NoArcadeBranding)
        : flow.showArcadeButton !== false
    }
    if (core.subscription.isGrowth || core.subscription.isEnterprise) {
      if (isShowArcadeButtonNullOrUndefined && theme) {
        return !theme.features?.[Feature.NoArcadeBranding]
      }
      return flow.showArcadeButton !== false
    }
  },

  get showByDefault() {
    if (!this.mayToggle) return true

    if (flow && typeof flow.showArcadeButton === 'boolean') {
      return flow.showArcadeButton
    }

    return theme
      ? !getSubfield(theme, 'features', Feature.NoArcadeBranding)
      : true
  },

  setShowByDefault(showByDefault: boolean) {
    if (flow) {
      return flow.update({ showArcadeButton: showByDefault }, core.user.id)
    }

    return theme
      ? setOrDeleteSubfield(
          theme,
          'features',
          Feature.NoArcadeBranding,
          !showByDefault,
          core.user.id
        )
      : Promise.resolve(false)
  },

  get linkUrl() {
    return this.mayConfigure ? selectWatermarkLinkUrl(theme, flow) : undefined
  },

  get imageUrl() {
    return this.mayConfigure ? selectWatermarkImageUrl(theme, flow) : undefined
  },

  get hoverText() {
    return this.mayConfigure ? selectWatermarkHoverText(theme, flow) : undefined
  },

  get openInTheSameTab() {
    return this.mayConfigure
      ? selectWatermarkOpenInTheSameTab(theme, flow)
      : false
  },

  setLinkUrl(linkUrl: string | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'watermark',
      'link',
      linkUrl,
      core.user.id
    )
  },

  setImageUrl(imageUrl: string | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'watermark',
      'imageUrl',
      imageUrl,
      core.user.id
    )
  },

  setHoverText(hoverText: string | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'watermark',
      'text',
      hoverText,
      core.user.id
    )
  },

  setOpenInTheSameTab(openInTheSameTab: boolean | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'watermark',
      'openInTheSameTab',
      openInTheSameTab,
      core.user.id
    )
  },
})
