import colors from '@arcadehq/configs/tailwind/colors'
import { getSubfield } from '@arcadehq/shared/lib/helpers'
import { Flow, FlowPublicData, TeamPublicData, TeamTheme } from 'src/types'

import { AccountCore } from '../AccountCore'
import { setOrDeleteSubfield } from '../helpers'

export type SharePage = ReturnType<typeof sharePage>

type Entity = Pick<
  TeamPublicData,
  'sharePageLogo' | 'sharePageButton' | 'prefs'
> | null

export const selectLinkUrl = (entity: Entity, flow?: FlowPublicData) =>
  (flow && getSubfield(flow, 'sharePageLogo', 'link')) ??
  getSubfield(entity, 'sharePageLogo', 'link')

export const selectLogoImageUrl = (entity: Entity, flow?: FlowPublicData) =>
  (flow && getSubfield(flow, 'sharePageLogo', 'imageUrl')) ??
  getSubfield(entity, 'sharePageLogo', 'imageUrl')

export const selectOpenLogoInTheSameTab = (
  entity: Entity,
  flow?: FlowPublicData
) =>
  (flow && getSubfield(flow, 'sharePageLogo', 'openInTheSameTab')) ??
  getSubfield(entity, 'sharePageLogo', 'openInTheSameTab') ??
  true

const selectCtaButtonTextSaved = (entity: Entity, flow?: FlowPublicData) =>
  (flow && getSubfield(flow, 'sharePageButton', 'text')) ??
  getSubfield(entity, 'sharePageButton', 'text')

export const selectCtaButtonText = (entity: Entity, flow?: FlowPublicData) =>
  selectCtaButtonTextSaved(entity, flow) || 'Sign in'

const selectDefaultCtaButtonColor = (entity: Entity) =>
  entity?.prefs?.bgColor || colors.blue[600]

const selectCtaButtonColorSaved = (entity: Entity, flow?: FlowPublicData) =>
  (flow && getSubfield(flow, 'sharePageButton', 'bgColor')) ||
  getSubfield(entity, 'sharePageButton', 'bgColor')

export const selectCtaButtonColor = (entity: Entity, flow?: FlowPublicData) =>
  selectCtaButtonColorSaved(entity, flow) || selectDefaultCtaButtonColor(entity)

const selectDefaultCtaButtonTextColor = (entity: Entity) =>
  entity?.prefs?.fgColor || colors.white

const selectCtaButtonTextColorSaved = (entity: Entity, flow?: FlowPublicData) =>
  (flow && getSubfield(flow, 'sharePageButton', 'textColor')) ||
  getSubfield(entity, 'sharePageButton', 'textColor')

export const selectCtaButtonTextColor = (
  entity: Entity,
  flow?: FlowPublicData
) =>
  selectCtaButtonTextColorSaved(entity, flow) ||
  selectDefaultCtaButtonTextColor(entity)

export const selectCtaButtonLinkUrl = (entity: Entity, flow?: FlowPublicData) =>
  (flow && getSubfield(flow, 'sharePageButton', 'link')) ??
  getSubfield(entity, 'sharePageButton', 'link')

export const selectOpenCtaButtonInTheSameTab = (
  entity: Entity,
  flow?: FlowPublicData
) =>
  (flow && getSubfield(flow, 'sharePageButton', 'openInTheSameTab')) ??
  getSubfield(entity, 'sharePageButton', 'openInTheSameTab') ??
  true

export const selectHasCustomCta = (entity: Entity, flow?: FlowPublicData) =>
  !!(
    selectCtaButtonLinkUrl(entity, flow) ||
    selectCtaButtonText(entity, flow) ||
    selectCtaButtonColorSaved(entity, flow) ||
    selectCtaButtonTextColorSaved(entity, flow)
  )

export const sharePage = (
  core: AccountCore,
  theme: TeamTheme | null,
  flow?: Flow
) => ({
  get mayConfigure(): boolean {
    return !core.subscription.isFree && !core.subscription.isPro
  },

  get hasCustomCta() {
    return this.mayConfigure ? selectHasCustomCta(theme, flow) : false
  },

  get logoLinkUrl() {
    return this.mayConfigure ? selectLinkUrl(theme, flow) : undefined
  },

  get logoImageUrl() {
    return this.mayConfigure ? selectLogoImageUrl(theme, flow) : undefined
  },

  get openLogoInTheSameTab() {
    return this.mayConfigure ? selectOpenLogoInTheSameTab(theme, flow) : false
  },

  get ctaButtonText() {
    return this.mayConfigure ? selectCtaButtonText(theme, flow) : 'Sign in'
  },

  get ctaButtonColor() {
    return this.mayConfigure
      ? selectCtaButtonColor(theme, flow)
      : colors.blue[600]
  },

  get ctaButtonTextColor() {
    return this.mayConfigure
      ? selectCtaButtonTextColor(theme, flow)
      : colors.white
  },

  get ctaButtonLinkUrl() {
    return this.mayConfigure ? selectCtaButtonLinkUrl(theme, flow) : undefined
  },

  get openCtaButtonInTheSameTab() {
    return this.mayConfigure
      ? selectOpenCtaButtonInTheSameTab(theme, flow)
      : false
  },

  setLogoLinkUrl(logoLinkUrl: string | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'sharePageLogo',
      'link',
      logoLinkUrl,
      core.user.id
    )
  },

  setLogoImageUrl(logoImageUrl: string | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'sharePageLogo',
      'imageUrl',
      logoImageUrl,
      core.user.id
    )
  },

  setOpenLogoInTheSameTab(openInTheSameTab: boolean) {
    const model = flow || theme
    return model?.update(
      {
        sharePageLogo: {
          ...(model.sharePageLogo ?? {}),
          openInTheSameTab,
        },
      },
      core.user.id
    )
  },

  setCtaButtonText(ctaButtonText: string | undefined) {
    return (
      setOrDeleteSubfield(
        flow || theme,
        'sharePageButton',
        'text',
        ctaButtonText,
        core.user.id
      ) || 'Sign in'
    )
  },

  setCtaButtonColor(ctaButtonColor: string | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'sharePageButton',
      'bgColor',
      ctaButtonColor,
      core.user.id
    )
  },

  setCtaButtonTextColor(ctaButtonTextColor: string | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'sharePageButton',
      'textColor',
      ctaButtonTextColor,
      core.user.id
    )
  },

  setCtaButtonLinkUrl(ctaButtonLinkUrl: string | undefined) {
    return setOrDeleteSubfield(
      flow || theme,
      'sharePageButton',
      'link',
      ctaButtonLinkUrl,
      core.user.id
    )
  },

  setOpenCtaButtonInTheSameTab(openInTheSameTab: boolean) {
    const model = flow || theme
    return model?.update(
      {
        sharePageButton: {
          ...(model.sharePageButton ?? {}),
          openInTheSameTab,
        },
      },
      core.user.id
    )
  },
})
