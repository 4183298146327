"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMagicCursor = getMagicCursor;
exports.desktopCaptureMagicCursorPath = desktopCaptureMagicCursorPath;
exports.getCursorPathForStep = getCursorPathForStep;
exports.desktopEventPositionToCoords = desktopEventPositionToCoords;
async function getMagicCursor(flow, db, { requiredProcessed = true, requireEnabled = true, onError, } = {}) {
    if (!flow.uploadId || (requireEnabled && !flow.useMagicCursor))
        return null;
    try {
        const upload = await db.collection('apiUploads').doc(flow.uploadId).get();
        if (!upload.exists)
            return null;
        const uploadData = upload.data();
        if (!uploadData.assets['video'] ||
            !uploadData.assets['metadata'] ||
            !uploadData.assets['mousemoves'] ||
            !uploadData.assets['mousedrags'])
            return null;
        const video = await db
            .collection('videos')
            .where('sourceUrl', '==', uploadData.assets['video'].url)
            .get();
        if (video.docs.length !== 1)
            return null;
        const videoId = video.docs[0].id;
        const videoData = video.docs[0].data();
        const assetId = videoData.url ? videoData.assetId ?? null : null; // Has url if processed
        if (!assetId && requiredProcessed)
            return null;
        const [metadata, mousemoves, mousedrags] = await Promise.all([
            uploadData.assets['metadata'].url,
            uploadData.assets['mousemoves'].url,
            uploadData.assets['mousedrags'].url,
        ].map(url => fetch(url).then(res => res.json())));
        const path = desktopCaptureMagicCursorPath({
            metadata,
            mousemoves,
            mousedrags,
        });
        if (!path)
            return null;
        return { assetId, videoId, path };
    }
    catch (e) {
        onError?.(e);
    }
    return null;
}
function desktopCaptureMagicCursorPath({ metadata, mousemoves, mousedrags, }) {
    function normalize(timestamp) {
        return timestamp - metadata.startUptime;
    }
    function rotate(e) {
        return (e.deltaX > 0 ? Math.min(e.deltaX, 3) : Math.max(e.deltaX, -3)) * 5;
    }
    const duration = normalize(metadata.endUptime);
    // Weave together mousemoves and mousedrags
    const path = [];
    let moveI = 0;
    let dragI = 0;
    function addPos(e) {
        const time = normalize(e.timestamp);
        if (time < 0 || time > duration)
            return; // Shouldn't happen but just in case
        const pos = {
            ...desktopEventPositionToCoords(e, metadata),
            time,
            rotation: rotate(e),
        };
        if (!path.length)
            path.push({ ...pos, time: 0 });
        path.push(pos);
    }
    while (moveI < mousemoves.length || dragI < mousedrags.length) {
        if (moveI === mousemoves.length) {
            addPos(mousedrags[dragI]);
            dragI++;
            continue;
        }
        if (dragI === mousedrags.length) {
            addPos(mousemoves[moveI]);
            moveI++;
            continue;
        }
        if (mousemoves[moveI].timestamp < mousedrags[dragI].timestamp) {
            addPos(mousemoves[moveI]);
            moveI++;
            continue;
        }
        else {
            addPos(mousedrags[dragI]);
            dragI++;
            continue;
        }
    }
    if (!path.length)
        return null;
    path.push({ ...path[path.length - 1], time: duration });
    return path;
}
function getCursorPathForStep({ path, assetId }, step) {
    const assetIdMatch = !assetId ? true : step.assetId === assetId;
    if (!path.length || !assetIdMatch)
        return null;
    const duration = path[path.length - 1].time;
    const startTime = duration * (step.startTimeFrac ?? 0);
    const endTime = duration * (step.endTimeFrac ?? 1);
    let beforeIndex = 0;
    let afterIndex = path.length - 1;
    for (let i = 0; i < path.length; i++) {
        const moveTime = path[i].time;
        if (moveTime < startTime)
            beforeIndex = i;
        if (moveTime > endTime) {
            afterIndex = i;
            break;
        }
    }
    return [
        // Ensure there's always a position at the beginning of the video step
        { ...path[beforeIndex], time: 0 },
        // Add all events occurring within the step window
        ...path
            .slice(beforeIndex + 1, afterIndex)
            .map(p => ({ ...p, time: p.time - startTime })),
        // Ensure there's always a position at the end of the video step
        { ...path[afterIndex - 1], time: endTime - startTime },
    ];
}
function desktopEventPositionToCoords(e, metadata) {
    const recordScreen = metadata.screens.find(screen => screen.id === metadata.recordScreen);
    if (!recordScreen)
        throw new Error('Recording screen not found');
    const xInRecordArea = e.x - recordScreen.x - metadata.recordArea.x;
    const yInRecordArea = e.y - recordScreen.y - metadata.recordArea.y;
    return {
        x: xInRecordArea / metadata.recordArea.width,
        y: 1 - yInRecordArea / metadata.recordArea.height,
    };
}
