"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubfield = void 0;
const getSubfield = (obj, fieldName, subfieldName) => {
    const field = obj?.[fieldName];
    if (!field)
        return undefined;
    // Workaround for https://github.com/microsoft/TypeScript/issues/21760
    const subfield = field[subfieldName];
    return subfield ?? undefined;
};
exports.getSubfield = getSubfield;
