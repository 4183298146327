import {
  getTranslatedFlowDescription,
  getTranslatedFlowNameExternal,
  getTranslatedStepData,
} from '@arcadehq/shared/helpers'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { atoms } from 'src/state/atoms'
import { Flow } from 'src/types'
import { getEmptyFlow } from 'src/utils/flows'

import { resetActiveStepIdAtom } from './local-state/activeStep'
import { resetInspectorPanel } from './local-state/inspectorPanel'
import { resetViewerPlaybackAtom } from './local-state/viewerPlayback'

export const originalflowAtom = atom<Flow, [Flow], void>(
  getEmptyFlow(),
  (get, set, flow: Flow) => {
    const currentFlow = get(originalflowAtom)

    set(originalflowAtom, flow)

    if (flow.id !== currentFlow.id) {
      set(resetActiveStepIdAtom)
      set(resetInspectorPanel)
      set(resetViewerPlaybackAtom)
    }
  }
)

// This atom may be translated if the preview language is set
export const flowAtom = atom(get => {
  const previewLanguage = get(atoms.previewLanguage)
  const flow = get(originalflowAtom)

  if (previewLanguage !== 'default' && flow.translations) {
    const translatedStringsInFlow = flow.translations[previewLanguage]?.strings
    return {
      ...flow,
      externalName: getTranslatedFlowNameExternal(
        flow.externalName ?? flow.name,
        translatedStringsInFlow
      ),
      description: getTranslatedFlowDescription(
        flow.description,
        translatedStringsInFlow
      ),
      steps: getTranslatedStepData(flow.steps, translatedStringsInFlow),
    }
  }

  return flow
})

export function useFlow() {
  return useAtomValue(flowAtom)
}

export function useSetFlow() {
  return useSetAtom(originalflowAtom)
}

export function useHydrateFlow(flow: Flow) {
  useHydrateAtoms([[originalflowAtom, flow]])
}
