import { UserProfile } from 'src/types'

import { AccountCore } from '../AccountCore'

export type Onboarding = ReturnType<typeof onboarding>

export const onboarding = (core: AccountCore, userProfile: UserProfile) => ({
  get useCases(): UserProfile['onboardingUseCases'] | undefined {
    return userProfile.onboardingUseCases
  },

  get getStartedArticlesHidden():
    | UserProfile['getStartedArticlesHidden']
    | undefined {
    return userProfile.getStartedArticlesHidden
  },

  saveFormOutput(
    useCases: UserProfile['onboardingUseCases'],
    companySize: UserProfile['onboardingCompanySize']
  ): Promise<boolean> {
    return userProfile.update(
      {
        onboardingUseCases: useCases,
        onboardingCompanySize: companySize,
      },
      core.user.id
    )
  },

  hideGetStartedArticle(articleId: string): Promise<boolean> {
    return userProfile.update(
      {
        getStartedArticlesHidden: {
          ...userProfile.getStartedArticlesHidden,
          [articleId]: true,
        },
      },
      core.user.id
    )
  },

  get needsOnboardingForm(): boolean {
    if (
      core.isFullyLoaded &&
      !userProfile.onboardingCompanySize &&
      !userProfile.onboardingUseCases
    ) {
      const cutoffDate = new Date('2023-06-22T17:00:00.000Z') // date of the onboarding form release
      if (
        core.user.creationDate > cutoffDate ||
        // The following condition is to guard against showing the onboarding for users who slipped
        // through the onboarding form. We don't want to show the form to users who have already been using
        // the product. It would be more ideal to check if they've created an Arcade before, but that
        // requires a database call.
        !core.user.activeTeamId
      ) {
        return true
      }
    }
    return false
  },
})
