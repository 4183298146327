"use strict";
// URL can be in the shape of:
//
// * `https://cdn.arcade.software/path/to/file.ext`
// * `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/path%2Fto%2Ffile.ext`
// * `https://storage.googleapis.com/${projectId}.appspot.com/path/to/file.ext`
// * `https://storage.googleapis.com/cdn.arcade.software/path%2Fto%2Ffile.ext`
// * `http://localhost:9199/${projectId}.appspot.com/path/to/file.ext`
// * `http://localhost:9199/v0/b/${projectId}.appspot.com/o/path%2Fto%2Ffile.ext`
//
// It will return the bucket name and the file path as `path/to/file.ext`.
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseStorageUrl = parseStorageUrl;
exports.getFilePathFromStorageUrl = getFilePathFromStorageUrl;
function parseStorageUrl(urlString) {
    const url = new URL(urlString);
    if ((['firebasestorage.googleapis.com', 'localhost', '127.0.0.1'].includes(url.hostname) ||
        /.trycloudflare.com/.test(url.hostname)) &&
        url.pathname.startsWith('/v0/')) {
        return {
            bucket: url.pathname.split('/b/')[1].split('/')[0],
            // Keep only the object name (decoded)
            path: decodeURIComponent(url.pathname.split('/o/')[1] || ''),
        };
    }
    else if (['storage.googleapis.com', 'localhost', '127.0.0.1'].includes(url.hostname)) {
        return {
            bucket: url.pathname.split('/')[1],
            // Remove first path component (bucket name)
            path: decodeURIComponent(url.pathname.split('/').slice(2).join('/')),
        };
    }
    else if ('cdn.arcade.software' === url.hostname) {
        // Remove leading slash
        return {
            bucket: url.hostname,
            path: url.pathname.slice(1),
        };
    }
    else {
        return undefined;
    }
}
function getFilePathFromStorageUrl(url) {
    const parsed = parseStorageUrl(url);
    if (!parsed)
        return undefined;
    return parsed.path;
}
