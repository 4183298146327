"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWatermarkDisplayProps = exports.selectWatermarkOpenInTheSameTab = exports.selectWatermarkHoverText = exports.selectWatermarkImageUrl = exports.selectWatermarkLinkUrl = void 0;
const types_1 = require("../types");
const utils_1 = require("./utils");
const selectWatermarkLinkUrl = (entity, // null if Pro
flow) => (flow && (0, utils_1.getSubfield)(flow, 'watermark', 'link')) ??
    (0, utils_1.getSubfield)(entity, 'watermark', 'link');
exports.selectWatermarkLinkUrl = selectWatermarkLinkUrl;
const selectWatermarkImageUrl = (entity, flow) => (flow && (0, utils_1.getSubfield)(flow, 'watermark', 'imageUrl')) ??
    (0, utils_1.getSubfield)(entity, 'watermark', 'imageUrl');
exports.selectWatermarkImageUrl = selectWatermarkImageUrl;
const selectWatermarkHoverText = (entity, flow) => (flow && (0, utils_1.getSubfield)(flow, 'watermark', 'text')) ??
    (0, utils_1.getSubfield)(entity, 'watermark', 'text');
exports.selectWatermarkHoverText = selectWatermarkHoverText;
const selectWatermarkOpenInTheSameTab = (entity, flow) => (flow && (0, utils_1.getSubfield)(flow, 'watermark', 'openInTheSameTab')) ??
    (0, utils_1.getSubfield)(entity, 'watermark', 'openInTheSameTab') ??
    false;
exports.selectWatermarkOpenInTheSameTab = selectWatermarkOpenInTheSameTab;
const getWatermarkDisplayProps = (flow, flowCreatorUserProfile = null, flowTeam = null, theme) => {
    const { features } = theme ?? flowTeam ?? {};
    const isActiveGrowth = !!flowTeam?.currentSubscriber;
    const isActivePro = !!flowCreatorUserProfile?.currentSubscriber;
    const customProps = isActiveGrowth
        ? {
            imageUrl: (0, exports.selectWatermarkImageUrl)(theme || flowTeam, flow) ?? '',
            link: (0, exports.selectWatermarkLinkUrl)(theme || flowTeam, flow) ?? '',
            text: (0, exports.selectWatermarkHoverText)(theme || flowTeam, flow) ?? '',
            openInTheSameTab: (0, exports.selectWatermarkOpenInTheSameTab)(theme || flowTeam, flow),
        }
        : undefined;
    if (typeof flow.showArcadeButton === 'boolean' &&
        (isActiveGrowth || isActivePro)) {
        return { shouldDisplay: flow.showArcadeButton, customProps };
    }
    const belongsToTeam = flow.belongsToTeam || flowCreatorUserProfile?.isActiveMemberOfTeam;
    if (isActiveGrowth && belongsToTeam) {
        return features?.[types_1.Feature.NoArcadeBranding]
            ? { shouldDisplay: false }
            : { shouldDisplay: true, customProps };
    }
    if (isActivePro) {
        return {
            shouldDisplay: flow.showArcadeButton === null
                ? !flowCreatorUserProfile.features?.[types_1.Feature.NoArcadeBranding]
                : !!flow.showArcadeButton,
        };
    }
    // Creator is a Free user
    return {
        shouldDisplay: true,
    };
};
exports.getWatermarkDisplayProps = getWatermarkDisplayProps;
