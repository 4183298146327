"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmbedOption = exports.ViewerLayout = exports.FlowNavSetting = exports.MaskType = exports.MaskLevel = exports.BlurLevel = exports.BlurType = exports.OverlayBlur = exports.OverlayVariant = exports.OverlayTheme = exports.OverlayType = exports.StepType = exports.CustomCursor = exports.FlowWrapper = exports.OpeningAnimation = exports.LinkType = exports.DefaultShareType = exports.PublishStatus = void 0;
var PublishStatus;
(function (PublishStatus) {
    PublishStatus[PublishStatus["draft"] = 1] = "draft";
    PublishStatus[PublishStatus["published"] = 2] = "published";
    PublishStatus[PublishStatus["archived"] = 3] = "archived";
    PublishStatus[PublishStatus["deleted"] = 4] = "deleted";
})(PublishStatus || (exports.PublishStatus = PublishStatus = {}));
var DefaultShareType;
(function (DefaultShareType) {
    DefaultShareType[DefaultShareType["public"] = 1] = "public";
    DefaultShareType[DefaultShareType["private"] = 2] = "private";
})(DefaultShareType || (exports.DefaultShareType = DefaultShareType = {}));
var LinkType;
(function (LinkType) {
    LinkType[LinkType["button"] = 1] = "button";
    LinkType[LinkType["link"] = 2] = "link";
})(LinkType || (exports.LinkType = LinkType = {}));
var OpeningAnimation;
(function (OpeningAnimation) {
    OpeningAnimation["Fade and Grow"] = "Fade and Grow";
})(OpeningAnimation || (exports.OpeningAnimation = OpeningAnimation = {}));
var FlowWrapper;
(function (FlowWrapper) {
    FlowWrapper[FlowWrapper["none"] = 1] = "none";
    FlowWrapper[FlowWrapper["browserLight"] = 2] = "browserLight";
    FlowWrapper[FlowWrapper["browserDark"] = 3] = "browserDark";
})(FlowWrapper || (exports.FlowWrapper = FlowWrapper = {}));
var CustomCursor;
(function (CustomCursor) {
    CustomCursor[CustomCursor["default"] = 1] = "default";
    CustomCursor[CustomCursor["arcade"] = 2] = "arcade";
    CustomCursor[CustomCursor["mobile"] = 3] = "mobile";
})(CustomCursor || (exports.CustomCursor = CustomCursor = {}));
var StepType;
(function (StepType) {
    StepType[StepType["Image"] = 1] = "Image";
    StepType[StepType["Video"] = 2] = "Video";
    StepType[StepType["Overlay"] = 3] = "Overlay";
    StepType[StepType["Empty"] = 4] = "Empty";
})(StepType || (exports.StepType = StepType = {}));
var OverlayType;
(function (OverlayType) {
    OverlayType[OverlayType["Start"] = 0] = "Start";
    OverlayType[OverlayType["Chapter"] = 1] = "Chapter";
    OverlayType[OverlayType["Final"] = 2] = "Final";
})(OverlayType || (exports.OverlayType = OverlayType = {}));
var OverlayTheme;
(function (OverlayTheme) {
    OverlayTheme["Dark"] = "dark";
    OverlayTheme["Light"] = "light";
    OverlayTheme["Custom"] = "custom";
})(OverlayTheme || (exports.OverlayTheme = OverlayTheme = {}));
var OverlayVariant;
(function (OverlayVariant) {
    OverlayVariant["Buttons"] = "buttons";
    OverlayVariant["Form"] = "form";
    OverlayVariant["Embeds"] = "embeds";
})(OverlayVariant || (exports.OverlayVariant = OverlayVariant = {}));
var OverlayBlur;
(function (OverlayBlur) {
    OverlayBlur["Medium"] = "medium";
    OverlayBlur["Small"] = "small";
    OverlayBlur["None"] = "none";
})(OverlayBlur || (exports.OverlayBlur = OverlayBlur = {}));
var BlurType;
(function (BlurType) {
    BlurType["Secure"] = "secure";
    BlurType["Smooth"] = "smooth";
})(BlurType || (exports.BlurType = BlurType = {}));
var BlurLevel;
(function (BlurLevel) {
    BlurLevel["Small"] = "small";
    BlurLevel["Medium"] = "medium";
    BlurLevel["Large"] = "large";
})(BlurLevel || (exports.BlurLevel = BlurLevel = {}));
var MaskLevel;
(function (MaskLevel) {
    MaskLevel["Small"] = "small";
    MaskLevel["Medium"] = "medium";
    MaskLevel["Large"] = "large";
})(MaskLevel || (exports.MaskLevel = MaskLevel = {}));
var MaskType;
(function (MaskType) {
    MaskType["Dark"] = "dark";
    MaskType["Light"] = "light";
})(MaskType || (exports.MaskType = MaskType = {}));
var FlowNavSetting;
(function (FlowNavSetting) {
    FlowNavSetting["On"] = "ON";
    FlowNavSetting["Auto"] = "AUTO";
    FlowNavSetting["Off"] = "OFF";
})(FlowNavSetting || (exports.FlowNavSetting = FlowNavSetting = {}));
var ViewerLayout;
(function (ViewerLayout) {
    ViewerLayout["Default"] = "DEFAULT";
    ViewerLayout["MobileInline"] = "MOBILE_INLINE";
    ViewerLayout["MobileExpanded"] = "MOBILE_EXPANDED";
    ViewerLayout["Overlay"] = "OVERLAY";
})(ViewerLayout || (exports.ViewerLayout = ViewerLayout = {}));
var EmbedOption;
(function (EmbedOption) {
    EmbedOption["Inline"] = "inline";
    EmbedOption["PopoutTab"] = "tab";
    EmbedOption["PopoutModal"] = "modal";
})(EmbedOption || (exports.EmbedOption = EmbedOption = {}));
