import { UserProfile } from 'src/types'

import { AccountCore } from '../AccountCore'

export type Intercom = ReturnType<typeof intercom>

export const intercom = (_core: AccountCore, userProfileDoc: UserProfile) => ({
  get userHash(): string | null {
    return userProfileDoc.intercomUserHash ?? null
  },
})
