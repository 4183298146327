"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContentTypeFromExt = getContentTypeFromExt;
exports.getExtFromContentType = getExtFromContentType;
const CONTENT_TYPE_TO_EXT = new Map([
    ['video/quicktime', 'mov'],
    ['video/mp4', 'mp4'],
    ['video/webm', 'webm'],
    ['image/png', 'png'],
    ['application/json', 'json'],
]);
const EXT_TO_CONTENT_TYPE = new Map(Array.from(CONTENT_TYPE_TO_EXT.entries()).map(([k, v]) => [v, k]));
function getContentTypeFromExt(ext) {
    if (EXT_TO_CONTENT_TYPE.has(ext))
        return EXT_TO_CONTENT_TYPE.get(ext);
    throw new Error(`Unknown extension: ${ext}`);
}
function getExtFromContentType(contentType) {
    if (CONTENT_TYPE_TO_EXT.has(contentType))
        return CONTENT_TYPE_TO_EXT.get(contentType);
    throw new Error(`Unknown content type: ${contentType}`);
}
