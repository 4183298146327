import {
  defaultTeamPrefs,
  defaultUserPrefs,
  getSubfield,
} from '@arcadehq/shared/helpers'
import { Flow, TeamTheme } from 'src/types'

import { AccountCore } from '../AccountCore'
import { setOrDeleteSubfield } from '../helpers'

// TODO do something to bake in the global default values here

export type HotspotDefaults = ReturnType<typeof hotspotDefaults>

export const hotspotDefaults = (
  core: AccountCore,
  theme: TeamTheme | null,
  flow?: Flow
) => ({
  get backgroundColor() {
    const color = flow ? getSubfield(flow, 'prefs', 'bgColor') : null
    if (color) return color

    if (core.subscription.isGrowthAndAbove) {
      return getSubfield(theme, 'prefs', 'bgColor') ?? defaultTeamPrefs.bgColor
    }
    return core.user.prefs?.bgColor ?? defaultUserPrefs.bgColor
  },

  get textColor() {
    const color = flow ? getSubfield(flow, 'prefs', 'fgColor') : null
    if (color) return color

    if (core.subscription.isGrowthAndAbove) {
      return getSubfield(theme, 'prefs', 'fgColor') ?? defaultTeamPrefs.fgColor
    }

    return core.user.prefs?.textColor ?? defaultUserPrefs.textColor
  },

  setTeamBackgroundColor(backgroundColor: string | undefined) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'bgColor',
        backgroundColor,
        core.user.id
      )
    }
    return Promise.resolve(false)
  },

  setUserBackgroundColor(backgroundColor: string | undefined) {
    return core.user.updatePrefs({ bgColor: backgroundColor })
  },

  setTeamTextColor(textColor: string | undefined) {
    if (theme) {
      return setOrDeleteSubfield(
        theme,
        'prefs',
        'fgColor',
        textColor,
        core.user.id
      )
    }
    return Promise.resolve(false)
  },

  setUserTextColor(textColor: string | undefined) {
    return core.user.updatePrefs({ textColor })
  },

  setBothColors(backgroundColor: string, textColor: string) {
    if (theme) {
      const prefs = {
        ...(theme.prefs ?? {}),
        bgColor: backgroundColor,
        textColor,
      }

      return theme.update(
        {
          prefs,
        },
        core.user.id
      )
    }
    // TODO set user profile
    return Promise.resolve(false)
  },
})
