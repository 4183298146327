import { User } from 'next-firebase-auth'
import { Team, UserProfile, Workspace } from 'src/types'

import { AccountCore } from './AccountCore'
import { Extension, extension } from './features/extension'
import { FlowDefaults, flowDefaults } from './features/flow-defaults'
import { Flows, flows } from './features/flows'
import { HotspotDefaults, hotspotDefaults } from './features/hotspot-defaults'
import { Insights, insights } from './features/insights'
import { Intercom, intercom } from './features/intercom'
import { Onboarding, onboarding } from './features/onboarding'
import { Privacy, privacy } from './features/privacy'
import { SharePage, sharePage } from './features/share-page'
import { Watermark, watermark } from './features/watermark'

export class Account extends AccountCore {
  public extension: Extension
  public flowDefaults: FlowDefaults
  public flows: Flows
  public hotspotDefaults: HotspotDefaults
  public insights: Insights
  public intercom: Intercom
  public onboarding: Onboarding
  public privacy: Privacy
  public sharePage: SharePage
  public watermark: Watermark

  constructor(
    authUserDoc: User,
    userProfileDoc: UserProfile,
    teamDoc: Team | null | undefined,
    workspaceDoc: Workspace | null // TODO:workspaces remove `null` also in team above
  ) {
    super(authUserDoc, userProfileDoc, teamDoc, workspaceDoc)

    this.extension = extension(this)
    this.flowDefaults = flowDefaults(this, this.defaultTheme)
    this.hotspotDefaults = hotspotDefaults(this, this.defaultTheme)
    this.insights = insights(this, teamDoc)
    this.intercom = intercom(this, userProfileDoc)
    this.onboarding = onboarding(this, userProfileDoc)
    this.privacy = privacy(this)
    this.sharePage = sharePage(this, this.defaultTheme)
    this.watermark = watermark(this, this.defaultTheme)

    this.flows = flows(this)
  }
}
