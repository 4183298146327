import { createContext, PropsWithChildren } from 'react'

import { CurrentFlowProps } from './types'

// see src/flow/withFlow.tsx for details

export const FlowContext = createContext<CurrentFlowProps>({
  fontFamily: '',
  customLink: undefined,
  customValues: {},
  customVariableParams: {},
})

export function FlowProvider(
  props: PropsWithChildren<{ contextProps: CurrentFlowProps }>
) {
  const { children, contextProps } = props

  return (
    <FlowContext.Provider value={contextProps}>{children}</FlowContext.Provider>
  )
}
