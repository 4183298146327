"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryByCSSPath = exports.getElementByXPath = exports.CaptureHtmlMessages = exports.getDoctype = exports.checkIsCapturedHTML = exports.HTML_FOLDER = void 0;
exports.HTML_FOLDER = 'arcade-html';
const checkIsCapturedHTML = (url) => url.endsWith('.html') && url.includes(`/${exports.HTML_FOLDER}/`);
exports.checkIsCapturedHTML = checkIsCapturedHTML;
const getDoctype = () => {
    const node = document.doctype;
    return node
        ? '<!DOCTYPE ' +
            node.name +
            (node.publicId ? ' PUBLIC "' + node.publicId + '"' : '') +
            (!node.publicId && node.systemId ? ' SYSTEM' : '') +
            (node.systemId ? ' "' + node.systemId + '"' : '') +
            '>'
        : '<!DOCTYPE html>';
};
exports.getDoctype = getDoctype;
var CaptureHtmlMessages;
(function (CaptureHtmlMessages) {
    // Between the Web and rendered content script page-editor
    CaptureHtmlMessages["RequestHtml"] = "ArcadeRequestHtml";
    CaptureHtmlMessages["ResponseHtml"] = "ArcadeResponseHtml";
    CaptureHtmlMessages["ChangeEditMode"] = "ArcadeChangeEditMode";
    CaptureHtmlMessages["RegisteredChange"] = "ArcadeRegisteredChange";
    CaptureHtmlMessages["UndoEdit"] = "ArcadeUndoEdit";
    CaptureHtmlMessages["RedoEdit"] = "ArcadeRedoEdit";
    CaptureHtmlMessages["UndoShortcutEdit"] = "ArcadeUndoShortcutEdit";
    CaptureHtmlMessages["RedoShortcutEdit"] = "ArcadeRedoShortcutEdit";
    CaptureHtmlMessages["ResetScroll"] = "ArcadeHtmlResetScroll";
    CaptureHtmlMessages["InitHtmlReplace"] = "ArcadeInitHtmlReplaceMessage";
    // Between extension and child iframe
    CaptureHtmlMessages["RequestHtmlFromIframe"] = "ArcadeRequestHtmlFromIframe";
    CaptureHtmlMessages["SendHtmlFromIframe"] = "ArcadeSendHtmlFromIframe";
})(CaptureHtmlMessages || (exports.CaptureHtmlMessages = CaptureHtmlMessages = {}));
const getElementByXPath = (doc, xpath) => {
    const result = doc.evaluate(xpath, doc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
    return result.singleNodeValue;
};
exports.getElementByXPath = getElementByXPath;
const queryByCSSPath = (doc, cssPath) => cssPath.startsWith('/')
    ? (0, exports.getElementByXPath)(doc, cssPath)
    : doc.querySelector(cssPath);
exports.queryByCSSPath = queryByCSSPath;
