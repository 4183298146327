"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractGroupName = exports.isPublicDomain = exports.hasPublicEmailDomain = void 0;
const EMAIL_PROVIDERS = [
    'gmail.com',
    'icloud.com',
    'googlemail.com',
    'yahoo.com',
    'live.com',
    'hotmail.com',
    'outlook.com',
    'msn.com',
    'aol.com',
    'comcast.net',
    'sbcglobal.net',
    'att.net',
    'verizon.net',
    'shaw.ca',
    'bell.ca',
    'shawcable.ca',
    'shawcable.net',
    'shaw.net',
    'shaw.com',
    'shawcable.com',
    'hotmail.co.uk',
    'protonmail.com',
];
const hasPublicEmailDomain = (email) => {
    const domain = email.split('@')[1];
    return EMAIL_PROVIDERS.includes(domain);
};
exports.hasPublicEmailDomain = hasPublicEmailDomain;
const isPublicDomain = (domain) => {
    return EMAIL_PROVIDERS.includes(domain);
};
exports.isPublicDomain = isPublicDomain;
const extractGroupName = (user, fallbackToUserId = true) => {
    if (!user.email)
        return null;
    const domain = user.email.split('@')[1];
    if (EMAIL_PROVIDERS.includes(domain)) {
        return fallbackToUserId ? user.id : null;
    }
    return domain;
};
exports.extractGroupName = extractGroupName;
