import { convertDate } from '@arcadehq/shared/helpers'
import {
  Feature,
  StoredFeatures,
  SubscriptionInfo,
  WorkspaceDataDoc,
} from '@arcadehq/shared/types'
import { Workspace } from 'src/types'

import { AccountCore } from '../AccountCore'

/**
 * workspace is a collection of getters that are specific to a workspace.
 * This file should only include low level getter and generic helpers.
 * It acts as a proxy between other features in Account and the database object.
 */

export const workspace = (core: AccountCore, workspace: Workspace) => ({
  update(
    updates: Partial<WorkspaceDataDoc>,
    modifiedBy?: string
  ): Promise<boolean> {
    return workspace.update(updates, modifiedBy || this.id)
  },

  get id(): string {
    return workspace.id
  },

  get name(): string {
    return workspace.name
  },

  get slug(): string {
    return workspace.slug
  },

  async setSlug(slug: string): Promise<void> {
    const response = await core.fetchWithToken(
      'POST',
      `/api/workspace/${workspace.id}/update-slug`,
      { slug }
    )
    const result = await response.json()
    if (result.error) {
      throw Error(result.error)
    }
    return result.slug
  },

  get logo(): string | undefined {
    return workspace.logo
  },

  get maySetLogo(): boolean {
    return core.access.isWorkspaceOwner || core.access.isWorkspaceAdmin
  },

  get subscriptions(): SubscriptionInfo[] {
    return workspace.subscriptions
  },

  get customDomain(): string | undefined {
    return workspace.customDomain
  },

  get stripeCustomerId(): string | undefined {
    return workspace.stripeCustomerId
  },

  get isOverdue(): boolean {
    return !!workspace.isOverdue && !workspace.ignoreOverdue
  },

  get creationDate(): Date {
    return convertDate(workspace.created)
  },

  get features(): StoredFeatures {
    return workspace.features
  },

  getFeature<F extends Feature>(feat: F): StoredFeatures[F] | null {
    return this.features[feat] ?? null
  },

  setFeature<F extends Feature>(
    feature: F,
    value: StoredFeatures[F]
  ): Promise<boolean> {
    const newFeatures = { ...this.features, [feature]: value }
    return workspace.update({ features: newFeatures }, core.user.id)
  },
})
