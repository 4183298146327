import { captureException, withScope } from '@sentry/nextjs'
import type { Extras } from '@sentry/types'
import type { AxiosError } from 'axios'
import omit from 'lodash/omit'

export * from './capture-error'

const isAxiosError = (e: unknown): e is AxiosError => {
  return (
    typeof e === 'object' &&
    e !== null &&
    'isAxiosError' in e &&
    (e as any).isAxiosError
  )
}

const jsonParseIfPossible = (input: string): string | object => {
  try {
    return JSON.parse(input)
  } catch (_e) {
    return input
  }
}

const headersToOmit = ['authorization', 'Authorization']

export const captureExceptionWithRequestData = (e: unknown) => {
  if (isAxiosError(e)) {
    const extras: Extras = {}
    if (e.config.headers) {
      extras['request_headers'] = omit(e.config.headers, headersToOmit)
    }
    if (e.config.params) {
      extras['request_params'] = e.config.params
    }
    if (e.config.data) {
      extras['request_data'] = jsonParseIfPossible(e.config.data)
    }
    if (e.response) {
      extras['response_data'] = e.response.data
      extras['response_headers'] = omit(e.response.headers, headersToOmit)
      extras['response_status'] = e.response.status
      extras['response_status_text'] = e.response.statusText
    }
    withScope(scope => {
      scope.setExtras(extras)
      captureException(e)
    })
  } else {
    captureException(e)
  }
}

export class HTTPError extends Error {
  status: number
  extra?: Extras

  constructor(message: string, status: number, extra?: Extras) {
    super(message)
    this.status = status
    this.extra = extra
  }
}

export class InternalServerError extends HTTPError {
  constructor(message: string = 'Unexpected error', extra?: Extras) {
    super(message, 500, extra)
  }
}

export class NotAllowedError extends HTTPError {
  constructor(message: string = 'Not allowed', extra?: Extras) {
    super(message, 405, extra)
  }
}

export class NotFoundError extends HTTPError {
  constructor(message: string = 'Not found', extra?: Extras) {
    super(message, 404, extra)
  }
}

export class ForbiddenError extends HTTPError {
  constructor(message: string = 'Forbidden', extra?: Extras) {
    super(message, 403, extra)
  }
}

export class UnauthorizedError extends HTTPError {
  constructor(message: string = 'Unauthorized', extra?: Extras) {
    super(message, 401, extra)
  }
}

export class BadRequestError extends HTTPError {
  constructor(message: string = 'Bad request', extra?: Extras) {
    super(message, 400, extra)
  }
}

export function getErrorInfo(error: unknown) {
  const isHTTPError = error instanceof HTTPError
  const isError = error instanceof Error
  const status = isHTTPError ? error.status : 500
  const message = isHTTPError || isError ? error.message : undefined
  const extra = isHTTPError ? error.extra : undefined
  return { status, message, extra }
}
