"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HtmlCaptureStatus = void 0;
// ----------------------------
var HtmlCaptureStatus;
(function (HtmlCaptureStatus) {
    HtmlCaptureStatus["Processing"] = "processing";
    HtmlCaptureStatus["Succeeded"] = "succeeded";
    HtmlCaptureStatus["Failed"] = "failed";
})(HtmlCaptureStatus || (exports.HtmlCaptureStatus = HtmlCaptureStatus = {}));
