import {
  getPlan,
  getWorkspaceStatus,
  Plan,
  Trial,
} from '@arcadehq/shared/helpers'
import {
  Feature,
  StoredFeatures,
  TeamPrefs,
  UserProfileDataDoc,
  WorkspacePlan,
} from '@arcadehq/shared/types'
import type { User } from 'next-firebase-auth'
import { isElevenLabsSupportedLanguage } from 'src/api/SyntheticVoice'
import { FlowLikeEntity } from 'src/components/Flows/types'
import {
  Flow,
  FlowsCollection,
  Team,
  TeamMember,
  TeamTheme,
  UserProfile,
  Workspace,
} from 'src/types'
import { isFlowLocked } from 'src/utils/subscription'

import { access } from './core-features/access'
import { subscription } from './core-features/subscription'
import { team } from './core-features/team'
import { user } from './core-features/user'
import { workspace } from './core-features/workspace'
import { getDefaultThemeFromTeam } from './helpers'

export type { Plan } from '@arcadehq/shared/helpers'

export interface PaywallConfig {
  title: string
  CTA: string
  path: string
}

export class AccountCore {
  public plan: Plan
  public workspacePlan: WorkspacePlan | null = null // TODO:workspaces make non-nullable
  public workspaceTrial: Trial | null = null
  public user: ReturnType<typeof user>
  public team: ReturnType<typeof team>
  public workspace: ReturnType<typeof workspace> | undefined
  public subscription: ReturnType<typeof subscription>
  public access: ReturnType<typeof access>
  public defaultTheme: TeamTheme | null = null
  public isFullyLoaded: boolean
  public maySeeTeamInviteNotification: boolean

  constructor(
    authUserDoc: User,
    userProfileDoc: UserProfile,
    teamDoc: Team | null | undefined, // Undefined means it's still loading
    workspaceDoc: Workspace | null | undefined // Undefined means it's still loading // TODO:workspaces remove `null`
  ) {
    this.user = user(this, authUserDoc, userProfileDoc)
    // TODO:workspaces remove second hand below (and check for teamDoc)
    this.team =
      workspaceDoc && teamDoc
        ? team(this, teamDoc)
        : team(this, (!!this.user.isActiveMemberOfTeam && teamDoc) || null)
    this.plan = getPlan(userProfileDoc, teamDoc) // TODO:workspaces remove this.plan

    // TODO:workspaces make this.workspace mandatory
    if (workspaceDoc) {
      this.workspace = workspace(this, workspaceDoc)
      const status = getWorkspaceStatus(workspaceDoc)
      this.workspacePlan = status.plan
      this.workspaceTrial = status.trial
    }
    this.subscription = subscription(this, userProfileDoc, teamDoc)
    this.access = access(this)

    this.isFullyLoaded = !!userProfileDoc.id && typeof teamDoc !== 'undefined'

    this.maySeeTeamInviteNotification = !!teamDoc?.currentSubscriber

    if (this.mayUseTeamThemes && teamDoc) {
      this.defaultTheme = getDefaultThemeFromTeam(teamDoc)
    }
  }

  getFeature<F extends Feature>(feat: F): StoredFeatures[F] | null {
    return (
      this.workspace?.getFeature(feat) ??
      this.team.getFeature(feat) ??
      this.user.getFeature(feat)
    )
  }

  get isLoggedIn(): boolean {
    return !!this.user.id
  }

  get colors() {
    if (this.workspace) {
      return this.team.colors
    }
    // TODO:workspaces remove below
    return this.access.isActiveMemberOfTeam && this.team.id
      ? this.team.colors
      : this.user.colors
  }

  updateSavedColors(colors: string[]) {
    if (this.workspace) {
      return this.team.update({ colors })
    }
    // TODO:workspaces remove below
    return this.access.isActiveMemberOfTeam && this.team.id
      ? this.team.update({ colors })
      : this.user.update({ colors })
  }

  async fetchWithToken(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    apiPath: string,
    requestBody?: any,
    options?: RequestInit
  ): Promise<Response> {
    const idToken = await this.user.getIdToken()
    return fetch(apiPath, {
      method,
      ...options,
      headers: {
        Authorization: idToken!,
        'Content-Type': 'application/json',
        ...options?.headers,
      },
      ...(requestBody
        ? {
            body: JSON.stringify(requestBody),
          }
        : {}),
    })
  }

  get mayEditVideo(): boolean {
    return !this.subscription.isFree
  }

  get mayInviteCollaborators(): boolean {
    return this.subscription.isGrowth || this.subscription.isEnterprise
  }

  mayEditFlow(flow: Flow): boolean {
    return (
      (!isFlowLocked(flow, this.subscription.freePlanLimitsApply) &&
        !!this.user.id &&
        (flow.createdBy === this.user.id ||
          flow.editors?.includes(this.user.id))) ||
      (!this.getFeature(Feature.EditAccessToArcadesIsControlled) &&
        flow.belongsToTeam &&
        this.access.isActiveMemberOfTeam &&
        this.team.group === flow.group)
    )
  }

  get mayUseCollections(): boolean {
    return this.subscription.isGrowth || this.subscription.isEnterprise
  }

  mayEditCollection(flow: FlowsCollection): boolean {
    return (
      this.mayUseCollections &&
      !!(
        (!!this.user.id &&
          (flow.createdBy === this.user.id ||
            flow.editors?.includes(this.user.id))) ||
        (!this.getFeature(Feature.EditAccessToArcadesIsControlled) &&
          flow.belongsToTeam &&
          this.access.isActiveMemberOfTeam &&
          this.team.group === flow.group)
      )
    )
  }

  mayToggleFlowBelongsToTeam(flow: FlowLikeEntity): boolean {
    return this.access.isActiveMemberOfTeam && flow.createdBy === this.user.id
  }

  mayDeleteFlow(flow: FlowLikeEntity): boolean {
    return this.user.id === flow.createdBy
  }

  get mayUseURLDestinationInOverlay(): boolean {
    // [ARC-3120](https://linear.app/arcadehq/issue/ARC-3120/move-cta-from-pro-plan-to-growth-plan)
    return (
      this.subscription.isGrowth ||
      (this.subscription.isPro &&
        this.user.creationDate.getTime() < 1705706669606) || // 2024-01-19
      this.subscription.isEnterprise
    )
  }

  // TODO combine flowDefaults and hotspotDefaults and move this there
  get mayEditAppearanceSettings(): boolean {
    return (
      (this.subscription.isGrowth || this.subscription.isEnterprise) &&
      this.access.isTeamAdmin
    )
  }

  get mayManageTeam(): boolean {
    return (
      (this.subscription.isGrowth || this.subscription.isEnterprise) &&
      this.access.isTeamAdmin
    )
  }

  // TODO combine flowDefaults and hotspotDefaults and move this there
  get hasAppearanceSettings(): boolean {
    return !!this.team.prefs
  }

  // TODO remove the reset button?
  resetAppearanceSettings(deleteFieldValue: any): Promise<boolean> {
    return this.team.update({ prefs: deleteFieldValue as TeamPrefs })
  }

  discoverFeature(
    feature: keyof Required<UserProfileDataDoc>['featuresDiscovered']
  ): Promise<boolean> {
    if (!this.user.featuresDiscovered[feature]) {
      return this.user.update({
        featuresDiscovered: {
          ...this.user.featuresDiscovered,
          [feature]: true,
        },
      })
    }
    return Promise.resolve(false)
  }

  get mayUseOrganizationTools(): boolean {
    return this.subscription.isGrowth || this.subscription.isEnterprise
  }

  get mayViewTeamSettings(): boolean {
    return this.subscription.isGrowth || this.subscription.isEnterprise
  }

  mayViewTeamMembersSettings(myself?: TeamMember): boolean {
    return (
      this.subscription.isGrowthAndAbove ||
      (this.subscription.hasntUpgradedAfterTrial &&
        myself?.type === 'Admin' &&
        !this.subscription.isPro)
    )
  }

  get mayViewOverviewInsights(): boolean {
    return !this.subscription.isFree
  }

  get mayViewUserSettings(): boolean {
    return this.subscription.isFree || this.subscription.isPro
  }

  // If this ever becomes an issue, we can add a feature flag for specific
  // customers who want to stay with Google voices, but the idea is to transition
  // to Elevenlabs entirely in a few months
  // Elevenlabs do not support Norwegian voices yet, so we need to keep Google voices for now if we have someone using it :(
  mayUseGoogleVoice(flow: Flow, language: string): boolean {
    return (
      flow.created < new Date('2024-05-21T10:00:00.000Z') ||
      !isElevenLabsSupportedLanguage(language)
    )
  }

  get mayUseApi(): boolean {
    return this.subscription.isEnterprise
  }

  get mayAlterApi(): boolean {
    return !!(this.mayUseApi && this.access.isTeamAdmin)
  }

  get mayUseWebhooks(): boolean {
    return this.subscription.isEnterprise
  }

  get mayAlterWebhooks(): boolean {
    return !!(this.mayUseWebhooks && this.access.isTeamAdmin)
  }

  get planName(): WorkspacePlan {
    if (this.workspace && this.workspacePlan) return this.workspacePlan
    // TODO:workspaces remove below
    return this.subscription.isEnterprise
      ? 'Enterprise'
      : this.subscription.isGrowth
      ? 'Growth'
      : this.subscription.isPro
      ? 'Pro'
      : 'Free'
  }

  get planDescription() {
    return this.plan
  }

  get mayUseBranchingMap(): boolean {
    return this.access.isActiveMemberOfTeam
  }

  get mayUseIntegrations(): boolean {
    return this.access.isActiveMemberOfTeam
  }

  get mayConfigureIntegrations(): boolean {
    return this.access.isActiveMemberOfTeam && this.access.isTeamAdmin
  }

  get mayUseFlowForm(): boolean {
    return this.access.isActiveMemberOfTeam
  }

  get mayUseFlowEmbeds(): boolean {
    return this.access.isActiveMemberOfTeam
  }

  get mayUsePanAndZoom(): boolean {
    return !this.subscription.isFree
  }

  get mayUseExport(): boolean {
    return !this.subscription.isFree
  }

  get mayUseTeamThemes(): boolean {
    return (
      this.access.isActiveMemberOfTeam &&
      (this.subscription.isGrowth || this.subscription.isEnterprise)
    )
  }

  get mayCreateTeamTheme(): boolean {
    return this.access.isTeamAdmin
  }

  getPaywallConfig(availableOn: ('Pro' | 'Growth')[]): PaywallConfig {
    // If they had a trial, upgrading is the only option
    if (this.subscription.hasntUpgradedAfterTrial) {
      return {
        title: `Upgrade to ${availableOn.join(' or ')}`,
        CTA: 'Upgrade',
        path: '/settings/billing',
      }
    }
    // If no trial and Pro / Growth are available, can upgrade / trial
    if (availableOn.length === 2) {
      return {
        title: 'Upgrade to Pro or try Growth for free',
        CTA: 'Try now',
        path: '/settings/billing',
      }
    }
    // There's nothing in Pro that's not in Growth
    return {
      title: 'Try Growth for free',
      CTA: 'Try now',
      path: '/flows/team/signup',
    }
  }

  get customDomain(): string | undefined {
    if (this.workspace) {
      return this.workspace.customDomain
    }
    // TODO:workspaces remove below
    return this.team?.customDomain
  }

  shouldUseNewWorkspaces(): boolean {
    return this.getFeature(Feature.Workspaces) || false
  }

  get group() {
    return this.team.group || this.user.emailGroup || undefined
  }
}
