"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DUCKING_BG_MUSIC_VOLUME = exports.DEFAULT_BG_MUSIC_VOLUME = exports.DEFAULT_WAIT_TIME_PER_STEP = exports.VOICEOVER_BUFFER = exports.MIN_5_GROWTH_MEMBERS_UNTIL = exports.MIN_5_GROWTH_MEMBERS_SINCE = exports.DEFAULT_ZOOMED_IN_LEVEL = exports.allLanguages = exports.STRIPE_IDS = exports.overlayWithEmbedStepPropsThatAffectViewerRendering = exports.overlayWithoutPathsStepPropsThatAffectViewerRendering = exports.overlayWithPathsStepPropsThatAffectViewerRendering = exports.videoStepPropsThatAffectViewerRendering = exports.imageStepPropsThatAffectViewerRendering = exports.flowPropsThatAffectViewerRendering = exports.PERSONAL_TEAM_SHORTHAND = exports.DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS = exports.BG_EXPORT_EDGE_FRAC = exports.BROWSER_BAR_EXPORT_HEIGHT_FRAC = exports.BROWSER_BAR_OR_CAPTION_HEIGHT = void 0;
exports.BROWSER_BAR_OR_CAPTION_HEIGHT = 41;
exports.BROWSER_BAR_EXPORT_HEIGHT_FRAC = 0.05;
exports.BG_EXPORT_EDGE_FRAC = 0.05;
exports.DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS = 14;
exports.PERSONAL_TEAM_SHORTHAND = 'me';
exports.flowPropsThatAffectViewerRendering = [
    'aspectRatio',
    'belongsToTeam',
    'font',
];
exports.imageStepPropsThatAffectViewerRendering = [
    'hotspots',
    'panAndZoom',
    'size',
    'url',
    'cameraRecording',
];
exports.videoStepPropsThatAffectViewerRendering = [
    'duration',
    'endTimeFrac',
    'muted',
    'panAndZoom',
    'playbackRate',
    'size',
    'startTimeFrac',
    'streamUrl',
    'url',
    'videoThumbnailUrl',
    'hotspots',
    'cameraRecording',
];
exports.overlayWithPathsStepPropsThatAffectViewerRendering = ['blur', 'paths', 'subtitle', 'theme', 'title', 'cameraRecording'];
exports.overlayWithoutPathsStepPropsThatAffectViewerRendering = [
    'blur',
    'buttonColor',
    'buttonText',
    'buttonTextColor',
    'subtitle',
    'theme',
    'title',
    'cameraRecording',
];
exports.overlayWithEmbedStepPropsThatAffectViewerRendering = ['embedCode'];
exports.STRIPE_IDS = {
    test: {
        proLegacy: {
            id: 'NOT_SET',
            monthly: 'NOT_SET',
            yearly: 'NOT_SET',
            billingPortalConfigId: 'bpc_1MoTp1CDgCbpE4wjLGzZj4i7',
        },
        pro: {
            id: 'prod_JgVW6XyJFn1GOp',
            monthly: 'price_1LAOgiCDgCbpE4wjE4ZS2MNv',
            yearly: 'price_1MRjaCCDgCbpE4wj9BKR1D9t',
            billingPortalConfigId: 'bpc_1MoTp1CDgCbpE4wjLGzZj4i7',
        },
        growth: {
            id: 'prod_NC4dyvUBYsckAV',
            monthly: 'price_1N1iE6CDgCbpE4wjAPf2kbTK',
            yearly: 'price_1MRgU0CDgCbpE4wj3IRWZ9Qc',
            euroMonthly: 'NOT_SET',
            billingPortalConfigId: 'bpc_1MoTs4CDgCbpE4wjDM6EtzZy',
        },
        growthMin5: {
            id: 'prod_NC4dyvUBYsckAV',
            monthly: 'price_1OIIjLCDgCbpE4wjVwei4C4H',
            yearly: 'price_1OIIjsCDgCbpE4wjA5Uey007',
            euroMonthly: 'NOT_SET',
            billingPortalConfigId: 'bpc_1MoTs4CDgCbpE4wjDM6EtzZy',
        },
    },
    live: {
        proLegacy: {
            id: 'prod_LoFjjMbsRubmhN',
            monthly: 'NOT_SET',
            yearly: 'price_1LAeIkCDgCbpE4wj878If8nn',
            billingPortalConfigId: 'bpc_1MoTvVCDgCbpE4wjD6wkZ5Zx',
        },
        pro: {
            id: 'prod_LDHiTE6EefMd0q',
            monthly: 'price_1KWr9GCDgCbpE4wjm9BtSM6J',
            yearly: 'price_1MRjxbCDgCbpE4wjPDG4ZqPv',
            billingPortalConfigId: 'bpc_1MoTvVCDgCbpE4wjD6wkZ5Zx',
        },
        growth: {
            id: 'prod_NBsfBq7KHd8Lub',
            monthly: 'price_1MRUuUCDgCbpE4wjuWjHDUc7',
            yearly: 'price_1MRUuUCDgCbpE4wjPn4qWicR',
            euroMonthly: 'price_1MSLtZCDgCbpE4wjHWwtB3NH', // Legacy
            billingPortalConfigId: 'bpc_1MoTtyCDgCbpE4wjkNE1hQZo',
        },
        growthMin5: {
            id: 'prod_NBsfBq7KHd8Lub',
            monthly: 'price_1OIIazCDgCbpE4wjS6Ehjwj4',
            yearly: 'price_1OIIdBCDgCbpE4wjtpZ9595z',
            euroMonthly: 'NOT_SET',
            billingPortalConfigId: 'bpc_1MoTtyCDgCbpE4wjkNE1hQZo',
        },
    },
};
// Languages shared by Synthetic Voices & Translations
exports.allLanguages = [
    { key: 'ar', name: 'Arabic' },
    { key: 'bg', name: 'Bulgarian' },
    { key: 'zh', name: 'Chinese' },
    { key: 'hr', name: 'Croatian' },
    { key: 'cs', name: 'Czech' },
    { key: 'da', name: 'Danish' },
    { key: 'nl', name: 'Dutch' },
    { key: 'en', name: 'English' },
    { key: 'fi', name: 'Finnish' },
    { key: 'fr', name: 'French' },
    { key: 'de', name: 'German' },
    { key: 'el', name: 'Greek' },
    { key: 'hi', name: 'Hindi' },
    { key: 'id', name: 'Indonesian' },
    { key: 'it', name: 'Italian' },
    { key: 'ja', name: 'Japanese' },
    { key: 'ko', name: 'Korean' },
    { key: 'ms', name: 'Malay' },
    { key: 'no', name: 'Norwegian' },
    { key: 'pl', name: 'Polish' },
    { key: 'pt', name: 'Portuguese' },
    { key: 'ro', name: 'Romanian' },
    { key: 'ru', name: 'Russian' },
    { key: 'sk', name: 'Slovak' },
    { key: 'es', name: 'Spanish' },
    { key: 'sv', name: 'Swedish' },
    { key: 'ta', name: 'Tamil' },
    { key: 'tr', name: 'Turkish' },
    { key: 'uk', name: 'Ukrainian' },
];
exports.DEFAULT_ZOOMED_IN_LEVEL = 1.5;
exports.MIN_5_GROWTH_MEMBERS_SINCE = 1701458525544 + 1000 * 60 * 60;
exports.MIN_5_GROWTH_MEMBERS_UNTIL = 1703097044286 + 1000 * 60 * 60;
exports.VOICEOVER_BUFFER = 1; // Amount of time buffered between step transitions and voiceover start / end
exports.DEFAULT_WAIT_TIME_PER_STEP = 2.5; // in s
exports.DEFAULT_BG_MUSIC_VOLUME = 1;
exports.DUCKING_BG_MUSIC_VOLUME = 0.3;
