import {
  getCustomValuesFromQuery,
  withCustomVariables,
} from '@arcadehq/shared/helpers'
import { LanguageCode } from '@arcadehq/shared/types'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import useAccount from 'src/auth/useAccount'
import { Flow, FlowCustomLink } from 'src/types'
import { theme } from 'tailwind.config'

import { useFlow } from './atoms/flow'
import { CurrentFlowProps } from './types'

// internal hook. do not use in UI components

interface FlowProviderContextOpt {
  customLink?: FlowCustomLink
}

type CustomValues = Record<string, string>
export const selectTitle = (flow: Flow, customValues?: CustomValues) =>
  withCustomVariables(flow.name, flow.customVariables, customValues)

export const selectExternalTitle = (
  flow: Flow,
  customValues?: CustomValues,
  language?: LanguageCode
) => {
  const title = flow.externalName ?? flow.name
  const translatedTitle =
    language && flow.translations && flow.translations[language]?.strings?.name
      ? flow.translations[language]?.strings.name ?? title
      : title
  return withCustomVariables(
    translatedTitle,
    flow.customVariables,
    customValues
  )
}

export const selectDescription = (
  flow: Flow,
  customValues?: CustomValues,
  language?: LanguageCode
) => {
  const translatedDescription =
    language &&
    flow.translations &&
    flow.translations[language]?.strings?.description
      ? flow.translations[language]?.strings.description ?? flow.description
      : flow.description
  return withCustomVariables(
    translatedDescription,
    flow.customVariables,
    customValues
  )
}

export function useFlowProviderContext({
  customLink,
}: FlowProviderContextOpt): CurrentFlowProps {
  const account = useAccount()
  const router = useRouter()
  const flow = useFlow()

  // Add 'Noto Color Emoji' as fallback for Emojis
  // This will be used during GIF/Video exports
  const fontFamily = useMemo(() => {
    const font =
      (flow.font || account.flowDefaults.font) ?? theme.extend.fontFamily.sans
    return `"${font}", "Noto Color Emoji"`
  }, [flow.font, account.flowDefaults.font])

  const customVariableParams = useMemo(
    () => getCustomValuesFromQuery(router.query),
    [router.query]
  )

  const customValues = useMemo(
    () => ({
      ...customLink?.variables,
      ...customVariableParams,
    }),
    [customVariableParams, customLink]
  )

  return useMemo(
    () => ({
      fontFamily,
      customValues,
      customVariableParams,
      customLink,
    }),
    [fontFamily, customValues, customVariableParams, customLink]
  )
}
