import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'

export enum FlowMode {
  Edit = 'EDIT',
  View = 'VIEW',
  Upload = 'UPLOAD',
}

export const flowModeAtom = atom<FlowMode>(FlowMode.View)

export function useFlowMode() {
  return useAtomValue(flowModeAtom)
}

export function useSetFlowMode() {
  return useSetAtom(flowModeAtom)
}

export function useHydrateFlowMode(mode: FlowMode) {
  useHydrateAtoms([[flowModeAtom, mode]])
}
